import { Avatar } from '@nextui-org/react';
import React from 'react';
import { twMerge } from 'tailwind-merge';

interface Props {
  iconSrc: string;
  subIconSrc?: string;
  baseClassName?: string;
  iconClassName?: string;
  subIconClassName?: string;
}

const TokenIcon: React.FC<Props> = ({ iconSrc, subIconSrc, baseClassName, iconClassName, subIconClassName }) => {
  return (
    <div className={twMerge('relative w-fit', baseClassName)}>
      <Avatar className={twMerge('h-6 w-6', iconClassName)} src={iconSrc} />
      {subIconSrc ? (
        <Avatar className={twMerge('absolute bottom-0 right-0 h-2 w-2', subIconClassName)} src={subIconSrc} />
      ) : (
        <></>
      )}
    </div>
  );
};

export default TokenIcon;
