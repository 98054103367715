import { Decimal } from 'decimal.js';
import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { generatePersistConfig } from 'utils/misc';
import { persistReducer } from 'redux-persist';
import { ChainIds } from 'configs/chain';

interface State {
  [baseToken: string]: PairPrice[];
}
interface PairPrice {
  priceUsd: number;
  basePrice: number;
  baseTokenAddress: string;
  quoteReserve?: Decimal;
  baseReserve?: Decimal;
  chainId?: ChainIds;
  liquidity?: string;
  pairAddress: string;
  athPrice?: number;
  athPriceUsd?: number;
}

interface LiquidityAction {
  baseTokenAddress: string;
  liquidity: string;
  pairAddress: string;
}

const tokenPrice = createSlice({
  name: 'tokenPrice',
  initialState: {},
  reducers: {
    addPrice: (state: State, action: PayloadAction<PairPrice>) => {
      const currentState = current(state)?.[action.payload.baseTokenAddress] || [];
      const currentPair = currentState?.find((pair) => pair.pairAddress === action.payload?.pairAddress);
      if (!currentPair) {
        return {
          [action.payload.baseTokenAddress]: [...currentState, action.payload],
        };
      }
      return {
        [action.payload.baseTokenAddress]: currentState?.map((pair) => {
          if (pair?.pairAddress === action.payload?.pairAddress) {
            return action.payload;
          }
          return pair;
        }),
      };
    },
    updatePrice: (state: State, action: PayloadAction<PairPrice>) => {
      const currentState = current(state)?.[action.payload.baseTokenAddress] || [];
      return {
        [action.payload.baseTokenAddress]: currentState?.map((pair) => {
          if (pair?.pairAddress === action.payload?.pairAddress) {
            return action.payload;
          }
          return pair;
        }),
      };
    },
    clearPrice: () => {
      return {};
    },
    updateLiquidityV3: (state: State, action: PayloadAction<LiquidityAction>) => {
      const currentState = current(state)?.[action.payload.baseTokenAddress] || [];
      return {
        [action.payload.baseTokenAddress]: currentState?.map((pair) => {
          if (pair?.pairAddress === action.payload?.pairAddress) {
            return { ...pair, liquidity: action.payload?.liquidity };
          }
          return pair;
        }),
      };
    },
  },
});

export const { addPrice, clearPrice, updatePrice, updateLiquidityV3 } = tokenPrice.actions;
const persistConfig = generatePersistConfig('tokenPrice', []);
export default persistReducer<State>(persistConfig, tokenPrice.reducer);
