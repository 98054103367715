import { checkedSuccessIcon, infoCircleIcon } from 'assets/icons';
import { Tooltip } from '@nextui-org/react';
import React from 'react';

interface Props {
  isUpdated: boolean;
}

const SocialStatus: React.FC<Props> = ({ isUpdated }) => {
  return (
    <Tooltip
      classNames={{
        content:
          'border border-gray-800 bg-black text-gray-500 py-1 px-2 text-[13px] font-medium max-w-[126px] text-center',
      }}
      content={isUpdated ? 'ppdated social and info' : 'not updated social and info'}
    >
      <img src={isUpdated ? checkedSuccessIcon : infoCircleIcon} />
    </Tooltip>
  );
};

export default SocialStatus;
