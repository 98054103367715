import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { generatePersistConfig } from 'utils/misc';
import { persistReducer } from 'redux-persist';
// import { solana } from 'configs/networks';

type MasterConfigNormalized = {
  authority: string;
  feeReceiver: string;
  creationFee: string;
  nativeFeePercent: number;
  tokenFeePercent: number;
  nativeFeeOnlyPercent: number;
  operators: string[];
  currencies: string[];
};

type SolanaStateType = {
  masterConfig?: MasterConfigNormalized;
  isMasterConfigLoading: boolean;
  isConnectWalletModalVisible: boolean;
  currentRPC: string;
  currentWS: string;
  autoConnect: boolean;
};

const initialState: SolanaStateType = {
  isMasterConfigLoading: false,
  isConnectWalletModalVisible: false,
  currentRPC: 'https://api.mainnet-beta.solana.com',
  currentWS: 'wss://api.mainnet-beta.solana.com',
  autoConnect: false,
};

const solanaSlice = createSlice({
  name: 'solana',
  initialState,
  reducers: {
    setMasterConfig(state, action: PayloadAction<MasterConfigNormalized>) {
      state.masterConfig = action.payload;
    },
    setIsMasterConfigLoading(state, action: PayloadAction<boolean>) {
      state.isMasterConfigLoading = action.payload;
    },
    openConnectWalletModal(state) {
      state.isConnectWalletModalVisible = true;
    },
    closeConnectWalletModal(state) {
      state.isConnectWalletModalVisible = false;
    },

    disableAutoConnect(state) {
      state.autoConnect = false;
    },
    enableAutoConnect(state) {
      state.autoConnect = true;
    },
  },
});

const persistConfig = generatePersistConfig('solana', ['autoConnect']);
export const solanaActions = solanaSlice.actions;

export default persistReducer<SolanaStateType>(persistConfig, solanaSlice.reducer);
