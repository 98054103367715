import client from 'configs/axios';
import { ChainIds } from 'configs/chain';
import ApiResponse from 'types/ApiResponse';
import Trending from 'types/Trending';
import Pair, {
  IPair24hChange,
  IPairDetail,
  IPairExtraInfo,
  IPairHistoryTransaction,
  IPairSearch,
  ITopBuyerSeller,
  IWalletTransaction,
} from 'types/Pair';
import compact from 'lodash/compact';
import { CommonStatus } from 'configs/enums';

interface PairActivityParams {
  chainId?: number | string;
  factory?: string;
  pageIndex?: number;
  pageSize?: number;
}

interface PairDetailParams {
  baseToken?: string;
  chainId?: ChainIds;
  quoteToken?: string;
  address?: string;
}

interface PairExtraInfoParams {
  chainId?: ChainIds;
  address?: string;
}

type pairAddress_chainId = string;

export interface PairActivity24hInfo {
  volume24h: number;
  priceChange24h: number;
  totalTransaction24h: number;
}
type SwapPairDetailParam = Omit<PairDetailParams, 'address'>;

export interface PairHistoryTransactionParams {
  limit?: number;
  chainId: string;
  pair: string;
  timeAfter?: number;
  logIndexAfter?: number;
}

export interface WalletTxParams {
  baseToken?: string;
  walletAddress?: string;
  chainId?: number;
}

export interface TopBuyerSellerParams {
  chainId?: ChainIds;
  pairAddress?: string;
  isBuy: CommonStatus.ACTIVE | CommonStatus.INACTIVE;
  from?: number;
  to?: number;
}

export interface VoteInfoParams {
  chainId?: string;
  pairAddress?: string;
  walletAddress?: string;
}

export interface VoteParams {
  chainId: string;
  signature: string;
  signedMessage: string;
  walletAddress?: string;
}

export interface VoteResponse {
  totalLike?: number;
  totalDislike?: number;
  isLike?: boolean;
  isDislike?: boolean;
}

type MultiChartResponse = Record<string, IPair24hChange>;

export async function getActivity(params: PairActivityParams) {
  return (await client.get<ApiResponse<Pair[]>>('/pairs', { params })).data;
}

export async function getNewPairs(params: PairActivityParams) {
  return (
    await client.get<
      ApiResponse<{
        data: Pair[];
      }>
    >('/pairs', { params: { ...params, isNewPair: 1 } })
  ).data;
}

export async function getTrendings(params: { chainId?: string; pageIndex?: number; pageSize?: number }) {
  return (await client.get<ApiResponse<any>>('pairs/trending', { params })).data;
}

export async function getPairDetailAndActivityInfo(params: PairDetailParams) {
  return (await client.get<{ data: IPairDetail | null }>('pairs/find-one', { params }))?.data;
}

export async function getPairExtraInfo(params: PairExtraInfoParams) {
  return (await client.get<{ data?: IPairExtraInfo }>('pairs/extra-info', { params }))?.data;
}

export async function getSwapPairDetail(params: SwapPairDetailParam) {
  return (
    await client.get<ApiResponse<IPairDetail | null>>('swap/pair', {
      params: {
        chainId: params.chainId,
        addresses: [params.baseToken, params.quoteToken],
      },
    })
  )?.data;
}

export async function searchPair(keyword: string) {
  const dataFromApi = (await client.get<ApiResponse<IPairSearch[]>>('pairs/search', { params: { keyword } })).data;

  if (dataFromApi?.data && !dataFromApi?.data?.length) {
    const web3Common = await import('utils/web3Common');
    const dataFromBlockChain = await web3Common.getTokenInfoFromBlockChain(keyword);
    dataFromApi.data.push(...compact(dataFromBlockChain));
  }

  return dataFromApi;
}

export async function getPairHistoryTransaction(params: PairHistoryTransactionParams) {
  const queryParams = {
    limit: 100,
    ...params,
  };

  return (await client.get<ApiResponse<IPairHistoryTransaction[]>>('pairs/list-tx', { params: queryParams })).data;
}

export async function getWalletTrx(params: WalletTxParams) {
  return (await client.get<ApiResponse<IWalletTransaction[]>>('token/wallet-recently-tx', { params })).data;
}

export async function getTopBuyerSeller(params: TopBuyerSellerParams) {
  return (await client.get<ApiResponse<ITopBuyerSeller>>('pairs/buyer-seller', { params })).data;
}

export async function getMultiChart(params: { pairAddress: string[] }) {
  return (await client.get<ApiResponse<MultiChartResponse>>('pairs/pair-activity-24h', { params })).data;
}

export async function likePair(params: VoteParams) {
  return (await client.post<ApiResponse<VoteResponse>>('pairs/like', params)).data;
}

export async function dislikePair(params: VoteParams) {
  return (await client.post<ApiResponse<VoteResponse>>('pairs/dislike', params)).data;
}

export async function removeVote(params: VoteParams) {
  return (await client.post<ApiResponse<VoteResponse>>('pairs/remove-vote', params)).data;
}

export async function getUserProfile(walletAddress?: string) {
  return (
    await client.get<ApiResponse<{ isCommunityWhiteListWallet: boolean }>>('user/profile', {
      params: { walletAddress },
    })
  ).data;
}

export async function getVoteInfo(params: VoteInfoParams) {
  return (
    await client.get<ApiResponse<VoteResponse>>('/pairs/community-vote', {
      params,
    })
  ).data;
}

export async function getPairActivity24hInfo(params: pairAddress_chainId[]) {
  return (
    await client.get<ApiResponse<{ [key: pairAddress_chainId]: PairActivity24hInfo }>>('pairs/pair-activity-24h', {
      params: {
        pairAddress: params,
      },
    })
  ).data;
}

export async function getPairSocialNetworkUpdates(params: { chainId?: number }) {
  return (await client.get<ApiResponse<Pair[]>>('/pairs/social-network-updates', { params })).data;
}

export async function getPair(params: { chainId: string; address?: string; baseToken?: string }) {
  const dataFromApi = (await client.get<ApiResponse<IPairDetail>>('pairs/', { params })).data;
  return dataFromApi;
}


export async function getOnePair(params: { chainId: string; address?: string; baseToken?: string }) {
  const dataFromApi = (await client.get<ApiResponse<IPairDetail>>('pairs/find-one', { params })).data;
  return dataFromApi;
}
