import { createSlice } from '@reduxjs/toolkit';
import { generatePersistConfig } from 'utils/misc';
import { persistReducer } from 'redux-persist';

interface State {
  isVisible: boolean;
}

const swapFormVisible = createSlice({
  name: 'swapFormVisible',
  initialState: { isVisible: false },
  reducers: {
    handleOpenSwapForm: (state) => {
      state.isVisible = true;
    },
    handleCloseSwapForm: (state) => {
      state.isVisible = false;
    },
  },
});

export const { handleCloseSwapForm, handleOpenSwapForm } = swapFormVisible.actions;
const persistConfig = generatePersistConfig('swapFormVisible', []);
export default persistReducer<State>(persistConfig, swapFormVisible.reducer);
