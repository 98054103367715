import { useCallback, useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { Pagination } from 'types';
import PairActivity from '../components/PairActivity';
import { useLocation, useParams } from 'react-router-dom';
import { ChainNames } from 'configs/chain';
import { getChainIdFromChainName, getFullChainNameFromChainName } from 'utils/chain';
import path from 'configs/path';
import Chains from '../components/Chains';
import { Helmet } from 'react-helmet-async';
import useTranslation from 'shared/hooks/useTranslation';
import ReactGa from 'react-ga4';
import usePairSocialNetworkUpdates from '../hooks/usePairSocialUpdates';
import { HomeScreen } from 'types/Pair';

function TokenSocialNetworkUpdates() {
  const { t } = useTranslation('home');
  const { pathname } = useLocation();
  const { chain } = useParams<{
    chain?: ChainNames;
    dex?: string;
  }>();

  const [pagination, setPagination] = useState<Pagination>({
    page: 1,
    limit: 100,
  });

  useEffect(() => {
    ReactGa.send({
      hitType: 'pageview',
      path: pathname,
      title: chain ? `Social Network Updates - ${chain.toUpperCase()}` : 'Social Network Updates ',
    });
  }, [chain, pathname]);

  const { data, isLoading } = usePairSocialNetworkUpdates({
    chainId: chain ? getChainIdFromChainName(chain) : undefined,
  });

  const onNext = useCallback(() => setPagination((prev) => ({ ...prev, page: prev.page + 1 })), []);
  const onPrevious = useCallback(() => setPagination((prev) => ({ ...prev, page: prev.page - 1 })), []);
  return (
    <>
      <Helmet>
        <title>
          {chain
            ? t('seoSocialNetworkUpdates', { fullChainName: getFullChainNameFromChainName(chain) })
            : t('seoTitleDefault')}
        </title>
      </Helmet>
      <Box>
        <Chains
          chainName={chain}
          routePath={path.SOCIAL_NETWORK_UPDATES}
          chainPath={path.SOCIAL_NETWORK_UPDATES_WITH_CHAIN}
        />
      </Box>
      <Box mt={2}>
        <PairActivity
          homeScreen={HomeScreen.SOCIAL_NETWORK_UPDATES}
          isLoading={isLoading}
          data={data}
          pagination={pagination}
          onNext={onNext}
          onPrevious={onPrevious}
        />
      </Box>
    </>
  );
}

export default TokenSocialNetworkUpdates;
