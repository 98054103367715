import { WalletBalance } from 'types';
import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { generatePersistConfig } from 'utils/misc';
import { persistReducer } from 'redux-persist';

export const MAX_HISTORY = 21;

interface State {
  historyData: WalletBalance[];
}

const initialState: State = {
  historyData: [],
};

const historyToken = createSlice({
  name: 'historyToken',
  initialState,
  reducers: {
    removeHistory: (state: State, action: PayloadAction<string>) => {
      const newData = current(state.historyData)?.filter((item) => item?.token !== action?.payload);
      state.historyData = newData;
    },
    addHistory: (state: State, action: PayloadAction<WalletBalance>) => {
      const newData = current(state.historyData)?.filter((item) => item?.token !== action?.payload?.token);
      if (current(state.historyData)?.length !== newData?.length) {
        state.historyData = [action.payload, ...newData]?.slice(0, MAX_HISTORY);
      } else {
        state.historyData = [action.payload, ...current(state.historyData)]?.slice(0, MAX_HISTORY);
      }
    },
  },
});

export const { removeHistory, addHistory } = historyToken.actions;
const persistConfig = generatePersistConfig('historyToken', ['historyData']);
export default persistReducer<State>(persistConfig, historyToken.reducer);
