import { Flex, Image, Link, Text, useColorModeValue } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';

interface Props {
  icon?: string;
  title: string;
  href: string;
  isInactive?: boolean;
}

function DexItem({ icon, title, href, isInactive }: Props) {
  const borderColorActive = useColorModeValue('blackAlpha.400', 'blackAlpha.700');
  const borderColor = useColorModeValue('blackAlpha.50', 'blackAlpha.500');
  return (
    <Link
      as={NavLink}
      to={href}
      whiteSpace="nowrap"
      borderBottomWidth={4}
      borderBottomColor="transparent"
      _activeLink={{
        borderBottomColor: isInactive ? undefined : borderColorActive,
      }}
      _focus={{
        boxShadow: 'none',
      }}
      _hover={{ borderBottomColor: isInactive ? undefined : borderColor }}
      px={4}
      py={2}
      transition="border 250ms ease-in-out"
    >
      <Flex alignItems="center" gap={2}>
        {icon ? <Image src={icon} w={4} h={4} /> : null}
        <Text>{title}</Text>
      </Flex>
    </Link>
  );
}

export default DexItem;
