import { ChainOutlineIcon, PhotoOutlineIcon, PlayOutlineIcon } from 'shared/components/Icons/Proof';
import { useState } from 'react';
import { Link } from 'react-router-dom';

interface Props {
  link?: string;
  video?: string;
  image?: string;
}
const Proof = ({ image, link, video }: Props) => {
  const [proofHover, setProofHover] = useState('');
  return (
    <div className="flex items-center gap-1 justify-center">
      <Link to={link ?? '#'} target="_blank">
        <div onMouseEnter={() => setProofHover('chain')} onMouseLeave={() => setProofHover('')}>
          <ChainOutlineIcon color={link ? '#0bc662' : '#27593a'} />
        </div>
      </Link>
      <Link to={image ?? '#'} target="_blank">
        <div onMouseEnter={() => setProofHover('photo')} onMouseLeave={() => setProofHover('')}>
          <PhotoOutlineIcon color={image ? '#0bc662' : '#27593a'} />
        </div>
      </Link>
      <Link to={video ?? '#'} target="_blank">
        <div onMouseEnter={() => setProofHover('play')} onMouseLeave={() => setProofHover('')}>
          <PlayOutlineIcon color={video ? '#0bc662' : '#27593a'} />
        </div>
      </Link>
    </div>
  );
};

export default Proof;
