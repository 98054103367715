import { WalletBalance } from 'types';
import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { generatePersistConfig } from 'utils/misc';
import { persistReducer } from 'redux-persist';
import { decimalValue } from 'utils/format';

interface State {
  hiddenData: WalletBalance[];
}

const initialState: State = {
  hiddenData: [],
};
const calculateAmount = (wallet: WalletBalance): number => {
  return wallet?.priceUsd
    ? decimalValue(wallet?.priceUsd || '0')
        .mul(wallet?.balance || '0')
        .toNumber()
    : 0;
};
const walletHidden = createSlice({
  name: 'walletHidden',
  initialState,
  reducers: {
    removeHidden: (state: State, action: PayloadAction<{ token: string; chainId: string }>) => {
      const newData = current(state.hiddenData)?.filter(
        (item) => item?.token !== action?.payload?.token && item.chainId === action?.payload?.chainId,
      );
      state.hiddenData = newData;
    },
    addHidden: (state: State, action: PayloadAction<WalletBalance>) => {
      state.hiddenData = [action.payload, ...current(state.hiddenData)]?.sort(
        (a, b) => calculateAmount(b) - calculateAmount(a),
      );
    },
  },
});

export const { removeHidden, addHidden } = walletHidden.actions;
const persistConfig = generatePersistConfig('walletHidden', ['hiddenData']);
export default persistReducer<State>(persistConfig, walletHidden.reducer);
