import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { BackpackWalletAdapter } from '@solana/wallet-adapter-backpack';
import {
  LedgerWalletAdapter,
  MathWalletAdapter,
  PhantomWalletAdapter,
  SafePalWalletAdapter,
  SolflareWalletAdapter,
  TokenPocketWalletAdapter,
  TorusWalletAdapter,
  WalletConnectWalletAdapter,
} from '@solana/wallet-adapter-wallets';
import constants from 'configs/constants';

const PHANTOM_PROVIDER = {
  name: 'Phantom',
  url: 'https://phantom.app',
  adapter: new PhantomWalletAdapter(),
};

const SOLFLARE_PROVIDER = {
  name: 'Solflare',
  url: 'https://solflare.com',
  adapter: new SolflareWalletAdapter(),
};

const TORUS_PROVIDER = {
  name: 'Torus',
  url: 'https://tor.us',
  adapter: new TorusWalletAdapter(),
};

const BACKPACK_PROVIDER = {
  name: 'Backpack',
  url: 'https://backpack.app',
  adapter: new BackpackWalletAdapter(),
};

const MATH_PROVIDER = {
  name: 'MathWallet',
  url: 'https://mathwallet.org',
  adapter: new MathWalletAdapter(),
};

const SAFEPAL_PROVIDER = {
  name: 'SafePal',
  url: 'https://safepal.com',
  adapter: new SafePalWalletAdapter(),
};

const TOKEN_POCKET_PROVIDER = {
  name: 'TokenPocket',
  url: 'https://www.tokenpocket.pro',
  adapter: new TokenPocketWalletAdapter(),
};

const LEDGER_PROVIDER = {
  name: 'Ledger',
  url: 'https://www.ledger.com/',
  adapter: new LedgerWalletAdapter(),
};

const WALLET_CONNECT = {
  name: 'WalletConnect',
  url: 'https://walletconnect.com/',
  adapter: new WalletConnectWalletAdapter({
    // TODO make network dynamic
    network: WalletAdapterNetwork.Mainnet,
    options: {
      projectId: constants.WALLET_CONNECT_PROJECT_ID,
      metadata: {
        name: 'Alpha',
        description: 'AlphaDex',
        url: 'https://www.alpha.com',
        icons: ['https://alphadex.site/logo.png'],
      },
    },
  }),
};

export const WALLET_PROVIDERS = [
  PHANTOM_PROVIDER,
  TORUS_PROVIDER,
  BACKPACK_PROVIDER,
  SOLFLARE_PROVIDER,
  MATH_PROVIDER,
  SAFEPAL_PROVIDER,
  TOKEN_POCKET_PROVIDER,
  WALLET_CONNECT,
  LEDGER_PROVIDER,
];

export const DEFAULT_PROVIDER = PHANTOM_PROVIDER;
