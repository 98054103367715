import { useEffect, useMemo, useState } from 'react';
import { Text, Box, Icon, TableContainer, useColorModeValue, useMediaQuery } from '@chakra-ui/react';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  ColumnDef,
  SortingState,
  getSortedRowModel,
} from '@tanstack/react-table';
import Pair, { HomeScreen } from 'types/Pair';
import PairItem from './PairItem';
import DataTable from 'shared/components/DataTable';
import DataTableRow from 'shared/components/DataTableRow';
import DataTableCell from 'shared/components/DataTableCell';
import { themeConstants } from 'configs/theme';
import constants from 'configs/constants';
import { twMerge } from 'tailwind-merge';
import { FilterCircleIcon, SortIcon } from 'shared/components/Icons/Filter';
import { fireIcon, infoCircleBlackIcon } from 'assets/icons';

type DataTableProps<Data extends Pair> = {
  data: Data[];
  columns: ColumnDef<Data, any>[];
  currentPage: number;
  limit: number;
  homeScreen?: HomeScreen;
};

function PairTableV2<Data extends Pair>({ data, columns, currentPage, limit, homeScreen }: DataTableProps<Data>) {
  const [sorting, setSorting] = useState<SortingState>(
    homeScreen === HomeScreen.NEW_PAIR
      ? [
          {
            id: 'tradingStartTime',
            desc: true,
          },
        ]
      : [],
  );
  // const bgTableHeader = useColorModeValue(themeConstants.rowHoverLight, themeConstants.rowHoverDark);
  const [isLargerThan1300] = useMediaQuery('(min-width: 1300px)');
  const [, setTimer] = useState(0);

  const gridTemplateColumns = useMemo(() => {
    const screenKey = isLargerThan1300 ? 'DEFAULT' : 'MEDIUM';

    return [HomeScreen.NEW_PAIR, HomeScreen.SOCIAL_NETWORK_UPDATES].includes(homeScreen as any)
      ? constants.GRID_TEMPLATE_COLUMNS?.[homeScreen as HomeScreen]?.[screenKey]
      : undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [homeScreen]);

  useEffect(() => {
    let id: number;
    if (homeScreen === HomeScreen.NEW_PAIR) {
      // @ts-ignore
      id = setInterval(() => {
        setTimer((prev) => {
          return prev + 1;
        });
      }, 1000);
    }
    return () => {
      clearInterval(id);
    };
  }, [homeScreen]);

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
  });

  return (
    <div className="w-full overflow-x-auto">
      <table className="w-full bg-transparent">
        {table.getHeaderGroups().map((headerGroup) => {
          return (
            <thead key={headerGroup.id}>
              <tr className="h-[30px] bg-[#131722]">
                {headerGroup.headers.map((header) => {
                  const meta: any = header.column.columnDef.meta;
                  // const title = header.column.columnDef.header;

                  return (
                    <th
                      className={twMerge(
                        'min-w-[86px] px-1 text-center',
                        header.id === '_id' && 'min-w-11',
                        header.id === 'baseTokenSymbol' && 'min-w-[225px] pl-[60px]',
                        header.id === 'factory' && 'min-w-11',
                        (header.id === 'pairCreatedTime' || header.id === 'tradingStartTime') && 'min-w-[100px]',
                      )}
                      key={header.id}
                    >
                      <div className={twMerge('flex items-center justify-center gap-[2px]', meta?.align)}>
                        <div className={twMerge('hidden', meta?.isInfo && 'block')}>
                          <img src={infoCircleBlackIcon} alt="" />
                        </div>
                        <div className={twMerge('hidden', meta?.isFilter && 'block')}>
                          <FilterCircleIcon />
                        </div>
                        <span className="text-13 font-bold text-white">
                          {flexRender(header.column.columnDef.header, header.getContext())}
                        </span>
                        <div className={twMerge('hidden', meta?.isSort && 'block')}>
                          <SortIcon />
                        </div>
                      </div>
                    </th>
                  );
                })}
              </tr>
            </thead>
          );
        })}
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <PairItem
                gridTemplateColumns={gridTemplateColumns}
                key={row.id}
                row={row}
                currentPage={currentPage}
                limit={limit}
                homeScreen={homeScreen}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
export default PairTableV2;
