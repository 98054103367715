import { useQuery } from '@tanstack/react-query';
import { getResource } from 'apis/resource';
import constants from 'configs/constants';
import queryKey from 'configs/queryKey';

export default function useResources(enabled = false) {
  return useQuery([queryKey.RESOURCE], getResource, {
    enabled,
    staleTime: Infinity,
    cacheTime: constants.CACHE_TIME[queryKey.RESOURCE],
  });
}
