import { ChakraProvider } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { FC, PropsWithChildren } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';

import theme from 'configs/theme';
import { persistor, store } from 'shared/state/store';
import { PersistGate } from 'redux-persist/integration/react';
import ChildProviders from 'ChildProviders';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      // cacheTime: 0, // no cache, cloudflare handle itself
      retry: false,
    },
  },
});

const Providers: FC<PropsWithChildren<{}>> = ({ children }) => (
  <HelmetProvider>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <ChildProviders>
              <ChakraProvider theme={theme}>{children}</ChakraProvider>
            </ChildProviders>
          </BrowserRouter>
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  </HelmetProvider>
);

export default Providers;
