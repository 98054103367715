import { useQuery } from '@tanstack/react-query';
import { getPairSocialNetworkUpdates } from 'apis/pair';
import { ChainIds } from 'configs/chain';
import queryKey from 'configs/queryKey';

interface Params {
  chainId?: ChainIds;
}

function usePairSocialNetworkUpdates({ chainId }: Params, autoFetch = true) {
  return useQuery(
    [queryKey.NEW_PAIRS, chainId],
    () =>
      getPairSocialNetworkUpdates({
        chainId,
      }),
    {
      enabled: autoFetch,
    },
  );
}

export default usePairSocialNetworkUpdates;
