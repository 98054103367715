import { FC } from 'react';
import { formatNumeral, formatPriceValue, separateNumber } from 'utils/format';
import { ChakraProps, Flex, Text } from '@chakra-ui/react';

interface IProps extends ChakraProps {
  value?: number | string | null;
  prefix?: string;
  symbol?: string;
  color?: any;
  default?: string;
  numeral?: string;
  fractionDigits?: number;
  isCalcNumeralFormat?: boolean;
  decimalPlaces?: number;
  isAllowZero?: boolean;
}

const getValueNumeral = (valueFormatted: string, isCalcNumeralFormat?: boolean, numeral?: string) => {
  try {
    if (isCalcNumeralFormat) {
      const zeros = new Array(String(valueFormatted).split('.')[1]?.length || 0).fill('0').join('');
      return formatNumeral(valueFormatted, `0,0.[${zeros || '0000'}]`);
    }

    return formatNumeral(
      valueFormatted,
      numeral || (Number(valueFormatted) > 0 && Number(valueFormatted) < 1 ? '0,0.[000000]' : '0,0.[00]'),
    );
  } catch (error) {
    return valueFormatted;
  }
};
const FormatNumberDecimal: FC<IProps> = (props) => {
  const { ...rest } = props;

  if (props.isAllowZero && props.value === '0') {
    return (
      <Text {...rest}>
        {props.prefix}0 {props.symbol}
      </Text>
    );
  }

  if (!props.value || props.value === '0')
    return (
      <Text {...rest}>
        {props.default || 'N/A'} {props.symbol}
      </Text>
    );

  const valueFormatted = formatPriceValue(String(props.value || 0), props.decimalPlaces, props.fractionDigits);

  if (valueFormatted.includes('_')) {
    const [digits, pow, value] = valueFormatted.split('_');
    return (
      <Flex {...rest}>
        {props.prefix}
        <Text as="span">{separateNumber(String(digits), ',')}</Text>
        <Text as="span" mt="0.5rem" fontSize="0.7em">
          {pow}
        </Text>
        <Text as="span">{Number(value)}</Text>
        {props.symbol && (
          <Text as="span" paddingLeft="1">
            {props.symbol}
          </Text>
        )}
      </Flex>
    );
  }

  return (
    <Flex {...rest}>
      {props.prefix}
      <Text as="span">{getValueNumeral(valueFormatted, props.isCalcNumeralFormat, props.numeral)}</Text>
      {props.symbol && (
        <Text as="span" paddingLeft="1">
          {props.symbol}
        </Text>
      )}
    </Flex>
  );
};

export default FormatNumberDecimal;
