import { ColorModeScript } from '@chakra-ui/react';
import * as ReactDOM from 'react-dom/client';
import 'utils/i18n'; // important: must import before ./App
import App from './App';
import Providers from './Providers';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';

import './index.css';
import 'react-datepicker/dist/react-datepicker.css';
import './date-picker.css';

const container = document.getElementById('root');
if (!container) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(container);

root.render(
  <>
    <ColorModeScript initialColorMode="dark" />
    <Providers>
      <App />
    </Providers>
  </>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
