import { PairLabel } from 'configs/enums';
import { IKycDetail } from './dex_type';
import Decimal from 'decimal.js';
import { Badge, SocialLinks } from './Trending';

interface Pair {
  _id: string;
  address: string;
  chainId: string;
  basePrice: number;
  baseToken: string;
  baseTokenName: string;
  baseTokenSymbol: string;
  factory: string;
  fdv: number;
  liquidity: number;
  pairCreatedTime: number;
  priceChange1h: number;
  priceChange24h: number;
  priceChange5m: number;
  priceChange6h: number;
  priceUsd: number;
  quoteToken: string;
  quoteTokenSymbol: string;
  totalTransaction24h: number;
  volume24h: number;
  href?: string;
  totalBuy24h: number;
  totalSell24h: number;
  tradingStartTime: number;
  baseTokenLogo: string;
  quoteTokenLogo: string;
  createdAt?: number;
  athPreSale?: number;
  athListing?: number;
  baseTokenInfo?: BaseTokenInfo | null;
  lastAction?: string;
  badges?: Badge[];
  socialLinks?: SocialLinks;
}

interface BaseTokenInfo {
  address: string;
  chainId: string;
  description?: string;
  status: number;
  telegram?: string;
  twitter?: string;
  updateBy?: string;
  website?: string;
  owner?: string;
  discord?: string;
  validators: IKycDetail;
  github?: string;
  updatedAt?: number;
}

export interface IPairSearch {
  _id: string;
  address: string;
  quoteTokenName: string;
  chainId: string;
  basePrice: number;
  baseToken: string;
  baseTokenName: string;
  baseTokenSymbol: string;
  factory: string;
  liquidity: number;
  priceChange24h: number;
  priceUsd: number;
  quoteToken: string;
  quoteTokenSymbol: string;
  totalTransaction24h: number;
  volume24h: number;
  baseTokenLogo: string;
  quoteTokenLogo: string;
  href?: string;
}

export interface Score {
  avg: number;
  information: number;
  transaction: number;
  holder: number;
  lpLock: number;
  kyc: number;
  maxScore?: number;
}

export interface IPairDetail {
  _id: string;
  address: string;
  chainId: string;
  factory: string;
  token0: string;
  decimal0: number;
  token1: string;
  decimal1: number;
  blockNumber: number;
  baseToken: string;
  quoteToken: string;
  status: number;
  basePrice: number;
  liquidity: number;
  priceUsd: number;
  reserve0: string;
  labels: PairLabel[];
  reserve1: string;
  fdv: number;
  baseTokenData: BaseTokenData;
  quoteTokenData: QuoteTokenData;
  pairCreatedTime: number;
  newInformation: NewInformation;
  baseTokenPairs: BaseTokenPair[];
  totalSupply: string;
  totalBurned: string;
  tradingStartTime: number;
  baseTokenInfo?: BaseTokenInfo | null;
  holders: number;
  baseTokenLogo: string;
  score?: Score;
  ath?: number;
  athPrice?: number;
  athPriceUsd?: number;
  isHoneypotSetByAdmin?: boolean | null;
  lpMint?: string;
  token0Symbol: string;
  token1Symbol: string;
}

export interface IPairExtraInfo {
  ath: number | null;
  athPrice: number | null;
  athPriceUsd: number | null;
  listingPrice: number | null;
  listingPriceUsd: number | null;
}

export interface BaseTokenPair {
  address: string;
  baseTokenData: BaseTokenData;
}

export interface BaseTokenPair {
  _id: string;
  address: string;
  chainId: string;
  factory: string;
  liquidity: number;
  priceUsd: number;
  quoteReserve: number;
  baseTokenData: BaseTokenData;
  quoteTokenData: QuoteTokenData;
  labels?: PairLabel[];
}

export interface BaseTokenData {
  address: string;
  decimal: number;
  name: string;
  symbol: string;
}

export interface QuoteTokenData {
  address: string;
  decimal: number;
  name: string;
  symbol: string;
}

export interface NewInformation {
  _id: string;
  volume24h: number;
  priceChange24h: number;
  totalTransaction24h: number;
  // address: string;
  // chainId: string;
  // __v: number;
  // baseToken: string;
  // baseTokenName: string;
  // baseTokenSymbol: string;
  // factory: string;
  // fdv: number;
  // liquidity: number;
  // priceChange1h: number;
  // priceChange5m: number;
  // priceChange6h: number;
  // priceUsd: number;
  // quoteToken: string;
  // quoteTokenName: string;
  // quoteTokenSymbol: string;
  // router: string;
  // status: number;
  // time: number;
  // totalBuy1h: number;
  // totalBuy24h: number;
  // totalBuy5m: number;
  // totalBuy6h: number;
  // totalSell1h: number;
  // totalSell24h: number;
  // totalSell5m: number;
  // totalSell6h: number;
  // totalTransaction1h: number;
  // totalTransaction5m: number;
  // totalTransaction6h: number;
  // volume1h: number;
  // volume5m: number;
  // volume6h: number;
  // updateAt: number;
  // basePrice: number;
  // updatedAt: Date;
  // latestBuildCandleTime: number;
  // createdAt?: string;
}

export interface IPairHistoryTransaction {
  _id: string;
  time: number;
  hash: string;
  pair: string;
  chainId: string;
  liquidity: string;
  quotePrice: string;
  token0: string;
  reserve0: string;
  reserve1: string;
  token1: string;
  logIndex: number;
  isBuy: boolean;
  basePrice: string;
  priceUsd: string;
  amountUsd: string;
  txFrom: string;
  txTo: string;
  isContract?: boolean;
  isHidden: boolean;
  baseAmount: string;
  quoteAmount: string;

  /**Calculate and assign into item */
  makerTradeInfo?: IMakerTradeInfo;
}

export interface IWalletTransaction {
  _id: string;
  time: number;
  hash: string;
  pair: string;
  // amountIn: string;
  // amountOut: string;
  isBuy: boolean;
  priceUsd: string;
  amountUsd: string;
  baseToken: string;
  quoteToken: string;
  basePrice: string;
  isReserve: boolean;
  baseAmount: string;
  currentValue: string;
  value: string;
  baseTokenName: string;
  baseTokenSymbol: string;
  quoteTokenSymbol: string;
  quoteAmount: string;
  chainId?: number;
}

export interface ITopBuyerSeller {
  from: number;
  to: number;
  data: IAmountBuyerSeller[];
}

export interface IAmountBuyerSeller {
  amountUsd: number;
  _id: string;
}

export interface IPair24hChange {
  priceChange24h: number;
  totalTransaction24h: number;
  volume24h: number;
}

export enum HomeScreen {
  NEW_PAIR = 'NEW_PAIR',
  SOCIAL_NETWORK_UPDATES = 'SOCIAL_NETWORK_UPDATES',
}

export interface IMakerTradeInfo {
  firstSeenOn: number | null;
  totalTx: number;
  totalBuy: number;
  totalSell: number;
  volume: Decimal;
  volumeBuy: Decimal;
  volumeSell: Decimal;
  profit: Decimal;
  isPositive: boolean | null;
  priceUsd: {
    min: Decimal;
    max: Decimal;
    avg: Decimal;
  };
  baseToken: {
    buy: Decimal;
    sell: Decimal;
  };
  quoteToken: {
    buy: Decimal;
    sell: Decimal;
  };
}

export default Pair;
