import { createAction, createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { generatePersistConfig } from 'utils/misc';
import { persistReducer, REHYDRATE } from 'redux-persist';
import { ITabData, LayoutType } from 'types/MultiChart';
import isEmpty from 'lodash/isEmpty';
import Pair from 'types/Pair';

interface State {
  multiChartData: ITabData;
}

const rehydrateAction = createAction<State | undefined>(REHYDRATE);

export const MAX_CHART = 16;
export const TAB_DEFAULT = {
  settings: {
    columns: {
      value: 4,
      change: 1,
      max: 8,
      min: 1,
    },
    height: {
      value: 100,
      change: 10,
      min: 100,
      max: 300,
    },
    spacing: {
      value: 1,
      change: 1,
      max: 8,
      min: 1,
    },
  },
  interval: 1,
  chartData: [],
};

const initialState: State = {
  multiChartData: {
    Main: { ...TAB_DEFAULT, createdAt: new Date().getTime() },
  },
};

const multiChart = createSlice({
  name: 'multiChart',
  initialState,
  reducers: {
    addTab: (state: State, action: PayloadAction<string>) => {
      state.multiChartData = {
        ...current(state?.multiChartData),
        [action.payload?.trim()]: { ...TAB_DEFAULT, createdAt: new Date().getTime() },
      };
    },
    removeTab: (state: State, action: PayloadAction<string>) => {
      const newData = { ...current(state?.multiChartData) };
      delete newData[action.payload?.trim()];
      state.multiChartData = isEmpty(newData) ? { Main: { ...TAB_DEFAULT, createdAt: new Date().getTime() } } : newData;
    },
    changeNameTab: (state: State, action: PayloadAction<{ newName: string; oldName: string }>) => {
      const { newName, oldName } = action.payload;
      const newData = {
        ...current(state?.multiChartData),
        [newName]: current(state?.multiChartData)[oldName],
      };
      delete newData[oldName];
      state.multiChartData = newData;
    },
    addChart: (state: State, action: PayloadAction<{ tabName: string; pair: Pair }>) => {
      const { tabName, pair } = action.payload;
      state.multiChartData = {
        ...current(state?.multiChartData),
        [tabName]: {
          ...current(state?.multiChartData)[tabName],
          chartData: [...current(state?.multiChartData)[tabName].chartData, pair],
        },
      };
    },
    removeChart: (state: State, action: PayloadAction<{ tabName: string; address: string }>) => {
      const { tabName, address } = action.payload;
      const itemTab = current(state?.multiChartData)[tabName];
      const chartData = itemTab?.chartData?.filter((item) => item?.address !== address);
      state.multiChartData = { ...current(state?.multiChartData), [tabName]: { ...itemTab, chartData } };
    },
    changeSetting: (state: State, action: PayloadAction<{ setting: LayoutType; tabName: string; value: any }>) => {
      const { tabName, setting, value } = action.payload;
      const tabItem = current(state.multiChartData)[tabName];
      state.multiChartData = {
        ...current(state.multiChartData),
        [tabName]: {
          ...tabItem,
          settings: { ...tabItem.settings, [setting]: { ...tabItem.settings[setting], value } },
        },
      };
    },
    changeInterval: (state: State, action: PayloadAction<{ tabName: string; value: any }>) => {
      const { tabName, value } = action.payload;
      state.multiChartData = {
        ...current(state.multiChartData),
        [tabName]: { ...current(state.multiChartData)[tabName], interval: value },
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(rehydrateAction, (state, { payload }) => {
      if (payload?.multiChartData) state.multiChartData = payload.multiChartData;
    });
  },
});

export const { addTab, removeTab, changeNameTab, addChart, removeChart, changeSetting, changeInterval } =
  multiChart.actions;
const persistConfig = generatePersistConfig('multiChart', ['multiChartData']);
export default persistReducer<State>(persistConfig, multiChart.reducer);
