import Card from 'shared/components/Card';
import Pair, { HomeScreen } from 'types/Pair';
import { createColumnHelper } from '@tanstack/react-table';
import { ChainIds } from 'configs/chain';
import SimplePagination from 'shared/components/SimplePagination';
import { Pagination } from 'types';
import Loading from 'shared/components/Loading';
import { useMemo } from 'react';
import PairTableV2 from './table/PairTableV2';
interface Props {
  isLoading: boolean;
  data: any;
  pagination: Pagination;
  chainId?: ChainIds;
  factory?: string;
  onNext?: () => void;
  onPrevious?: () => void;
  homeScreen?: HomeScreen;
}

const columnHelper = createColumnHelper<Pair>();

const DefaultColumns = [
  columnHelper.accessor('baseTokenSymbol', {
    cell: (info) => info.getValue(),
    header: 'Pair',
    meta: {
      isInfo: true,
      align: 'justify-start',
      textAlign: 'text-left',
      isFilter: false,
      isSort: true,
    },
  }),
  columnHelper.accessor('badges', {
    // cell: (info) => {
    //   return ['M', 'D', 'S', 'K', 'A'];
    // },
    cell: (info) => info.getValue(),
    header: 'Badge',
    meta: {
      isSort: true,
    },
  }),
  columnHelper.accessor('socialLinks', {
    cell: (info) => info.getValue(),
    header: 'Social',
    meta: {
      isSort: true,
    },
  }),
  columnHelper.accessor('athListing', {
    cell: (info) => info.getValue(),
    header: 'Dev of',
    meta: {
      isSort: true,
    },
  }),
  columnHelper.accessor('baseTokenInfo', {
    cell: (info) => ({
      status: 'success',
      amount: 10,
    }),
    header: 'Scan',
    meta: {
      isSort: true,
    },
  }),
  columnHelper.accessor('priceUsd', {
    cell: (info) => info.getValue(),
    header: 'Price',
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor('totalTransaction24h', {
    cell: (info) => info.getValue(),
    header: 'Txns',
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor('volume24h', {
    cell: (info) => info.getValue(),
    header: 'Volume',
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor('priceChange5m', {
    cell: (info) => info.getValue(),
    header: '5M',
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor('priceChange1h', {
    cell: (info) => info.getValue(),
    header: '1H',
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor('priceChange6h', {
    cell: (info) => info.getValue(),
    header: '6H',
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor('priceChange24h', {
    cell: (info) => info.getValue(),
    header: '24H',
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor('liquidity', {
    cell: (info) => info.getValue(),
    header: 'Liquidity',
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor('fdv', {
    cell: (info) => info.getValue(),
    header: 'FDV',
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor('factory', {
    cell: (info) => info.getValue(),
    header: 'Dex',
  }),
  columnHelper.accessor('address', {
    cell: (info) => info.getValue(),
    header: 'Action',
  }),
];

const NewPairColumns = [
  columnHelper.accessor('baseTokenSymbol', {
    cell: (info) => info.getValue(),
    header: 'Pair',
    meta: {
      isInfo: true,
      align: 'justify-start',
      textAlign: 'text-left',
      isFilter: false,
      isSort: true,
    },
  }),
  columnHelper.accessor('badges', {
    // cell: (info) => {
    //   return ['M', 'D', 'S', 'K', 'A'];
    // },
    cell: (info) => info.getValue(),
    header: 'Badge',
    meta: {
      isSort: true,
    },
  }),
  columnHelper.accessor('socialLinks', {
    cell: (info) => info.getValue(),
    header: 'Social',
    meta: {
      isSort: true,
    },
  }),
  columnHelper.accessor('athListing', {
    cell: (info) => info.getValue(),
    header: 'Dev of',
    meta: {
      isSort: true,
    },
  }),
  columnHelper.accessor('baseTokenInfo', {
    cell: (info) => ({
      status: 'success',
      amount: 10,
    }),
    header: 'Scan',
    meta: {
      isSort: true,
    },
  }),
  columnHelper.accessor('priceUsd', {
    cell: (info) => info.getValue(),
    header: 'Price',
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor('tradingStartTime', {
    cell: (info) => info.getValue(),
    header: 'Age',
    meta: {
      isNumeric: true,
      textAlign: 'left',
    },
  }),
  columnHelper.accessor('totalBuy24h', {
    cell: (info) => info.getValue(),
    header: 'Buys',
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor('totalSell24h', {
    cell: (info) => info.getValue(),
    header: 'Sells',
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor('totalTransaction24h', {
    cell: (info) => info.getValue(),
    header: 'Txns',
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor('volume24h', {
    cell: (info) => info.getValue(),
    header: 'Volume',
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor('priceChange5m', {
    cell: (info) => info.getValue(),
    header: '5M',
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor('priceChange1h', {
    cell: (info) => info.getValue(),
    header: '1H',
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor('priceChange6h', {
    cell: (info) => info.getValue(),
    header: '6H',
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor('priceChange24h', {
    cell: (info) => info.getValue(),
    header: '24H',
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor('liquidity', {
    cell: (info) => info.getValue(),
    header: 'Liquidity',
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor('fdv', {
    cell: (info) => info.getValue(),
    header: 'FDV',
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor('factory', {
    cell: (info) => info.getValue(),
    header: 'Dex',
  }),
  columnHelper.accessor('address', {
    cell: (info) => info.getValue(),
    header: 'Action',
  }),
];

const SocialNetworkUpdatedColumns = [
  columnHelper.accessor('baseTokenSymbol', {
    cell: (info) => info.getValue(),
    header: 'Pair',
    meta: {
      isInfo: true,
      align: 'justify-start',
      textAlign: 'text-left',
      isFilter: false,
      isSort: true,
    },
  }),
  columnHelper.accessor('baseTokenInfo', {
    cell: (info) => {
      return ['M', 'D', 'S', 'K', 'A'];
    },
    header: 'Badge',
    meta: {
      isSort: true,
    },
  }),
  columnHelper.accessor('baseTokenInfo', {
    cell: (info) => info.getValue(),
    header: 'Social',
    meta: {
      isSort: true,
    },
  }),
  columnHelper.accessor('athListing', {
    cell: (info) => info.getValue(),
    header: 'Dev of',
    meta: {
      isSort: true,
    },
  }),
  columnHelper.accessor('baseTokenInfo', {
    cell: (info) => ({
      status: 'success',
      amount: 10,
    }),
    header: 'Scan',
    meta: {
      isSort: true,
    },
  }),
  columnHelper.accessor('lastAction', {
    cell: (info) => info.getValue(),
    header: 'Activity',
    meta: {
      align: 'justify-end',
      textAlign: 'text-right',
      isSort: true,
    },
  }),
  columnHelper.accessor('lastAction', {
    cell: (info) => info.getValue(),
    header: 'Detail',
    meta: {
      align: 'justify-end',
      textAlign: 'text-right',
    },
  }),
  columnHelper.accessor('baseTokenInfo', {
    cell: (info) => info.getValue()?.status,
    header: 'Status',
    meta: {
      align: 'justify-end',
      textAlign: 'text-right',
    },
  }),
  columnHelper.accessor('baseTokenInfo', {
    cell: (info) => info.getValue(),
    header: 'Upload',
    meta: {
      align: 'justify-end',
      textAlign: 'text-right',
      isSort: true,
    },
  }),
  columnHelper.accessor('baseTokenInfo', {
    cell: (info) => info.getValue(),
    header: 'Proof',
    meta: {
      align: 'justify-end',
      textAlign: 'text-right',
    },
  }),
  columnHelper.accessor('factory', {
    cell: (info) => info.getValue(),
    header: 'Dex',
  }),
  columnHelper.accessor('address', {
    cell: (info) => info.getValue(),
    header: 'Action',
  }),
];

function getColumns(homeScreen?: HomeScreen) {
  if (homeScreen === HomeScreen.NEW_PAIR) return NewPairColumns;
  if (homeScreen === HomeScreen.SOCIAL_NETWORK_UPDATES) return SocialNetworkUpdatedColumns;
  return DefaultColumns;
}

function PairActivity({ isLoading, data, pagination, onNext, onPrevious, homeScreen }: Props) {
  const columnsData = useMemo(() => {
    return getColumns(homeScreen);
  }, [homeScreen]);

  return (
    <Card p={0} bg="transparent">
      <PairTableV2
        data={data?.data || ([] as any)}
        columns={columnsData}
        homeScreen={homeScreen}
        currentPage={pagination.page}
        limit={pagination.limit}
      />
      {isLoading ? <Loading p={10} borderTopWidth={1} /> : null}
      {data ? (
        <div className="mt-3 flex items-center justify-end gap-2">
          <SimplePagination
            onNext={onNext}
            onPrevious={onPrevious}
            page={pagination.page}
            pageLimit={pagination.limit}
            totalItems={data?.totalItems ? Number(data?.totalItems) : data?.data.length}
            isPreviousDisabled={pagination.page === 1}
            isNextDisabled={pagination.page >= Number(data?.totalPages)}
            buttonGroupProps={{
              mt: 2,
            }}
          />
        </div>
      ) : null}
    </Card>
  );
}

export default PairActivity;
