import { Icon, IconProps } from '@chakra-ui/react';

function LogoCat(props: IconProps) {
  return (
    <Icon viewBox="0 0 1024 1024" {...props}>
      <g>
        <path
          d="M323.131,474.485c-26.075,0-47.218,21.143-47.218,47.219c0,26.074,21.144,47.217,47.218,47.217
		s47.219-21.143,47.219-47.217C370.35,495.628,349.206,474.485,323.131,474.485"
        />
        <path
          d="M681.663,568.922c26.075,0,47.217-21.145,47.217-47.219c0-26.075-21.142-47.218-47.217-47.218
		s-47.219,21.143-47.219,47.218C634.444,547.777,655.588,568.922,681.663,568.922"
        />
        <path
          d="M1024,521.48C1024,233.474,794.77,0,512,0C229.229,0,0,233.474,0,521.48C0,761.086,158.67,962.921,374.821,1024
		c42.284-104.358,71.563-233.847,84.028-361.78c-205.333-8.866-364.052-68.41-364.052-140.517
		c0-50.179,76.875-94.272,192.79-119.428L349.757,191.2l56.966,193.423c16.972-1.522,34.381-2.65,52.126-3.436
		c17.407-0.735,35.15-1.129,53.151-1.129s35.744,0.394,53.151,1.129c17.743,0.786,35.152,1.914,52.125,3.436L674.242,191.2
		l62.17,211.075c115.916,25.156,192.792,69.249,192.792,119.428c0,72.106-158.72,131.65-364.053,140.517
		c12.464,127.934,41.743,257.422,84.027,361.78C865.329,962.921,1024,761.086,1024,521.48"
        />
      </g>
    </Icon>
  );
}

export default LogoCat;
