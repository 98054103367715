export const ContactIcon: React.FC<{
  width?: number;
  height?: number;
  color?: string;
}> = ({ width = 20, height = 16, color = '#808080' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_374_1884)">
        <path
          d="M3.94995 9.8V6.4C3.94995 3.4 6.64995 1 10.05 1C13.35 1 16.15 3.4 16.15 6.4V9.8C16.15 12.8 13.45 15.2 10.05 15.2"
          stroke={color}
          strokeMiterlimit="10"
        />
        <path
          d="M4.54998 5.40002C4.44998 5.40002 4.34998 5.40002 4.34998 5.40002C2.34998 5.40002 0.849976 6.90002 0.849976 8.80002V9.40002C0.849976 11.3 2.34998 12.8 4.24998 12.8C4.34998 12.8 4.44998 12.8 4.44998 12.8V5.40002H4.54998Z"
          fill={color}
        />
        <path
          d="M15.45 5.40002C15.55 5.40002 15.65 5.40002 15.65 5.40002C17.55 5.40002 19.05 6.90002 19.05 8.80002V9.40002C19.05 11.3 17.55 12.8 15.65 12.8C15.55 12.8 15.45 12.8 15.45 12.8V5.40002Z"
          fill={color}
        />
        <path
          d="M12.15 15.1C12.15 15.6 11.75 16 11.25 16H8.84995C8.34995 16 7.94995 15.6 7.94995 15.1C7.94995 14.6 8.34995 14.2 8.84995 14.2H11.15C11.75 14.1 12.15 14.5 12.15 15.1Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_374_1884">
          <rect width="18.3" height="15.5" fill="white" transform="translate(0.849976 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};
