import constants from 'configs/constants';
import { useCallback } from 'react';
import { createGlobalState } from 'react-use';

interface AppState {
  connectModalVisible: boolean;
  switchNetworkModalVisible: boolean;
  windowWidth: number;
  sidebarCollapsed: boolean;
  drawerMenuOpen: boolean;
  searchPanel: boolean;
  swapFormPosition: 'chartHeader' | 'walletSidebar';
}

interface AppStateWithMethods extends AppState {
  setAppState: (state: Partial<AppState>) => void;
  toggleSidebar: () => void;
}

const appState = createGlobalState<AppState>({
  connectModalVisible: false,
  switchNetworkModalVisible: false,
  windowWidth: window.innerWidth || window.outerWidth,
  sidebarCollapsed: true,
  drawerMenuOpen: false,
  searchPanel: false,
  swapFormPosition:
    localStorage.getItem(constants.LOCAL_STORAGE_KEY.swapSettings.position) &&
    localStorage.getItem(constants.LOCAL_STORAGE_KEY.swapSettings.position) === 'walletSidebar'
      ? 'walletSidebar'
      : 'chartHeader',
});

function useAppState(): AppStateWithMethods {
  const [state, setState] = appState();

  const setAppState = useCallback(
    (args: Partial<AppState>) => {
      setState((prev) => ({ ...prev, ...args }));
    },
    [setState],
  );

  const toggleSidebar = useCallback(() => {
    setState((prev) => ({ ...prev, sidebarCollapsed: !prev.sidebarCollapsed }));
  }, [setState]);

  return {
    ...state,
    setAppState,
    toggleSidebar,
  };
}

export default useAppState;
