import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { generatePersistConfig } from 'utils/misc';
import { persistReducer } from 'redux-persist';

interface State {
  isExpand: boolean;
}

const goPlusExpand = createSlice({
  name: 'goPlusExpand',
  initialState: { isExpand: false },
  reducers: {
    updateGoPlusExpand: (state, action: PayloadAction<{ isExpand: boolean }>) => {
      state.isExpand = action.payload.isExpand;
    },
  },
});

export const { updateGoPlusExpand } = goPlusExpand.actions;
const persistConfig = generatePersistConfig('goPlusExpand', []);
export default persistReducer<State>(persistConfig, goPlusExpand.reducer);
