import { Box, BoxProps, GridItem, GridItemProps } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';

function DataTableCell({ children, ...props }: PropsWithChildren<BoxProps>) {
  return (
    <Box as="td" {...props}>
      {children}
    </Box>
  );

  // return <GridItem {...props}>{children}</GridItem>;
}

export default DataTableCell;
