import { useMemo } from 'react';
import { useAppSelector } from 'shared/hooks/reduxHook';
import { Web3ModalProvider } from './connection/Web3ModalContext';
import { WALLET_PROVIDERS as SOLANA_WALLET_PROVIDERS } from 'connection/wallet-adapters';
import {
  WalletProvider as SolanaWalletProvider,
  ConnectionProvider as SolanaConnectionProvider,
} from '@solana/wallet-adapter-react';

function ChildProviders({ children }: { children: JSX.Element }) {
  const autoConnect = useAppSelector((state) => state.solana.autoConnect);
  const endpoint = useAppSelector((state) => state.solana.currentRPC);
  const wsEndpoint = useAppSelector((state) => state.solana.currentWS);
  const supportedWallets = useMemo(() => {
    return SOLANA_WALLET_PROVIDERS.map((provider) => provider.adapter);
  }, []);
  return (
    <SolanaConnectionProvider endpoint={endpoint} config={{ wsEndpoint, commitment: 'processed' }}>
      <SolanaWalletProvider wallets={supportedWallets} autoConnect={autoConnect}>
        <Web3ModalProvider>{children}</Web3ModalProvider>
      </SolanaWalletProvider>
    </SolanaConnectionProvider>
  );
}

export default ChildProviders;
