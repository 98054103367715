import { ChainIds } from 'configs/chain';
import { OwnerType, TokenInfoStatus } from 'configs/enums';
import { JsonRpcProvider, Web3Provider } from '@ethersproject/providers';

export interface IBarCandle {
  time: number;
  low: number;
  high: number;
  open: number;
  close: number;
  volume: number;
}
export interface MultiCallMethods {
  name: string;
  args?: any[];
}
export interface CallsParams {
  address: string;
  abi: string[] | Record<string, any>[] | any;
  methods: MultiCallMethods[];
  functionName?: string;
  args?: any[];
}

export interface MultiCallParams {
  calls: Array<CallsParams>;
  enabled?: boolean;
  chainId?: number;
}

export interface MultiCallEnhancedParams {
  calls: Array<CallsParams>;
  chainId?: number;
  provider: any;
}

export interface IAppendBarCandle {
  address: string;
  chainId: string;
  amountUsd: number | string;
  priceUsd: number | string;
  time: number;
  isOnlySyncPrice?: boolean;
}
export interface Pagination {
  page: number;
  limit: number;
}
export interface WalletBalance {
  chainId: string;
  token: string;
  name: string;
  symbol: string;
  decimals: number;
  balance: number;
  // balanceOf: BigNumber;
  amountUsd?: number;
  priceUsd?: number | null;
  pair?: string | null;
  isWrapped?: boolean;

  // Assign when scan honeypot & rug
  isRug?: boolean;
  isHoneypot?: boolean;
}

export interface FavoriteData {
  chainId: string;
  token: string;
  name: string;
  symbol: string;
  priceUsd?: string | number | null;
}

export enum CommonStatus {
  IDLE,
  LOADING,
  SUCCESS,
  ERROR,
}

export interface ITokenInfo {
  txHash: string;
  chainId: number;
  signature: string;
  signedMessage: string;
  website: string;
  telegram: string;
  twitter: string;
  description: string;
  tokenAddress: string;
  type?: OwnerType;
  creatorTxn?: string;
  owner?: string;
  _id?: string;
  logo?: string;
  tokenInfoRequest?: ITokenInfo;
  status?: TokenInfoStatus;
  discord?: string;
  github?: string;
}

export enum Resolution {
  ONE_SECOND = '1S',
}

export type Web3CommonArgs<T> = T & {
  chainId?: ChainIds;
  provider: Web3Provider | JsonRpcProvider | null;
};
