import {
  Flex,
  Spacer,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  useBreakpointValue,
  Text,
  Link,
  Image,
  useColorModeValue,
} from '@chakra-ui/react';
import chunk from 'lodash/chunk';
import useTranslation from 'shared/hooks/useTranslation';
import { NavLink, useParams } from 'react-router-dom';
import { HiOutlineChevronDown } from 'react-icons/hi';
import Card from 'shared/components/Card';
import { getChainIcon, getChainNameFromChainId } from 'utils/chain';
import { buildHref } from 'utils/path';
import DexItem from './DexItem';
import { useMemo } from 'react';
import { supportedChains } from 'configs/networks';

interface Props {
  chainName?: string;
  chainPath: string;
  routePath: string;
}

function Chains({ chainName, routePath, chainPath }: Props) {
  const { t } = useTranslation('home');
  const { chain } = useParams<{
    chain?: string;
  }>();
  const noOfVisibleChain = useBreakpointValue([1, 3, 5, 6, 8, 10]);
  const bgActive = useColorModeValue('gray.100', 'blackAlpha.700');
  const data = useMemo(() => {
    const tmp = supportedChains || [];
    if (tmp.length <= 8) {
      return {
        visibleChains: tmp,
        invisibleChains: [],
      };
    }
    const [visibleChains, ...chunks] = chunk(tmp, noOfVisibleChain);
    return {
      visibleChains: visibleChains || [],
      invisibleChains: [...chunks].flat(),
    };
  }, [noOfVisibleChain]);
  return (
    <Card as="nav" p={0} pr={2}>
      <Flex as="ul" listStyleType="none" alignItems="center" fontSize="sm">
        <DexItem title={t('allChain')} href={buildHref(routePath, { chain: chainName || '' })} isInactive={!!chain} />
        {data.visibleChains.map((item) => {
          const icon = getChainIcon(item.id);
          return (
            <DexItem
              key={item.id}
              title={item.name}
              href={buildHref(chainPath, {
                chain: getChainNameFromChainId(Number(item.id)) || '',
              })}
              icon={icon}
            />
          );
        })}
        {data.invisibleChains.length > 0 ? (
          <>
            <Spacer />
            <Menu>
              <MenuButton as={Button} rightIcon={<HiOutlineChevronDown />} size="sm">
                {t('more')}
              </MenuButton>
              <MenuList maxH="50vh" overflowY="auto">
                {data.invisibleChains.map((item) => {
                  const icon = getChainIcon(item.id);
                  return (
                    <MenuItem key={item.id} p={0}>
                      <Link
                        key={item.id}
                        as={NavLink}
                        to={buildHref(chainPath, {
                          chain: getChainNameFromChainId(Number(item.id)) || '',
                        })}
                        whiteSpace="nowrap"
                        transition="background 250ms ease-in-out"
                        borderBottomWidth={2}
                        borderBottomColor="transparent"
                        display="block"
                        textDecoration="none"
                        p={2}
                        px={4}
                        w="100%"
                        _activeLink={{
                          bgColor: bgActive,
                        }}
                        _focus={{
                          boxShadow: 'none',
                        }}
                        _hover={{
                          textDecoration: 'none',
                        }}
                      >
                        <Flex alignItems="center" gap={2}>
                          <Image src={icon} w={4} h={4} />
                          <Text>{item.name}</Text>
                        </Flex>
                      </Link>
                    </MenuItem>
                  );
                })}
              </MenuList>
            </Menu>
          </>
        ) : null}
      </Flex>
    </Card>
  );
}

export default Chains;
