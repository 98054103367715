export const ChevronRightCircleIcon: React.FC<{
  size?: string;
  color?: string;
  colorBg?: string;
}> = ({ size = 17, color = '#1A1A1A', colorBg = 'black' }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.7 17.15C13.2287 17.15 16.9 13.4787 16.9 8.95C16.9 4.42126 13.2287 0.75 8.7 0.75C4.17126 0.75 0.5 4.42126 0.5 8.95C0.5 13.4787 4.17126 17.15 8.7 17.15Z"
        fill={color}
      />
      <path
        d="M12.3 9.75C12.8 9.35 12.8 8.65 12.3 8.25L7.69999 4.55C7.19999 4.15 6.49999 4.15 5.99999 4.55C5.49999 4.85 5.59999 5.55 6.09999 5.95L8.99999 8.25C9.49999 8.65 9.49999 9.35 8.99999 9.75L6.09999 12.05C5.59999 12.45 5.59999 13.05 5.99999 13.45C6.49999 13.85 7.19999 13.75 7.69999 13.35L12.3 9.75Z"
        fill={colorBg}
      />
    </svg>
  );
};

export const ArrowIcon: React.FC<{
  width?: number;
  height?: number;
  color?: string;
}> = ({ width = 8, height = 10, color = '#17C467' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_77_7082)">
        <path
          d="M3.60001 0.499988L0.400012 5.19999C0.300012 5.39999 0.300012 5.59999 0.400012 5.69999C0.500012 5.79999 0.700012 5.89999 0.900012 5.89999H2.80001V9.59999H5.50001V5.89999H7.40001C7.60001 5.89999 7.80001 5.79999 7.90001 5.69999C8.00001 5.59999 8.00001 5.39999 7.90001 5.19999L4.60001 0.499988C4.50001 0.399988 4.30001 0.299988 4.10001 0.299988C3.90001 0.299988 3.70001 0.399988 3.60001 0.499988Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_77_7082">
          <rect width="7.7" height="9.4" fill="white" transform="translate(0.299988 0.299988)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const ArrowCircleIcon: React.FC<{
  size?: number;
  color?: string;
}> = ({ size = 22, color = '#454545' }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group">
        <path
          id="Subtract"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.3333 11C22.3333 17.0751 17.4084 22 11.3333 22C5.25812 22 0.333252 17.0751 0.333252 11C0.333252 4.92487 5.25812 0 11.3333 0C17.4084 0 22.3333 4.92487 22.3333 11ZM10.3941 8.31704C9.72332 8.31704 9.18674 7.78045 9.18674 7.10972C9.18674 6.43899 9.72332 5.9024 10.2599 5.76826H15.4916C16.1623 5.76826 16.5648 6.30484 16.5648 6.84143V12.0731C16.5648 12.7439 16.0282 13.2805 15.3575 13.2805C14.6867 13.2805 14.1502 12.7439 14.1502 12.0731V10.7317C14.1502 10.5975 13.8819 10.4634 13.7477 10.5975L8.38186 15.6951C7.97942 16.2317 7.17454 16.2317 6.63796 15.6951C6.10137 15.2926 6.10137 14.4878 6.63796 13.9512L11.8697 8.71948C12.0038 8.58533 12.0038 8.31704 11.7355 8.31704H10.3941Z"
          fill={color}
        />
      </g>
    </svg>
  );
};
