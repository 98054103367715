/**
 * [Multicall3](https://github.com/mds1/multicall)
 */
import multicallABI from 'abi/multicall-v3.json';
import uniswapV2FactoryABI from 'abi/uniswap-v2-factory.json';
import uniswapV2RouterABI from 'abi/uniswap-v2-router.json';
import uniswapV2PairABI from 'abi/uniswap-v2-pair.json';
import alphaAdapter from 'abi/amm/adapter.json';
import alphaRouter from 'abi/amm/router.json';
import alphaPathFinder from 'abi/amm/path-finder.json';

const uniswapV2PairEventsInterface = [
  'event Swap(address indexed sender, uint256 amount0In, uint256 amount1In, uint256 amount0Out, uint256 amount1Out, address indexed to)',
  'event Transfer(address indexed from, address indexed to, uint256 value)',
];

const erc20ABI = [
  'function approve(address spender, uint256 amount) returns (bool)',
  'function decreaseAllowance(address spender, uint256 subtractedValue) returns (bool)',
  'function increaseAllowance(address spender, uint256 addedValue) returns (bool)',
  'function transfer(address recipient, uint256 amount) returns (bool)',
  'function transferFrom(address sender, address recipient, uint256 amount) returns (bool)',
  'function allowance(address owner, address spender) view returns (uint256)',
  'function balanceOf(address account) view returns (uint256)',
  'function decimals() view returns (uint8)',
  'function name() view returns (string)',
  'function symbol() view returns (string)',
  'function totalSupply() view returns (uint256)',
  'function isTokenGenerated(address token) view returns (bool)',
];

const abis = {
  multicallABI,
  erc20ABI,
  uniswapV2FactoryABI,
  uniswapV2RouterABI,
  uniswapV2PairABI,
  uniswapV2PairEventsInterface,
  alphaAdapter,
  alphaRouter,
  alphaPathFinder,
};

export default abis;
