import { Chain, ChainIds } from './chain';

export const DEFAULT_CHAIN_ID = parseInt(process.env.REACT_APP_CHAIN_ID as string, 10);

export const ethereum: Chain = {
  id: ChainIds.Ethereum,
  network: 'homestead',
  name: 'Ethereum',
  nativeCurrency: {
    name: 'Ether',
    symbol: 'ETH',
    decimals: 18,
  },
  blockExplorers: {
    etherscan: {
      name: 'Etherscan',
      url: 'https://etherscan.io',
    },
    default: {
      name: 'Etherscan',
      url: 'https://etherscan.io',
    },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 14353601,
    },
  },
  rpcUrls: {
    default: {
      http: ['https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
    },
    infura: {
      http: ['https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
    },
    public: {
      http: ['https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
    },
  },
};

export const ethereumFlashbot: Chain = {
  id: ChainIds.Ethereum,
  network: 'homestead',
  nativeCurrency: {
    name: 'Ether',
    symbol: 'ETH',
    decimals: 18,
  },
  blockExplorers: {
    etherscan: {
      name: 'Etherscan',
      url: 'https://etherscan.io',
    },
    default: {
      name: 'Etherscan',
      url: 'https://etherscan.io',
    },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 14353601,
    },
  },
  name: 'Flashbots RPC',
  rpcUrls: {
    default: {
      http: ['https://alpha-eth.rpc.blxrbdn.com'],
    },
    public: {
      http: ['https://alpha-eth.rpc.blxrbdn.com'],
    },
  },
};

export const avalandche: Chain = {
  id: 43114,
  name: 'Avalanche C-Chain',
  network: 'avalanche',
  rpcUrls: {
    default: {
      http: ['https://rpc.ankr.com/avalanche'],
    },
    public: {
      http: ['https://rpc.ankr.com/avalanche'],
    },
  },
  nativeCurrency: { name: 'Avalanche', symbol: 'AVAX', decimals: 18 },
  blockExplorers: {
    default: {
      name: 'snowtrace',
      url: 'https://snowtrace.io/',
    },
  },
};

export const fantomOpera: Chain = {
  id: 250,
  name: 'Fantom Opera',
  network: 'fantom',
  nativeCurrency: { name: 'Fantom', symbol: 'FTM', decimals: 18 },
  rpcUrls: {
    default: {
      http: ['https://rpc.ftm.tools'],
    },
    public: {
      http: ['https://rpc.ftm.tools'],
    },
  },
  blockExplorers: {
    default: {
      name: 'FTMScan',
      url: 'https://ftmscan.com',
    },
  },
};

const bscExplorer = { name: 'BscScan', url: 'https://bscscan.com' };

export const bsc: Chain = {
  id: 56,
  name: 'BNB Smart Chain',
  network: 'bsc',
  rpcUrls: {
    default: {
      http: ['https://nodes.pancakeswap.info'],
    },
    public: {
      http: ['https://nodes.pancakeswap.info'],
    },
    dataseed2: {
      http: ['https://nodes.pancakeswap.info'],
    },
    ninicoin: {
      http: ['https://bsc-dataseed1.ninicoin.io'],
    },
    defibit: {
      http: ['https://bsc-dataseed1.defibit.io'],
    },
    ankr: {
      http: ['https://rpc.ankr.com/bsc'],
    },
    bnb48: {
      http: ['https://rpc-bsc.bnb48.club'],
    },
    onerpc: {
      http: ['https://1rpc.io/bnb'],
    },
  },
  blockExplorers: {
    default: bscExplorer,
    etherscan: bscExplorer,
  },
  nativeCurrency: {
    name: 'Binance Chain Native Token',
    symbol: 'BNB',
    decimals: 18,
  },
  contracts: {
    multicall3: {
      address: '0xcA11bde05977b3631167028862bE2a173976CA11',
      blockCreated: 15921452,
    },
  },
};

export const bscFlashbot: Chain = {
  id: 56,
  name: 'BNB Smart Chain Anti-Mevbot',
  network: 'bsc',
  rpcUrls: {
    default: {
      http: ['https://alpha-bnb.rpc.blxrbdn.com'],
    },
    public: {
      http: ['https://alpha-bnb.rpc.blxrbdn.com'],
    },
  },
  blockExplorers: {
    default: bscExplorer,
    etherscan: bscExplorer,
  },
  nativeCurrency: {
    name: 'Binance Chain Native Token',
    symbol: 'BNB',
    decimals: 18,
  },
  contracts: {
    multicall3: {
      address: '0xcA11bde05977b3631167028862bE2a173976CA11',
      blockCreated: 15921452,
    },
  },
};

export const bscTest: Chain = {
  id: 97,
  name: 'BNB Smart Chain Testnet',
  network: 'bsc-testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Binance Chain Native Token',
    symbol: 'tBNB',
  },
  rpcUrls: {
    default: {
      http: ['https://data-seed-prebsc-1-s2.binance.org:8545/'],
    },
    public: {
      http: ['https://data-seed-prebsc-1-s2.binance.org:8545/'],
    },
  },
  blockExplorers: {
    default: { name: 'BscScan', url: 'https://testnet.bscscan.com' },
  },
  contracts: {
    multicall3: {
      address: '0xb66a4fE12138C4391A98F29E34EFE4Cc7A445AE5',
      blockCreated: 21965366,
    },
  },
  testnet: true,
};

export const arbitrum: Chain = {
  id: 42161,
  name: 'Arbitrum One',
  network: 'arb',
  rpcUrls: {
    default: {
      http: ['https://arbitrum-mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
    },
    infura: {
      http: ['https://arbitrum-mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
    },
    public: {
      http: ['https://arbitrum-mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
    },
  },
  blockExplorers: {
    default: { name: 'Arbi Scan', url: 'https://arbiscan.io' },
    etherscan: { name: 'Arbi Scan', url: 'https://arbiscan.io' },
  },
  nativeCurrency: {
    name: 'Ethereum Native Token',
    symbol: 'ETH',
    decimals: 18,
  },
  contracts: {
    multicall3: {
      address: '0xcA11bde05977b3631167028862bE2a173976CA11',
      blockCreated: 7654707,
    },
  },
};

export const polygon: Chain = {
  id: 137,
  name: 'Polygon',
  network: 'pol',
  rpcUrls: {
    default: {
      http: ['https://polygon-mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
    },
    public: {
      http: ['https://polygon-mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
    },
    infura: {
      http: ['https://polygon-mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
    },
  },
  blockExplorers: {
    default: { name: 'Polygon scan', url: 'https://polygonscan.com' },
    etherscan: { name: 'Polygon scan', url: 'https://polygonscan.com' },
  },
  nativeCurrency: {
    name: 'Matic',
    symbol: 'MATIC',
    decimals: 18,
  },
  contracts: {
    multicall3: {
      address: '0xcA11bde05977b3631167028862bE2a173976CA11',
      blockCreated: 25770160,
    },
  },
};

export const polygonFlashbot: Chain = {
  id: 137,
  name: 'Polygon Anti-Mevbot',
  network: 'pol',
  rpcUrls: {
    default: {
      http: ['https://alpha-polygon.rpc.blxrbdn.com'],
    },
    public: {
      http: ['https://alpha-polygon.rpc.blxrbdn.com'],
    },
  },
  blockExplorers: {
    default: { name: 'Polygon scan', url: 'https://polygonscan.com' },
    etherscan: { name: 'Polygon scan', url: 'https://polygonscan.com' },
  },
  nativeCurrency: {
    name: 'Matic',
    symbol: 'MATIC',
    decimals: 18,
  },
  contracts: {
    multicall3: {
      address: '0xcA11bde05977b3631167028862bE2a173976CA11',
      blockCreated: 25770160,
    },
  },
};

export const pulse: Chain = {
  id: 369,
  name: 'Pulse',
  network: 'pul',
  rpcUrls: {
    default: {
      http: ['https://rpc.pulsechain.com'],
    },
    public: {
      http: ['https://rpc.pulsechain.com'],
    },
  },
  blockExplorers: {
    default: { name: 'Pulse scan', url: 'https://scan.pulsechain.com' },
    etherscan: { name: 'Pulse scan', url: 'https://scan.pulsechain.com' },
  },
  nativeCurrency: {
    name: 'Pulse',
    symbol: 'PLS',
    decimals: 18,
  },
  contracts: {
    multicall3: {
      address: '0xFF677B3fe5Aa598EF4987dae5CfA480A9bd459de',
      blockCreated: 17336436,
    },
  },
};

export const bitrock: Chain = {
  id: 7171,
  name: 'Bit Rock',
  network: 'bit',
  rpcUrls: {
    default: {
      http: ['https://connect.bit-rock.io'],
    },
    public: {
      http: ['https://connect.bit-rock.io'],
    },
  },
  blockExplorers: {
    default: { name: 'BitRock Scan', url: 'https://scan.bit-rock.io' },
    etherscan: { name: 'BitRock Scan', url: 'https://scan.bit-rock.io' },
  },
  nativeCurrency: {
    name: 'Bitrock',
    symbol: 'BROCK',
    decimals: 18,
  },
  contracts: {
    multicall3: {
      address: '0x37dcBbeaC40471810DA2bc4B964e66fD277e8537',
      blockCreated: 749908,
    },
  },
};

export const cybria: Chain = {
  id: 6661,
  name: 'Cybria',
  network: 'cyb',
  rpcUrls: {
    default: {
      http: ['https://rpc-mainnet.cybria.io'],
    },
    public: {
      http: ['https://rpc-mainnet.cybria.io'],
    },
  },
  blockExplorers: {
    default: { name: 'Cybria Scan', url: 'https://cybascan.io' },
    etherscan: { name: 'Cybria Scan', url: 'https://cybascan.io' },
  },
  nativeCurrency: {
    name: 'CYBRIA',
    symbol: 'CYBA',
    decimals: 18,
  },
  contracts: {
    multicall3: {
      address: '0x00feC8b7EE9c14424F71b30Dac7C1CFE054B6771',
      blockCreated: 475942,
    },
  },
};

export const shibarium: Chain = {
  id: 109,
  name: 'Shibarium',
  network: 'shi',
  rpcUrls: {
    default: {
      http: ['https://www.shibrpc.com'],
    },
    public: {
      http: ['https://www.shibrpc.com'],
    },
  },
  blockExplorers: {
    default: { name: 'Shibarium Scan', url: 'https://www.shibariumscan.io' },
    etherscan: { name: 'Shibarium Scan', url: 'https://www.shibariumscan.io' },
  },
  nativeCurrency: {
    name: 'BONE',
    symbol: 'BONE',
    decimals: 18,
  },
  contracts: {
    multicall3: {
      address: '0xBE0b139ABc90723Af76a89D3051f60Ba1B64c8d9',
      blockCreated: 350512,
    },
  },
};

export const base: Chain = {
  id: 8453,
  name: 'Base',
  network: 'base',
  rpcUrls: {
    default: {
      http: ['https://mainnet.base.org'],
    },
    public: {
      http: ['https://mainnet.base.org'],
    },
    meowrpc: {
      http: ['https://base.meowrpc.com'],
    },
  },
  blockExplorers: {
    default: { name: 'Base Scan', url: 'https://basescan.org/' },
    etherscan: { name: 'Base Scan', url: 'https://basescan.org/' },
  },
  nativeCurrency: {
    name: 'ETH',
    symbol: 'ETH',
    decimals: 18,
  },
  contracts: {
    multicall3: {
      address: '0x01562823E9d3E5471Ed98d0138f2C87b7a40D3b1',
      blockCreated: 12641946,
    },
  },
};

export const supportedChains = [ethereum, bsc, arbitrum, polygon, pulse, bitrock, shibarium, cybria, base];
