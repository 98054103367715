import { supportedChains } from 'configs/networks';
import { CallsParams, WalletBalance } from 'types';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import { ChainIds } from 'configs/chain';
import { serializeError } from 'eth-rpc-errors';
import { EthereumErrorCode } from 'configs/enums';
import { t } from 'i18next';
import constants from 'configs/constants';
import Decimal from 'decimal.js';
import type { Location } from '@remix-run/router';
import queryString from 'querystring';
import { createSearchParams } from 'react-router-dom';
import { JsonRpcProvider, JsonRpcSigner } from '@ethersproject/providers';

export function sleep(ms = 500) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function loadScript(url: string) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = url;
    document.body.appendChild(script);

    script.onload = resolve;
    script.onerror = reject;
  });
}
export function isImagePath(icon: any) {
  return typeof icon === 'string';
}

export function callsToObject(calls: CallsParams, data: any) {
  const { methods } = calls;
  let resultObject = {};
  methods.forEach((e, i) => {
    resultObject = {
      ...resultObject,
      [e.name]: data?.[i],
    };
  });
  return resultObject;
}

export function arrayChunk(array: any[], sizes: number[]) {
  const chunks = [];
  let lastIndex = 0;
  for (let i = 0; i < sizes.length; i++) {
    chunks.push(array.slice(lastIndex, sizes[i]));
    lastIndex = sizes[i];
  }

  return chunks;
}

export function arrayToObject(
  array: Array<{
    name: string;
    data: any;
  }>,
) {
  const obj: Record<string, any> = {};
  for (let i = 0; i < array.length; i++) {
    if (Array.isArray(array[i].data) && array[i].data.length === 1) {
      obj[array[i].name] = array[i].data[0];
    } else {
      obj[array[i].name] = array[i].data;
    }
  }
  return obj;
}

export function getExplorerLink(chainId?: number, addressOrHash?: string, path: string = 'tx', subfix?: string) {
  if (!chainId || !addressOrHash) return '#';
  const network = Object.values(supportedChains).find((network) => network.id === Number(chainId));
  return network ? `${network.blockExplorers?.default.url}/${path}/${addressOrHash}${subfix || ''}` : '#';
}

export function getSolanaAccountExplorerLink(chainId?: number, address?: string) {
  if (!chainId || !address) return '#';
  const network = Object.values(supportedChains).find((network) => network.id === Number(chainId));
  if (!network) return '#';

  return `${network.blockExplorers?.default.url}/account/${address}`;
}

export function getTokenHolderDetailLink(chainId?: number, tokenAddress?: string, holder?: string) {
  return `${getExplorerLink(chainId, tokenAddress, 'token')}?a=${holder}`;
}

export function goToLink(url: string) {
  window.open(url, '_blank');
}

export function generatePersistConfig(key: string, whitelist: string[], blackList?: string[]) {
  return {
    key,
    whitelist,
    blackList,
    version: 1,
    debug: false,
    storage,
    stateReconciler: autoMergeLevel2,
  };
}
export function checkFavorite(favoriteData: WalletBalance[], address: string) {
  return !!favoriteData?.find((item) => item?.token.toLocaleLowerCase() === address.toLocaleLowerCase());
}

export function getBlockChainLogo(chainId: ChainIds, baseToken?: string, logo?: string) {
  if (logo) return logo;

  const trustwalletAssetURL = 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains';
  if (!baseToken || !chainId) return '';

  // To get more chain go to : https://github.com/trustwallet/assets/tree/master/blockchains
  // Flag: ADD_NEW_CHAIN
  const chainAsset: Record<ChainIds, string> = {
    [ChainIds.Bsc]: 'smartchain',
    [ChainIds.Ethereum]: 'ethereum',
    [ChainIds.Arbitrum]: 'arbitrum',
    [ChainIds.Polygon]: 'polygon',
    [ChainIds.Pulse]: 'pulse',
    [ChainIds.Bitrock]: 'bitrock',
    [ChainIds.Shibarium]: 'shibarium',
    [ChainIds.Cybria]: 'cybria',
    // [ChainIds.Solana]: 'solana',
    [ChainIds.Base]: 'base',
    [ChainIds.Avalanche]: '',
    [ChainIds.BscTest]: '',
    [ChainIds.FantomOpera]: '',
  };
  return `${trustwalletAssetURL}/${chainAsset[chainId]}/assets/${baseToken}/logo.png`;
}

export function extractErrorMessage(error: any) {
  const serialized = serializeError(error);
  if ((serialized?.data as any)?.code === EthereumErrorCode.INSUFFICIENT_FUND) {
    return t('insufficientFund');
  }
  const [segment1] = serialized.message.split('(');
  const [segment2] = (segment1 || '').split('[');
  return (serialized?.data as any)?.originalError?.reason || segment2 || serialized?.message;
}

export function getAssetUrl(path: string) {
  return constants.ASSETS_URL + path;
}

// P = ( sqrtPriceX96 / Q96 ) ^ 2
// basePrice = (1 / P) * (10 ** baseDecimal) / (10 ** quoteDecimal)
export function calcTokenPriceFromSqrtPriceX96(sqrtPriceX96: string, decimal0: number, decimal1: number) {
  const Q96 = new Decimal('2').pow(new Decimal('96'));
  const P = new Decimal(sqrtPriceX96).div(Q96).pow(new Decimal('2'));

  const powDecimal0 = new Decimal('10').pow(new Decimal(decimal0));
  const powDecimal1 = new Decimal('10').pow(new Decimal(decimal1));

  const basePrice = new Decimal('1').div(P).mul(powDecimal1.div(powDecimal0));
  return basePrice;
}

export function addSearchParams(location: Location, key: string, value?: string) {
  const params = new URLSearchParams(location.search);
  let newParams = {};
  params.forEach((item, keyItem) => {
    if (key !== keyItem) {
      newParams = { ...newParams, [keyItem]: item };
    }
  });
  const pathname = location.pathname;
  const search = `?${createSearchParams(value ? { ...newParams, [key]: value } : newParams)}`;
  return { pathname, search };
}

interface ILocation {
  search: string;
  [key: string]: any;
}
export function getSearchParams(location: ILocation, key: string) {
  const qs: {
    [key: string]: unknown;
  } = queryString.parse(location.search.replace(/\?/g, ''));
  return qs?.[key] as string | undefined;
}

export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export function sortTransaction(params: { time: number; logIndex: number; [key: string]: any }[]) {
  return params.sort((a, b) => b.logIndex - a.logIndex).sort((a, b) => b.time - a.time);
}

export function checkDisableHoneypot(pairCreatedTime?: number) {
  pairCreatedTime = Number(pairCreatedTime) || 0;
  const current = Date.now() / 1000;

  const createdSeconds = current - pairCreatedTime;

  return createdSeconds < constants.TIME_DO_NOT_WARNING_HONEYPOT;
}

export function extractEtherError(e: any): string {
  const serialized = serializeError(e);
  let msg = serialized?.message;
  if ((serialized.data as any)?.originalError?.reason) {
    msg = (serialized.data as any)?.originalError?.reason;
  }
  if (msg) {
    const [, containMsg = ''] = msg.split('execution reverted: ');
    const [err] = containMsg.split('",');
    return err ? err : msg;
  } else {
    return e?.message || e?.toString();
  }
}

export function getSigner(provider: JsonRpcProvider, account: string): JsonRpcSigner {
  return provider.getSigner(account).connectUnchecked();
}

export function getProviderOrSigner(provider: JsonRpcProvider, account?: string): JsonRpcProvider | JsonRpcSigner {
  return account ? getSigner(provider, account) : provider;
}
