import { WalletBalance } from 'types';
import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { generatePersistConfig } from 'utils/misc';
import { persistReducer } from 'redux-persist';
import { areEqualAddress } from 'utils/web3Common';

const MAX_FAVORITES = 20;

interface State {
  favoriteData: WalletBalance[];
}

const initialState: State = {
  favoriteData: [],
};

const favoriteToken = createSlice({
  name: 'favoriteToken',
  initialState,
  reducers: {
    removeFavorite: (state: State, action: PayloadAction<string>) => {
      const newData = current(state.favoriteData)?.filter((item) => !areEqualAddress(item?.token, action?.payload));
      state.favoriteData = newData;
    },
    addFavorite: (state: State, action: PayloadAction<WalletBalance>) => {
      const isFavored = current(state.favoriteData)?.find(
        (item) =>
          areEqualAddress(item?.token, action?.payload?.token) &&
          Number(item.chainId) === Number(action?.payload.chainId),
      );
      if (isFavored) {
        const newData = current(state.favoriteData)?.filter(
          (item) =>
            !areEqualAddress(item?.token, action?.payload?.token) ||
            Number(item.chainId) !== Number(action?.payload.chainId),
        );
        state.favoriteData = newData;
      } else {
        state.favoriteData = [action.payload, ...current(state.favoriteData)]?.slice(0, MAX_FAVORITES);
      }
    },
  },
});

export const { removeFavorite, addFavorite } = favoriteToken.actions;
const persistConfig = generatePersistConfig('favoriteToken', ['favoriteData']);
export default persistReducer<State>(persistConfig, favoriteToken.reducer);
