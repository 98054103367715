import { CopyOutlineIcon } from 'shared/components/Icons/Filter';
import { ChartColumnSquareIcon } from 'shared/components/Icons/Menu';
import { StarOutlineIcon } from 'shared/components/Icons/Star';
import { Tooltip } from '@nextui-org/react';
import { useState } from 'react';

interface IProps {
  onShowChart?: () => void;
  onCopy?: () => void;
  onAddWatchlist?: () => void;
}

const Action = ({ onShowChart, onAddWatchlist, onCopy }: IProps) => {
  const [actionHover, setActionHover] = useState('');

  return (
    <div className="flex items-center gap-[9px]">
      <Tooltip
        classNames={{
          content: 'border border-gray-800 bg-black text-gray-500 py-1 px-2 text-[13px] font-medium',
        }}
        content="copy"
      >
        <button
          type="button"
          onMouseEnter={() => setActionHover('copy')}
          onMouseLeave={() => setActionHover('')}
          onClick={onCopy}
        >
          <CopyOutlineIcon color={actionHover === 'copy' ? '#FF6F00' : '#4D4D4D'} />
        </button>
      </Tooltip>
      <Tooltip
        classNames={{
          content: 'border border-gray-800 bg-black text-gray-500 py-1 px-2 text-[13px] font-medium',
        }}
        content="add watchlist"
      >
        <button
          type="button"
          onMouseEnter={() => setActionHover('favorite')}
          onMouseLeave={() => setActionHover('')}
          onClick={onAddWatchlist}
        >
          <StarOutlineIcon color={actionHover === 'favorite' ? '#FF6F00' : '#4D4D4D'} />
        </button>
      </Tooltip>
      <Tooltip
        classNames={{
          content: 'border border-gray-800 bg-black text-gray-500 py-1 px-2 text-[13px] font-medium',
        }}
        content="show chart"
      >
        <button
          type="button"
          onMouseEnter={() => setActionHover('chart')}
          onMouseLeave={() => setActionHover('')}
          onClick={onShowChart}
        >
          <ChartColumnSquareIcon color={actionHover === 'chart' ? '#FF6F00' : '#4D4D4D'} />
        </button>
      </Tooltip>
    </div>
  );
};

export default Action;
