import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import { useCallback } from 'react';
import useTranslation from 'shared/hooks/useTranslation';
import { useAppSelector, useAppDispatch } from 'shared/hooks/reduxHook';
import { solanaActions } from 'shared/state/redux/solana';

function ModalStacks() {
  const { t } = useTranslation('common');

  const isSolanaConnectWalletModalVisible = useAppSelector((state) => state.solana.isConnectWalletModalVisible);

  const dispatch = useAppDispatch();

  const closeSolanaConnectWalletModal = useCallback(() => {
    dispatch(solanaActions.closeConnectWalletModal());
  }, [dispatch]);

  return (
    <>
      <Modal isOpen={isSolanaConnectWalletModalVisible} onClose={closeSolanaConnectWalletModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('Connect wallet')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{/* <SolanaWalletConnectModal /> */}</ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ModalStacks;
