export enum CommonStatus {
  ACTIVE = 1,
  INACTIVE = 0,
}

export enum EthereumErrorCode {
  INSUFFICIENT_FUND = -32000,
}

export enum OwnerType {
  Creator = 1,
  Owner = 2,
}

export enum TokenInfoStatus {
  INACTIVE = 0,
  ACTIVE = 1,
  WAITING_ACCEPT = 2,
  REJECTED = 3,
}

export enum PairLabel {
  V2 = 'v2',
  V3 = 'v3',
}

export enum FilterAmountType {
  BASE_TOKEN = 'BASE_TOKEN',
  QUOTE_TOKEN = 'QUOTE_TOKEN',
  USD = 'USD',
  PRICE = 'PRICE',
}
