/* eslint-disable react/no-unescaped-entities */
import { Alert, AlertTitle, Box, Button, Flex, ListItem, UnorderedList } from '@chakra-ui/react';
import { Component } from 'react';
import Logo from './Logo';

class ErrorBoundary extends Component {
  constructor(props: {}) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  render() {
    if ((this.state as any)?.hasError) {
      const reload = () => window.location.reload();
      const backToHome = () => (window.location.href = '/');
      return (
        <Flex alignItems="center" mt="30" justifyContent="center">
          <Alert status="info" w="1000px" maxW="100%" py="30px" variant="subtle" flexDirection="column">
            <Logo />
            <AlertTitle mt={4} mb={1} fontSize="lg">
              Maybe we get some errors below:
            </AlertTitle>
            <Box ml="20" my="5">
              <UnorderedList>
                <ListItem>The chain was incorrect</ListItem>
                <ListItem>Token address was incorrect</ListItem>
                <ListItem>RPC url was broken</ListItem>
                <ListItem>The network was lagged and hasn't responded</ListItem>
              </UnorderedList>
            </Box>
            <Flex gap="2">
              <Button onClick={reload}>Try to reload</Button>
              <Button onClick={backToHome}>Back to home</Button>
            </Flex>
          </Alert>
        </Flex>
      );
    }

    return (this.props as any).children;
  }
}

export default ErrorBoundary;
