import { Box } from '@chakra-ui/react';
import { AccessorKeyColumnDef, Cell, Row } from '@tanstack/react-table';
import numeralType from 'configs/numeral';
import path from 'configs/path';
import { themeConstants } from 'configs/theme';
import { useMemo } from 'react';
import DataTableCell from 'shared/components/DataTableCell';
import FormatNumberDecimal from 'shared/components/FormatNumberDecimal';
import FormatPercent from 'shared/components/FormatPercent';
import { useAppDispatch } from 'shared/hooks/reduxHook';
import useDexes from 'shared/hooks/useDexes';
import { addAddress } from 'shared/state/redux/selectAddressSlice';
import Dex from 'types/Dex';
import Pair, { HomeScreen } from 'types/Pair';
import { getChainNameFromChainId } from 'utils/chain';
import { formatTimePair, formatTimeTokenSocialNetwork } from 'utils/date';
import { formatNumeral, numberFormat } from 'utils/format';
import { buildHref } from 'utils/path';
import PairItemName from './PairItemName';
import BadgeStatus from 'shared/components/BadgeStatus';
import SocialStatus from 'shared/components/SocialStatus';
import DevOf from 'shared/components/DevOf';
import ScanStatus from 'shared/components/ScanStatus';
import Proof from 'shared/components/Proof';
import { Tooltip } from '@nextui-org/react';
import Action from 'shared/components/Action';
import { twMerge } from 'tailwind-merge';

type RowTableProps<Data extends Pair> = {
  row: Row<Data>;
  currentPage: number;
  limit: number;
  gridTemplateColumns?: string;
  homeScreen?: HomeScreen;
};

function PairItem<Data extends Pair>({ row, currentPage, limit, homeScreen }: RowTableProps<Data>) {
  const dispatch = useAppDispatch();
  const dexes = useDexes();
  const dex = useMemo(() => {
    return dexes?.find((dex: Dex) => dex.factory === row.original.factory);
  }, [dexes, row]);
  // const bgHover = useColorModeValue(themeConstants.rowHoverLight, themeConstants.rowHoverDark);
  // const bg = useColorModeValue(themeConstants.cardBgLight, themeConstants.cardBgDark);
  // const onClickPair = useCallback(() => {
  //   if (row?.original?.address && row.original?.baseToken) {
  //     dispatch(addAddress({ address: String(row.original.address), baseToken: String(row.original?.baseToken) }));
  //   }
  // }, [dispatch, row.original.address, row.original?.baseToken]);

  const handleRedirect = (chainId: string, pair: string, baseToken: string) => {
    return () => {
      if (pair && baseToken) {
        dispatch(addAddress({ address: pair, baseToken: baseToken }));
      }

      const redirect_path = buildHref(path.TRADE, {
        address: baseToken,
        chain: getChainNameFromChainId(Number(chainId)) as string,
      });
      window.location.href = redirect_path;
      // redirect(redirect_path);
    };
  };

  const renderCell = (cell: Cell<Data, unknown>) => {
    const columnDef = cell.column.columnDef as AccessorKeyColumnDef<Data, unknown>;
    const meta = columnDef.meta as any;
    const value = cell.getContext().getValue() as any;
    const accessorKey = columnDef.accessorKey;
    // get header name
    const header = cell.column.columnDef.header as string;

    if (accessorKey === 'baseTokenSymbol') {
      return (
        <DataTableCell
          key={header}
          className={twMerge(
            'h-full border-b border-r border-b-[#131722] border-r-[#131722] px-1 text-center text-[13px] font-medium text-white last:border-r-[0px]',
            meta?.textAlign,
          )}
          // position="sticky"
          overflow="hidden"
        >
          <PairItemName
            pair={row.original.address}
            quoteTokenLogo={cell.row.original.quoteTokenLogo}
            baseTokenLogo={cell.row.original.baseTokenLogo}
            rank={row.index + 1 + (currentPage - 1) * limit}
            baseTokenSymbol={cell.row.original.baseTokenSymbol}
            quoteTokenSymbol={cell.row.original.quoteTokenSymbol}
            tokenName={cell.row.original.baseTokenName}
            // dexLogo={dexLogo}
            baseToken={cell.row.original.baseToken}
            quoteToken={cell.row.original.quoteToken}
            chainId={cell.row.original.chainId}
          />
        </DataTableCell>
      );
    }
    if (accessorKey === 'priceUsd') {
      return (
        <DataTableCell key={header} overflow="hidden" textAlign={meta?.isNumeric ? 'right' : 'left'}>
          <FormatNumberDecimal justifyContent="flex-end" value={value as number} prefix={'$'} />
        </DataTableCell>
      );
    }
    if (accessorKey === 'volume24h' || accessorKey === 'liquidity' || accessorKey === 'fdv') {
      return (
        <DataTableCell key={header} textAlign={meta?.isNumeric ? 'right' : 'left'} overflow="hidden">
          {formatNumeral(value as number, numeralType.VOLUME_NUMBER, '$')}
        </DataTableCell>
      );
    }
    if (
      accessorKey === 'priceChange5m' ||
      accessorKey === 'priceChange1h' ||
      accessorKey === 'priceChange6h' ||
      accessorKey === 'priceChange24h'
    ) {
      return (
        <DataTableCell key={header} textAlign={meta?.isNumeric ? 'right' : 'left'} overflow="hidden">
          <FormatPercent
            color={Number(value) > 0 ? themeConstants.green : themeConstants.red}
            percent={Number(value)}
          />
        </DataTableCell>
      );
    }
    if (accessorKey === 'tradingStartTime' || accessorKey === 'pairCreatedTime') {
      return (
        <DataTableCell key={header} textAlign={meta?.textAlign} overflow="hidden">
          <span className="text-right text-13 font-medium text-white">
            {homeScreen === HomeScreen.SOCIAL_NETWORK_UPDATES
              ? formatTimeTokenSocialNetwork(value)
              : formatTimePair(value as number)}
          </span>
        </DataTableCell>
      );
    }

    if (accessorKey === 'createdAt') {
      return (
        <DataTableCell key={header} textAlign={meta?.isNumeric ? 'right' : 'left'} overflow="hidden">
          <span className="text-right text-13 font-medium text-white">
            {formatTimeTokenSocialNetwork(value as number)}
          </span>
        </DataTableCell>
      );
    }

    // handle duplicate id, check by header
    switch (header) {
      case 'Badge':
        return (
          <div key={header} className="flex h-5 items-center gap-2 rounded-lg bg-[#131722]">
            {value?.map((value: string) => (
              <BadgeStatus badgeKey={value} key={value} />
            ))}
          </div>
        );
      case 'Social':
        return (
          <div key={header} className="mx-auto w-fit">
            <SocialStatus isUpdated={!!value} />
          </div>
        );
      case 'Dev of':
        return (
          <div key={header} className="mx-auto w-fit">
            <DevOf />
          </div>
        );
      case 'Scan':
        return (
          <div key={header} className="mx-auto w-fit">
            <ScanStatus scanKey={value?.status} amount={value?.amount} />
          </div>
        );
      case 'Activity':
        return (
          <span key={header} className="text-right text-13 font-medium text-white">
            {value ?? '--'}
          </span>
        );
      case 'Detail':
        return (
          <span key={header} className="text-right text-13 font-medium text-white">
            {value ?? '--'}
          </span>
        );
      case 'Status':
        return (
          <span key={header} className="text-right text-13 font-medium text-white">
            {value ?? '--'}
          </span>
        );
      case 'Upload':
        return (
          <span key={header} className="text-right text-13 font-medium text-white">
            {value ?? '--'}
          </span>
        );
      case 'Proof':
        return <Proof key={header} />;
      case 'Dex':
        return (
          <div key={header} className="mx-auto w-fit">
            <Tooltip
              classNames={{
                content: 'border border-gray-800 bg-black text-gray-500 py-1 px-2 text-[13px] font-medium',
              }}
              content={dex?.name}
            >
              <img src={dex?.avatar} alt="" className="h-[30px] w-[30px] rounded-full" />
            </Tooltip>
          </div>
        );
      case 'Action':
        return (
          <Action
            key={header}
            onShowChart={handleRedirect(row.original.chainId, row.original.address, row.original.baseToken)}
          />
        );
    }

    return (
      <DataTableCell key={header} overflow="hidden" textAlign={meta?.isNumeric ? 'right' : 'left'}>
        <span className="text-right text-13 font-medium text-white">{numberFormat(String(value))}</span>
      </DataTableCell>
    );
  };

  return (
    <Box as="tr" className="group h-[45px] bg-transparent hover:bg-r-orange-5">
      {row.getVisibleCells().map((cell) => {
        const meta: any = cell.column.columnDef;
        const header = cell.column.columnDef.header as string;

        return (
          <td
            className={twMerge(
              'h-full border-b border-r border-b-[#131722] border-r-[#131722] px-1 text-center text-[13px] font-medium text-white last:border-r-[0px]',
              meta?.textAlign,
            )}
            key={header ?? cell.id}
          >
            {renderCell(cell)}
          </td>
        );
      })}
    </Box>
  );
}

export default PairItem;
