import { Tooltip } from '@nextui-org/react';
import React from 'react';
import { twMerge } from 'tailwind-merge';

const BADGE_STATUS: any = {
  M: {
    name: 'ama',
    symbol: 'M',
    color: 'text-[#8551A7]',
    colorBg: 'bg-[#432953]',
  },
  D: {
    name: 'doxx',
    symbol: 'D',
    color: 'text-[#EDA803]',
    colorBg: 'bg-[#775403]',
  },
  S: {
    name: 'safu contract',
    symbol: 'S',
    color: 'text-[#FFB2BB]',
    colorBg: 'bg-[#804347]',
  },
  K: {
    name: 'kyc',
    symbol: 'K',
    color: 'text-[#17C467]',
    colorBg: 'bg-[#0B6234]',
  },
  A: {
    name: 'audited',
    symbol: 'A',
    color: 'text-[#00B8D8]',
    colorBg: 'bg-[#015C6C]',
  },
};

interface Props {
  badgeKey: string;
}

const BadgeStatus: React.FC<Props> = ({ badgeKey = 'M' }) => {
  const badgeStatus = BADGE_STATUS[badgeKey];
  return (
    <Tooltip
      classNames={{
        content: 'border border-gray-800 bg-black text-gray-500 py-1 px-2 text-[13px] font-medium',
      }}
      content={badgeStatus.name}
    >
      <div
        className={twMerge(
          'flex h-5 w-3 items-center justify-center rounded-[4px] text-[10px] font-medium',
          `${badgeStatus.color} ${badgeStatus.colorBg}`,
        )}
      >
        {badgeStatus.symbol}
      </div>
    </Tooltip>
  );
};

export default BadgeStatus;
