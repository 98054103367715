import numeralType from 'configs/numeral';
import { BigNumber, BigNumberish } from 'ethers';
import { formatUnits, parseUnits } from 'ethers/lib/utils';
import numeral from 'numeral';
import Decimal from 'decimal.js';
import { PublicKey } from '@solana/web3.js';

numeral.localeData().abbreviations = {
  thousand: 'K',
  million: 'M',
  billion: 'B',
  trillion: 'T',
};

export function abbreviateNumber(num: number): string {
  if (num >= 10000000000000) {
    return (num / 1000000000000).toFixed(0).replace(/\.0$/, '') + 'T';
  }
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B';
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
  }
  return num.toFixed(0);
}

export function withoutZeroEnd(amount: string): string {
  return amount.endsWith('.0') ? amount.replace('.0', '') : amount;
}

export function isExponentNumber(str: string) {
  return /e-/.test(str);
}

function eToNumber(num: any) {
  let sign = '';
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions, eqeqeq
  (num += '').charAt(0) == '-' && ((num = num.substring(1)), (sign = '-'));
  let arr = num.split(/[e]/gi);
  if (arr.length < 2) return sign + num;
  let dot = (0.1).toLocaleString().substr(1, 1),
    n = arr[0],
    exp = +arr[1],
    w = (n = n.replace(/^0+/, '')).replace(dot, ''),
    pos = n.split(dot)[1] ? n.indexOf(dot) + exp : w.length + exp,
    L = pos - w.length,
    s = '' + BigInt(w);
  w = exp >= 0 ? (L >= 0 ? s + '0'.repeat(L) : r()) : pos <= 0 ? '0' + dot + '0'.repeat(Math.abs(pos)) + s : r();
  L = w.split(dot);
  // @ts-ignore
  // eslint-disable-next-line eqeqeq
  if ((L[0] == 0 && L[1] == 0) || (+w == 0 && +s == 0)) w = 0; //** added 9/10/2021
  return sign + w;
  function r() {
    return w.replace(new RegExp(`^(.{${pos}})(.)`), `$1${dot}$2`);
  }
}

export function withoutExponent(no: number) {
  if (no.toString().indexOf('e-') >= 0 || no.toString().indexOf('e+') >= 0) {
    return eToNumber(no);
  }

  return no.toString();
}

export function numberFormat(amount: string, padding?: number): string {
  if (isExponentNumber(amount)) {
    const [num, exp] = amount.split('e-');
    return Number(amount).toFixed(Number(num.toString().length) + Number(exp) - 2); // -2 = because 0. = has lenght = 2
  }
  const [first, ...rest] = amount.split('.');
  return amount.split('.').length > 1
    ? `${(first ?? '').replace(/(.)(?=(\d{3})+$)/g, '$1,')}.${rest.join().substring(0, padding || 4)}`
    : amount.replace(/(.)(?=(\d{3})+$)/g, '$1,');
}

export function formatWithDecimals(amount: BigNumberish, decimals: number | string, padding?: number): string {
  try {
    const formated = formatUnits(amount, decimals);
    const withoutZero = withoutZeroEnd(formated);
    return numberFormat(withoutZero, padding);
  } catch (e) {
    return amount?.toString();
  }
}

export function toNumber(amount: BigNumberish, decimals: number): number {
  try {
    const multiplier = parseUnits('1', 18);
    const amountBN = parseUnits(amount.toString(), 0);
    const result = amountBN.mul(multiplier).div(parseUnits('1', decimals));
    const withoutZero = withoutZeroEnd(formatUnits(result, 18));
    return Number(withoutZero);
  } catch (e) {
    return 0;
  }
}

export function formatLocaleString(num: number, maximumFractionDigits?: number) {
  return num.toLocaleString('en-US', {
    maximumFractionDigits,
  });
}

export function toNumberString(amount: BigNumberish, decimals: number): string {
  try {
    const multiplier = parseUnits('1', 18);
    const amountBN = parseUnits(amount.toString(), 0);
    const result = amountBN.mul(multiplier).div(parseUnits('1', decimals));
    const withoutZero = withoutZeroEnd(formatUnits(result, 18));
    return withoutZero;
  } catch (e) {
    return '0';
  }
}

export function safeParseUnits(amount: string, decimals?: BigNumberish) {
  try {
    return parseUnits(amount, decimals);
  } catch (e) {
    return null;
  }
}

export function formatNumeral(
  price: string | number = 0,
  format = numeralType.DEFAULT,
  prefix?: string,
  suffix?: string,
): string {
  price = Number(price).toString();
  if (price.includes('e+')) {
    const [value, pow] = price.split('e+');
    return `${prefix || ''}${Math.round(Number(value) * 100) / 100}e+${pow}${suffix || ''}`;
  }
  return numeral(price).format(format);
}

export function formatAddress(address: string): string {
  return `${address.substring(0, 6)}...${address.substring(address.length - 4)}`;
}

/**
 * x.xx common number
 * 0.xxxxx small number
 * 0.0_p_xxxx very small number
 */

export const formatPriceValue = (numberStr: string, decimalPlaces = 9, fractionDigits = 2) => {
  numberStr = Number(numberStr).toString();
  // Case 0.0000000022578596055137 => 2.2578596055137e-9
  // Very small number.
  // Return 0.0_7_22579.
  if (!numberStr.includes('e-') && (numberStr.includes('e+') || numberStr.includes('e'))) {
    if (numberStr.includes('e+')) {
      const [value, pow] = numberStr.split('e+');
      const result = new Decimal(value).mul(new Decimal('10').pow(new Decimal(pow))).toFixed();
      return result;
    }

    if (numberStr.includes('e')) {
      const [value, pow] = numberStr.split('e');
      const result = new Decimal(value).mul(new Decimal('10').pow(new Decimal(pow))).toFixed();
      return result;
    }
  }

  if (numberStr.includes('e-')) {
    const [value, pow] = numberStr.split('e-');
    const powDecimal = new Decimal(pow).sub('1');

    const valueDecimal = new Decimal(Number(value).toFixed(3).replace('.', ''));

    // 0.0000000022578596055137 => 0.0_7_22579
    const result = `0.0_${powDecimal}_${valueDecimal}`;
    return result;
  }

  // 1.12139812739 => 1.12;
  if (+numberStr > 1) {
    return Number(new Decimal(numberStr).toFixed(fractionDigits)).toString();
  }

  // 0.01283470198 => 0.01283
  return Number(new Decimal(numberStr).toFixed(decimalPlaces)).toString();
};

export const calcPriceScale = (price: string) => {
  if (!price.includes('_')) {
    if (+price >= 1) return 10000;

    const [, num] = price.split('.');

    return '1' + new Array(Math.min(num.length, 8)).fill('0').join('');
  }

  const [, second, third] = price.split('_');

  const flag = Number(second) + Math.min(4, third.length);

  return '1' + new Array(Math.min(flag, 16)).fill('0').join('');
};

export function subAddress(address: string | undefined, length = 3) {
  if (!address) return '';
  const head: string = address.slice(0, length);
  const tail: string = address.slice(-length);
  return `${head}...${tail}`;
}

export const separateNumber = (number: string | number, separate = ',') => {
  try {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separate);
  } catch (error) {
    return number;
  }
};

const months: any = {
  Jan: '01',
  Feb: '02',
  Mar: '03',
  Apr: '04',
  May: '05',
  Jun: '06',
  Jul: '07',
  Aug: '08',
  Sep: '09',
  Oct: '10',
  Nov: '11',
  Dec: '12',
};

export function formatUTCDate(utcString: string, isShowYear: boolean = true) {
  const [, date, month, year, hhmmss] = utcString.split(' ');
  if (isShowYear) return `${year}-${months[month]}-${date} ${hhmmss}`;
  return `${months[month]}-${date} ${hhmmss}`;
}

export function formatTimeUTCDate(utcString: string, isShowYear: boolean = true) {
  try {
    const [, date, month, year, hhmmss] = utcString.split(' ');
    if (isShowYear) return `${year}-${months[month]}-${date} ${hhmmss}`;
    return hhmmss;
  } catch (error) {
    return '';
  }
}

export function formatTxDate(utcString: string) {
  const [, date, month, year] = utcString.split(' ');
  return `${year}-${months[month]}-${date}`;
}

export function formatTxTime(utcString: string) {
  const [, , , , hhmmss] = utcString.split(' ');
  return hhmmss;
}

export function fromRawBalance(input: number | string, decimals = 18) {
  try {
    const _decimals = new Decimal(10).pow(new Decimal(decimals));
    return new Decimal(String(input)).dividedBy(_decimals);
  } catch (e) {
    // TODO: consider return this line
    return new Decimal('0');
  }
}
export function decimalValue(value: number | string = '0') {
  try {
    return new Decimal(value);
  } catch (err) {
    return new Decimal('0');
  }
}

export function formatLockPercent(amount: BigNumberish, decimals: number, _totalSupply?: BigNumber) {
  try {
    if (!_totalSupply || _totalSupply.eq(0)) return '-';
    const formatted = fromRawBalance(amount?.toString() || '0', decimals);
    const totalSupply = fromRawBalance(_totalSupply.toString(), decimals);

    const percent = new Decimal(formatted).div(totalSupply).mul(100);
    if (percent.lessThan('1')) return '< 1';
    return percent.greaterThan(1) ? percent.toFixed(2) : +percent.toFixed(10);
  } catch (e) {
    return '-';
  }
}

export function formatNumberStartWithDot(num: string) {
  try {
    return num?.startsWith('.') ? '0' + num : num;
  } catch (error) {}

  return num;
}

export function formatSolanaAddress(address: PublicKey | string) {
  const base58 = typeof address === 'string' ? address : address.toBase58();
  return formatAddress(base58);
}

export function isNumberString(str: string) {
  const num = parseFloat(str);
  return !isNaN(num) && num.toString() === str;
}

export function formatValueWithDecimals(value: string, decimals: number) {
  const regex = new RegExp(`^(\\d*\\.?\\d{0,${decimals}}).*`);
  const result = value.match(regex);
  return result ? result[1] : '';
}
