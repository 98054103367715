import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { supportedLocale } from 'configs/language';

import translationEN from 'locales/en/translation.json';
import translationCN from 'locales/zh/translation.json';

import HeaderEN from 'locales/en/header.json';
import HeaderCN from 'locales/zh/header.json';

import HomePageEN from 'locales/en/home.json';
import HomePageCN from 'locales/zh/home.json';

import TradePageEN from 'locales/en/trade.json';
import TradePageCN from 'locales/zh/trade.json';

import MultiChartPageEN from 'locales/en/multi-chart.json';
import MultiChartPageCN from 'locales/zh/multi-chart.json';

import NotFoundEN from 'locales/en/not-found.json';
import NotFoundCN from 'locales/zh/not-found.json';

import VerifyTokenEN from 'locales/en/verify-token.json';
import VerifyTokenCN from 'locales/zh/verify-token.json';

import GoPlusEN from 'locales/en/goplus.json';
import GoPlusCN from 'locales/zh/goplus.json';

import TradeMakerInfoEN from 'locales/en/trade-maker-info.json';
import TradeMakerInfoCN from 'locales/zh/trade-maker-info.json';

import HoneypotIsEN from 'locales/en/honeypot.json';
import HoneypotIsCN from 'locales/zh/honeypot.json';

import constants from 'configs/constants';

const resources = {
  en: {
    translation: translationEN,
    header: HeaderEN,
    home: HomePageEN,
    trade: TradePageEN,
    multiChart: MultiChartPageEN,
    notFound: NotFoundEN,
    verifyToken: VerifyTokenEN,
    goplus: GoPlusEN,
    tradeMakerInfo: TradeMakerInfoEN,
    honeypot: HoneypotIsEN,
  },
  zh: {
    translation: translationCN,
    header: HeaderCN,
    home: HomePageCN,
    trade: TradePageCN,
    multiChart: MultiChartPageCN,
    notFound: NotFoundCN,
    verifyToken: VerifyTokenCN,
    goplus: GoPlusCN,
    tradeMakerInfo: TradeMakerInfoCN,
    honeypot: HoneypotIsCN,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem(constants.LANGUAGE_STORAGE_KEY) || supportedLocale.en.lng,
  fallbackLng: supportedLocale.en.lng,
  interpolation: {
    escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
  },
});

export default i18n;
