import Router from 'Router';
import useResources from 'shared/hooks/useResource';
import MainLayout from 'shared/layouts/MainLayout';
import ReactGA from 'react-ga4';
import constants from 'configs/constants';
import { useEffect } from 'react';

ReactGA.initialize([
  {
    trackingId: constants.GOOGLE_ANALYST_TRACKING_ID || '',
  },
]);

function App() {
  useResources(true);

  useEffect(() => {
    const el = document.getElementById('alpha-splash-screen-loading');
    el?.remove();
  }, []);

  return (
    <MainLayout>
      <Router />
    </MainLayout>
  );
}

export default App;
