import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { generatePersistConfig } from 'utils/misc';
import { persistReducer } from 'redux-persist';
import constants from 'configs/constants';

interface State {
  height: number;
}

const transactionTableHeight = createSlice({
  name: 'transactionTableHeight',
  initialState: { height: constants.HISTORY_TRANSACTION_DEFAULT_HEIGHT },
  reducers: {
    updateHeightTransactionTableHeight: (state: State, action: PayloadAction<number>) => {
      state.height = action.payload;
    },
  },
});

export const { updateHeightTransactionTableHeight } = transactionTableHeight.actions;
const persistConfig = generatePersistConfig('transactionTableHeight', []);
export default persistReducer<State>(persistConfig, transactionTableHeight.reducer);
