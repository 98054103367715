import client from 'configs/axios';
import ApiResponse from 'types/ApiResponse';
import Chain from 'types/Chain';
import Dex from 'types/Dex';

export async function getResource() {
  return (
    await client.get<
      ApiResponse<{
        chains: Chain[];
        dexes: Dex[];
        whiteList: { address: string; chainId: string }[];
      }>
    >('/resource')
  ).data;
}
