const path = {
  HOME: '/',
  NEW_PAIR: '/new-pairs',
  ADS: '/ads',
  NEW_PAIR_WITH_CHAIN: '/new-pairs/:chain',
  SOCIAL_NETWORK_UPDATES: '/last-updated',
  SOCIAL_NETWORK_UPDATES_WITH_CHAIN: '/last-updated/:chain',
  NEW_PAIR_WITH_DEX: '/new-pairs/:chain/:dex',
  TRADE: '/:chain/:address',
  CHAIN: '/:chain',
  CHAIN_WITH_DEX: '/:chain/dex/:dex',
  MULTIPLE_CHARTS: '/multi-charts',
  SWAP: '/swap',
  WALLET: '/wallet',
  WALLET_WITH_CHAIN: '/wallet/:chain',
  VERIFY_OWNER: '/:chain/:address/verify-owner',
  ORDERS: '/orders',
  SOCIAL_INFO: '/social-info',
  BANNER_ADS: '/banner-ads',
  BOOST_ADS: '/boost-ads',
  TOP_TRENDING: '/top-trending',
  TOP_PRESALE: '/top-presale',
  TOP_RESEARCH: '/top-research',
  ADMIN_ADS: '/admin-ads',
  DEV_AREA: '/dev-area',
};

export default path;
