import { HomeScreen } from 'types/Pair';
import { ChainIds } from './chain';
import queryKey from './queryKey';
import { Option } from 'chakra-multiselect';

const TypeSale: Option[] = [
  {
    label: 'Fairlaunch Public',
    value: 'fairlaunch_public',
  },
  {
    label: 'Fairlaunch with Whitelist',
    value: 'fairlaunch_with_whitelist',
  },
  {
    label: 'Whitelist Only',
    value: 'whitelist_only',
  },
  {
    label: 'Commission',
    value: 'commission',
  },
  {
    label: 'Subscription',
    value: 'subscription',
  },
  {
    label: 'Social Sale',
    value: 'social_sale',
  },
  {
    label: 'Degen pump fun',
    value: 'degen_pump_fun',
  },
];

const TagsOptionSocial: Option[] = [
  {
    label: 'Audit',
    value: 'audit',
  },
  {
    label: 'Kyc',
    value: 'kyc',
  },
  {
    label: 'Safu',
    value: 'safu',
  },
  {
    label: 'Doxx',
    value: 'doxx',
  },
];

type SocialOption = Option & {
  isRequired?: boolean;
};

const SocialType: SocialOption[] = [
  {
    label: 'Website',
    isRequired: true,
    value: 'website',
  },
  {
    label: 'Twitter',
    isRequired: true,
    value: 'twitter',
  },
  {
    label: 'Telegram',
    isRequired: true,
    value: 'telegram',
  },
  {
    label: 'Discord',
    isRequired: false,
    value: 'discord',
  },
  {
    label: 'Reddit',
    isRequired: false,
    value: 'reddit',
  },
  {
    label: 'Medium',
    isRequired: false,
    value: 'medium',
  },
  {
    label: 'Facebook',
    isRequired: false,
    value: 'facebook',
  },
  {
    label: 'Instagram',
    isRequired: false,
    value: 'instagram',
  },
  {
    label: 'Tiktok',
    isRequired: false,
    value: 'tiktok',
  },
  {
    label: 'Youtube',
    isRequired: false,
    value: 'youtube',
  },
  {
    label: 'Whitepaper',
    isRequired: false,
    value: 'whitepaper',
  },
  {
    label: 'Substack',
    isRequired: false,
    value: 'substack',
  },
  // {
  //   label: 'Other',
  //   value: 'other',
  // },
];
const OtherOption: Option = {
  label: 'Other',
  value: 'other',
};
const TimeOptions = Array.from({ length: 24 }).map((_, i) => {
  const hour = i;
  const minute = 0;
  return {
    value: hour * 60 * 60 * 1000 + minute * 60 * 1000,
    label: hour.toString().padStart(2, '0') + ':' + minute.toString().padStart(2, '0') + ' UTC',
  };
});
const BadgeType: Option[] = [
  {
    label: 'Kyc',
    value: 'KYC',
  },
  {
    label: 'Audit',
    value: 'AUDIT',
  },
  {
    label: 'Doxx',
    value: 'DOXXED',
  },
  {
    label: 'Safu',
    value: 'SAFU',
  },
  {
    label: 'Scam',
    value: 'SCAM',
  },
];

const TimeType: Option[] = [
  {
    label: '24h',
    value: '24h',
  },
  {
    label: '48h',
    value: '48h',
  },
  {
    label: '72h',
    value: '72h',
  },
];
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif'];
const Chains: (Option & { icon?: string })[] = [
  {
    value: 'eth',
    label: 'Eth',
  },
  {
    value: 'bsc',
    label: 'Bsc',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png',
  },
  {
    value: 'sol',
    label: 'Sol',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5426.png',
  },
  {
    value: 'ton',
    label: 'Ton',
  },
  {
    value: 'base',
    label: 'Base',
  },
];

const poolCurrencies = {
  [ChainIds.Ethereum]: [
    {
      symbol: 'USDT',
      address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
      decimals: 6,
    },
    {
      symbol: 'USDC',
      address: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
      decimals: 6,
    },
  ],
  [ChainIds.Bsc]: [
    {
      symbol: 'BUSD',
      address: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      decimals: 18,
    },
    {
      symbol: 'USDT',
      address: '0x55d398326f99059fF775485246999027B3197955',
      decimals: 18,
    },
    {
      symbol: 'USDC',
      address: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
      decimals: 18,
    },
  ],
  [ChainIds.BscTest]: [
    {
      symbol: 'BUSD',
      address: '0x8301F2213c0eeD49a7E28Ae4c3e91722919B8B47',
      decimals: 18,
    },
    {
      symbol: 'USDC',
      address: '0xFA3C228a243A00C3DE9D77528016a900c42Ec041',
      decimals: 9,
    },
    {
      symbol: 'USDT',
      address: '0x1119C3a415b62127CB8Ef653a77a717839414310',
      decimals: 6,
    },
  ],
  [ChainIds.Polygon]: [
    {
      symbol: 'USDT',
      address: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
      decimals: 6,
    },
    {
      symbol: 'USDC',
      address: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
      decimals: 6,
    },
  ],
  [ChainIds.Arbitrum]: [
    {
      symbol: 'USDT',
      address: '0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9',
      decimals: 6,
    },
    {
      symbol: 'USDC',
      address: '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
      decimals: 6,
    },
  ],
  [ChainIds.Pulse]: [
    {
      symbol: 'USDT',
      address: '0x0Cb6F5a34ad42ec934882A05265A7d5F59b51A2f',
      decimals: 6,
    },
    {
      symbol: 'USDC',
      address: '0x15D38573d2feeb82e7ad5187aB8c1D52810B1f07',
      decimals: 6,
    },
  ],
  [ChainIds.Bitrock]: [] as { symbol: string; address: string; decimals: number }[],
  [ChainIds.Shibarium]: [] as { symbol: string; address: string; decimals: number }[],
  [ChainIds.Cybria]: [
    {
      symbol: 'USDT',
      address: '0x84c7f870137b48c00F601FD13667651338007599',
      decimals: 18,
    },
  ],
  // [ChainIds.Solana]: [
  //   {
  //     symbol: 'USDT',
  //     address: 'Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB',
  //     decimals: 6,
  //   },
  //   {
  //     symbol: 'USDC',
  //     address: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
  //     decimals: 6,
  //   },
  // ],
  [ChainIds.Base]: [
    {
      symbol: 'USDC',
      address: '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913',
      decimals: 6,
    },
  ],
};
// FLAG: ADD_NEW_CHAIN
const DEX_POOL_CURRENCY: { [key in ChainIds]: { NATIVE: string; STABLE_COINS: string[] } } = {
  [ChainIds.Ethereum]: {
    NATIVE: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2', // WETH
    STABLE_COINS: (poolCurrencies?.[ChainIds.Ethereum] || []).map((item) => item.address),
  },
  [ChainIds.Bsc]: {
    NATIVE: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', // WBNB
    STABLE_COINS: (poolCurrencies?.[ChainIds.Bsc] || []).map((item) => item.address),
  },
  [ChainIds.Arbitrum]: {
    NATIVE: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1', // WETH
    STABLE_COINS: (poolCurrencies?.[ChainIds.Arbitrum] || []).map((item) => item.address),
  },
  [ChainIds.Polygon]: {
    NATIVE: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270', // WMATIC
    STABLE_COINS: (poolCurrencies?.[ChainIds.Polygon] || []).map((item) => item.address),
  },
  [ChainIds.Pulse]: {
    NATIVE: '0xA1077a294dDE1B09bB078844df40758a5D0f9a27', // WPLS
    STABLE_COINS: (poolCurrencies?.[ChainIds.Pulse] || []).map((item) => item.address),
  },
  [ChainIds.Bitrock]: {
    NATIVE: '0x413f0E3A440abA7A15137F4278121450416882d5', // WBROCK
    STABLE_COINS: (poolCurrencies?.[ChainIds.Bitrock] || []).map((item) => item.address),
  },
  [ChainIds.Shibarium]: {
    NATIVE: '0x213c25900f365F1BE338Df478CD82beF7Fd43F85', // WBONE
    STABLE_COINS: (poolCurrencies?.[ChainIds.Shibarium] || []).map((item) => item.address),
  },
  [ChainIds.Cybria]: {
    NATIVE: '0x8e3607E6BF321a9D87273CA9021ec51cf1C55fFE', // WCYBA
    STABLE_COINS: (poolCurrencies?.[ChainIds.Cybria] || []).map((item) => item.address),
  },
  // [ChainIds.Solana]: {
  //   NATIVE: 'So11111111111111111111111111111111111111112', // SOL
  //   STABLE_COINS: (poolCurrencies?.[ChainIds.Solana] || []).map((item) => item.address),
  // },
  [ChainIds.Base]: {
    NATIVE: '0x4200000000000000000000000000000000000006', // WETH
    STABLE_COINS: (poolCurrencies?.[ChainIds.Base] || []).map((item) => item.address),
  },
  /**@deprecated */
  [ChainIds.BscTest]: {
    NATIVE: '',
    STABLE_COINS: [],
  },
  /**@deprecated */
  [ChainIds.Avalanche]: {
    NATIVE: '',
    STABLE_COINS: [],
  },
  /**@deprecated */
  [ChainIds.FantomOpera]: {
    NATIVE: '',
    STABLE_COINS: [],
  },
};

const constants = {
  DEFAULT_CHAIN_ID: +process.env.REACT_APP_DEFAULT_CHAIN_ID!,
  LANGUAGE_STORAGE_KEY: '___lng_key',
  TOAST_DURATION: 3000, // 3s
  FILE_URL: process.env.REACT_APP_BASE_FILE_URL,
  API_BASE_URL: process.env.REACT_APP_API_BASE_API_URL,
  IO_API_BASE_URL: process.env.REACT_APP_IO_API_BASE_URL,
  API_SECRET: process.env.REACT_APP_API_SECRET,
  GOOGLE_ANALYST_TRACKING_ID: process.env.REACT_APP_GOOGLE_ANALYST_TRACKING_ID,
  ENV: process.env.NODE_ENV,
  BURN_ADDRESSES: [
    '0x000000000000000000000000000000000000dEaD',
    '0x0000000000000000000000000000000000000000',
    '0x0000000000000000000000000000000000000001',
    '0x0000000000000000000000000000000000000002',
    '0x0000000000000000000000000000000000000003',
    '0x0000000000000000000000000000000000000004',
    '0x0000000000000000000000000000000000000005',
    '0x0000000000000000000000000000000000000006',
    '0x0000000000000000000000000000000000000007',
    '0x0000000000000000000000000000000000000008',
    '0x0000000000000000000000000000000000000009',
  ],
  CACHE_TIME: {
    [queryKey.RESOURCE]: 1 * 3600 * 1000,
    [queryKey.PAIR_ACTIVITY]: 1 * 3600 * 1000,
    [queryKey.TOKEN_INFO]: 1 * 3600 * 1000,
    // One minute
    [queryKey.WALLET_BALANCE]: 10 * 1000,
    [queryKey.WALLET_TRX]: 5 * 60 * 1000,
    [queryKey.PAIR_HISTORY_TRX]: 0,
    [queryKey.TOP_BUY_SELL]: 5 * 60 * 1000,
    [queryKey.WRAPPED_WALLET]: 30 * 1000,
    [queryKey.MULTI_CHART]: 1 * 3600 * 1000,
    [queryKey.USER_INFO]: 60 * 1000,
    [queryKey.GO_PLUS_DATA_RUG_CHECK]: 5 * 60 * 1000,
    [queryKey.HONEYPOT_DATA_RUG_CHECK]: 5 * 60 * 1000,
    [queryKey.SCAN_AND_HIDE_HONEYPOT]: 60 * 1000,
    [queryKey.CURRENCIES]: 1 * 3600 * 1000,
  },
  LOCAL_STORAGE_KEY: {
    HISTORY_DATA: 'historyData',
    TAB_DATA: 'tabData',
    FAVORITE_DATA: 'favoriteData',
    HIDDEN_TOKEN: 'hiddenToken',
    INTERVAL: 'interval',
    SHOW_TRADE: 'showTrade',
    swapSettings: {
      isAutoGasPrice: 'alpha@isAutoGasPrice',
      gasPrice: 'alpha@gasPrice',
      isAutoSlippage: 'alpha@isAutoSlippage',
      slippage: 'alpha@slippage',
      position: 'alpha@swapFormPosition',
      isAntiMevBot: 'alpha@isAntiMevBot',
      pendingTransactions: 'alpha@pendingTransactions',
      isAutoDeadline: 'alpha@isAutoDeadline',
      deadline: 'alpha@deadline',
    },
    solanaSwapSettings: {
      slippage: 'alpha@solanaSlippage',
    },
    TIMEZONE: 'alpha@timezone',
    STUDY_TEMPLATE: 'alpha@studyTemplate',
    CHART_TYPE: 'alpha@chartType',
    SHOW_PENDING_TX: 'alpha@pendingTx',
    HIDE_BOT: 'alpha@hideBot',
  },
  MOBILE_WIDTH: 678,
  MOBILE_TRADE_PAGE_WIDTH: 480,
  TABLET_BREAKPOINT: 992,
  GRID_TEMPLATE_COLUMNS: {
    [HomeScreen.NEW_PAIR]: {
      DEFAULT: '20% 7% 9% 5% 5% 5% 7% 7% 7% 7% 7% 7% 7%',
      MEDIUM: '25% 10% 10% 6% 6% 6% 7% 7% 7% 7% 7% 7% 7%',
    },
    [HomeScreen.SOCIAL_NETWORK_UPDATES]: {
      DEFAULT: '20% 9% 13% 7% 7% 7% 7% 13% 9% 8% 8% 8%',
      MEDIUM: '25% 10% 13% 7% 7% 7% 7% 13% 9% 8% 8% 8%',
    },
  },
  /**
   * > 20% -> show warning for user.
   * An unlocked wallet is holding {{UNLOCKED_AMOUNT_WARNING_PERCENT}}% of the {{pair}}, we highly recommend it should be locked with pinklock, it's secure and free (click to view LP holders)?
   */
  UNLOCKED_AMOUNT_WARNING_PERCENT: 20,
  HIDDEN_AMOUNT_WARNING: 5000000,
  MAX_LENGTH_INPUT: 500,
  REFRESH_INTERVAL: {
    [queryKey.NEW_PAIRS]: 10 * 1000,
  },
  HISTORY_TRANSACTION_DEFAULT_HEIGHT: 700,
  HISTORY_TRANSACTION_EXPAND_HEIGHT: 1200,
  ESTIMATE_DESKTOP_HEADER_HEIGHT: 200,
  // Also set at BE
  MAX_MULTI_CHART_PAIR_FILTER: 10,
  WALLET_CONNECT_PROJECT_ID: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID,
  DEX_POOL_CURRENCY,
  TIME_SHOW_ATH_FROM_LISTING: 60 * 60 * 24 * 365,
  MAXIMUM_SHOW_ATH: 10000,
  /**List key cache already saved in local storage */
  LIST_KEY_CACHE_PAIR_DETAIL: 'alpha@LIST_KEY_CACHE_PAIR_DETAIL',
  MINIMUM_SHOW_TX_VOLUME_BAR: 25,
  TIME_DO_NOT_WARNING_HONEYPOT: 60 * 60 * 24, // 24 hours
  DEFAULT_TOKEN_NAME: '_Unknown',
  GOAT_SWAP_FACTORY: 'GoatAFSqACoMvJqvgW7aFACFkkArv69ezTJhS8xdEr5H',
  HYPE_LAP_ADS: {
    production: {
      apiUrl: 'https://api.hypelab.com',
      propertySlug: 'da4d12c6b7',
      banner: {
        '320x50': 'd31ae505bb',
        '300x250': '817b82cbb8',
        '728x90': '826b3ad886',
      },
    },
    staging: {
      apiUrl: 'https://api.hypelab-staging.com',
      // propertySlug: '3e77be8471',
      // banner: {
      //   '320x50': 'd9b9536acf',
      //   '300x250': 'c554c72e6f',
      //   '728x90': '9bf271e82b',
      // },
    },
  },
  SUPPORTED_FORMATS,
  TimeType,
  TagsOptionSocial,
  SocialType,
  BadgeType,
  TypeSale,
  Chains,
  OtherOption,
  TimeOptions,
};

export default constants;
