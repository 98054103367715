import { Box, Flex } from '@chakra-ui/react';
import { memo, PropsWithChildren } from 'react';
import ErrorBoundary from 'shared/components/ErrorBoundary';

import ModalStacks from 'shared/components/ModalStacks';
import SidebarMenu from 'shared/components/SidebarMenu';
import Trending from 'shared/components/Trending';
// import useHypeLabAds from 'shared/hooks/useHypeLabAds';
import PageHeader from './PageHeader';

function MainLayout({ children }: PropsWithChildren<{}>) {
  // useHypeLabAds();
  return (
    <>
      {/* <PageHeader /> */}
      {/* <Trending /> */}
      <div className="block md:flex bg-[#131722]">
        <SidebarMenu />
        <Box as="main" flex={1} overflowX="hidden" w="100%" maxH="100vh" overflowY="auto">
          <ErrorBoundary>{children}</ErrorBoundary>
        </Box>
      </div>
      <ModalStacks />
    </>
  );
}

export default memo(MainLayout);
