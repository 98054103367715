import { ChakraProps } from '@chakra-ui/react';
import { ChevronRightCircleIcon } from './Icons/Arrow';

interface Props {
  nextTitle?: string;
  page: number;
  pageLimit: number;
  totalItems: number;
  previousTitle?: string;
  isNextDisabled?: boolean;
  isPreviousDisabled?: boolean;
  buttonGroupProps?: ChakraProps;
  onNext?: () => void;
  onPrevious?: () => void;
}

function SimplePagination({
  nextTitle = 'Next',
  previousTitle = 'Previous',
  page,
  pageLimit,
  totalItems,
  isNextDisabled,
  isPreviousDisabled,
  onNext,
  onPrevious,
}: Props) {
  const startIndex = Math.min(Math.max(Math.max(page - 1, 0) * pageLimit, 1), totalItems);
  const endIndex = Math.min(startIndex + pageLimit - 1, totalItems);

  return (
    <>
      <button type="button" className="rotate-180" onClick={onPrevious} disabled={isPreviousDisabled}>
        <ChevronRightCircleIcon colorBg={isPreviousDisabled ? '#333333' : 'white'} />
      </button>
      <div className="w-fit rounded-[6px] bg-[#1A1A1A] px-3 text-12 font-medium text-white">{`${startIndex}-${endIndex}`}</div>
      <button type="button" onClick={onNext} disabled={isNextDisabled}>
        <ChevronRightCircleIcon colorBg={isNextDisabled ? '#333333' : 'white'} />
      </button>
    </>
  );
}

export default SimplePagination;
