import { useMemo } from 'react';
import Dex from 'types/Dex';
import useResources from './useResource';

function useDexes(chainId?: number): Dex[] | undefined {
  const { data: resources } = useResources();

  return useMemo(() => {
    if (!chainId) return resources?.data.dexes;
    return resources?.data?.dexes?.filter((dex: Dex) => Number(dex.chainId) === chainId);
  }, [resources, chainId]);
}

export default useDexes;
