import { useTranslation as useTranslationHook } from 'react-i18next';

export default function useTranslation(header?: string | string[]) {
  const { t: tt } = useTranslationHook(header);

  function t(key: string, option?: any): any {
    return tt(key, option) ?? '';
  }
  return {
    t,
  };
}
