import { ChevronRightCircleIcon } from 'shared/components/Icons/Arrow';
import TokenIcon from 'shared/components/TokenIcon';
import { Tooltip } from '@nextui-org/react';
import { twMerge } from 'tailwind-merge';

interface IProps {
  devOf?: string;
}

const DevOf = ({ devOf }: IProps) => {
  return (
    <Tooltip
      classNames={{
        content: 'bg-black p-[6px] rounded-lg border border-[#D7D6DF]',
      }}
      content={
        <div className="flex flex-wrap items-center gap-[6px]">
          <div className="flex w-fit flex-1 items-center gap-1 rounded-[6px] bg-[#131722] p-1">
            <TokenIcon
              iconSrc="https://s2.coinmarketcap.com/static/img/coins/64x64/29870.png"
              iconClassName="w-4 h-4"
            />
            <span className="text-12 font-medium text-[#D7D6DF]">100X</span>
          </div>
          <div
            className={twMerge(
              'flex w-fit flex-1 items-center gap-1 rounded-[6px] bg-[#131722] p-1',
              true && 'bg-g-150',
            )}
          >
            <TokenIcon
              iconSrc="https://s2.coinmarketcap.com/static/img/coins/64x64/29870.png"
              iconClassName="w-4 h-4"
            />
            <span className="text-12 font-medium text-[#D7D6DF]">100X</span>
          </div>
        </div>
      }
    >
      <div className="flex items-center">
        <div className="flex h-5 min-w-[50px] items-center justify-center rounded-[4px] bg-r-orange-4 p-1 text-[13px] font-medium text-[#F7931E] hover:bg-g-150">
          {devOf ?? 10}x
        </div>
        <div>
          <ChevronRightCircleIcon color="transparent" colorBg="#F7931E" />
        </div>
      </div>
    </Tooltip>
  );
};

export default DevOf;
