export const PlayOutlineIcon: React.FC<{
  size?: number;
  color?: string;
}> = ({ size = 21, color = '#17C467' }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Asset 5" clipPath="url(#clip0_77_10186)">
        <g id="Layer 2">
          <g id="Layer 1">
            <g id="Group" opacity="0.5">
              <path
                id="Vector"
                d="M17.11 0.930023H4.97002C2.65042 0.930023 0.77002 2.81043 0.77002 5.13002V15.67C0.77002 17.9896 2.65042 19.87 4.97002 19.87H17.11C19.4296 19.87 21.31 17.9896 21.31 15.67V5.13002C21.31 2.81043 19.4296 0.930023 17.11 0.930023Z"
                stroke={color}
                strokeMiterlimit="10"
              />
              <path
                id="Vector_2"
                d="M8.62002 5.50004C8.44677 5.3948 8.24934 5.33597 8.04675 5.32921C7.84416 5.32245 7.64325 5.36799 7.46337 5.46145C7.2835 5.5549 7.13073 5.69311 7.0198 5.86276C6.90887 6.03242 6.8435 6.22778 6.83002 6.43004V14.68C6.8471 14.8801 6.9154 15.0724 7.02834 15.2385C7.14128 15.4045 7.29506 15.5387 7.47488 15.6281C7.65469 15.7175 7.85449 15.7592 8.05505 15.749C8.25561 15.7388 8.45017 15.6772 8.62002 15.57L15.95 11.43C16.1164 11.3471 16.2564 11.2194 16.3543 11.0613C16.4521 10.9032 16.504 10.721 16.504 10.535C16.504 10.3491 16.4521 10.1669 16.3543 10.0088C16.2564 9.85066 16.1164 9.72297 15.95 9.64004L8.62002 5.50004Z"
                fill={color}
              />
            </g>
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_77_10186">
          <rect width="21.54" height="19.94" fill="white" transform="translate(0.27002 0.430023)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const ChainOutlineIcon: React.FC<{
  size?: number;
  color?: string;
}> = ({ size = 21, color = '#17C467' }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Asset 6" clipPath="url(#clip0_77_10169)">
        <g id="Layer 2">
          <g id="Layer 1">
            <g id="Group" opacity="0.5">
              <path
                id="Vector"
                d="M4.67001 7.57001L4.80001 7.43001L6.58001 5.66001C6.67965 5.56458 6.81204 5.51091 6.95001 5.51001C7.01255 5.49584 7.07746 5.49584 7.14001 5.51001C7.20429 5.53329 7.26243 5.5709 7.31001 5.62001L9.44001 7.79001L10.5 6.73001L8.38001 4.60001C8.1905 4.40737 7.96209 4.25736 7.71001 4.16001C7.46887 4.06096 7.21069 4.01 6.95001 4.01001C6.68932 4.01 6.43114 4.06096 6.19001 4.16001C5.93547 4.25254 5.70605 4.4032 5.52001 4.60001L3.61001 6.51001C3.42845 6.70443 3.28587 6.93187 3.19001 7.18001C3.00307 7.66943 3.00307 8.21059 3.19001 8.70001C3.29482 8.95058 3.4477 9.1782 3.64001 9.37001L8.03001 13.76C8.22182 13.9523 8.44943 14.1052 8.70001 14.21C8.94114 14.3091 9.19932 14.36 9.46001 14.36C9.72069 14.36 9.97887 14.3091 10.22 14.21C10.4731 14.11 10.7017 13.9565 10.89 13.76L11.44 13.21L12.51 12.15L12.8 11.85C12.9965 11.6617 13.15 11.4331 13.25 11.18C13.3416 10.937 13.389 10.6797 13.39 10.42C13.368 10.157 13.3004 9.89979 13.19 9.66001C13.0874 9.41118 12.9342 9.18641 12.74 9.00001L11.54 7.79001L10.5 8.85001L11.71 10.06C11.7591 10.1076 11.7967 10.1657 11.82 10.23C11.8342 10.2926 11.8342 10.3575 11.82 10.42C11.8191 10.558 11.7654 10.6904 11.67 10.79L11.37 11.09L10.31 12.15L9.76001 12.7C9.66036 12.7954 9.52797 12.8491 9.39001 12.85C9.32746 12.8642 9.26255 12.8642 9.20001 12.85C9.13572 12.8267 9.07758 12.7891 9.03001 12.74L4.67001 8.31001C4.62071 8.25871 4.58317 8.19728 4.56001 8.13001C4.54513 8.06755 4.54513 8.00247 4.56001 7.94001C4.54596 7.87408 4.54596 7.80594 4.56001 7.74001C4.58584 7.67702 4.62313 7.61938 4.67001 7.57001Z"
                fill={color}
              />
              <path
                id="Vector_2"
                d="M17.24 12.36L15.33 14.27C15.2959 14.3331 15.248 14.3878 15.19 14.43C15.1283 14.4561 15.062 14.4696 14.995 14.4696C14.928 14.4696 14.8617 14.4561 14.8 14.43C14.7352 14.4029 14.6772 14.362 14.63 14.31L12.51 12.19L11.44 13.25L13.57 15.38C13.9513 15.7559 14.4646 15.9676 15 15.97C15.2605 15.9685 15.5184 15.9176 15.76 15.82C16.0082 15.7152 16.2352 15.5661 16.43 15.38L18.31 13.43C18.4961 13.2352 18.6452 13.0082 18.75 12.76C18.8476 12.5184 18.8985 12.2606 18.9 12C18.8976 11.4646 18.6858 10.9513 18.31 10.57L13.91 6.17002C13.5287 5.79415 13.0154 5.58239 12.48 5.58002C12.2194 5.58151 11.9615 5.63241 11.72 5.73002C11.4718 5.83482 11.2448 5.98391 11.05 6.17002L10.5 6.73002L9.43998 7.79002L9.18998 8.08002C9.00387 8.2748 8.85478 8.50184 8.74998 8.75002C8.65236 8.99158 8.60146 9.24948 8.59998 9.51002C8.60235 10.0454 8.81411 10.5587 9.18998 10.94L10.4 12.15L11.46 11.09L10.19 9.88002C10.138 9.83281 10.0971 9.77478 10.07 9.71002C10.0438 9.64833 10.0304 9.58201 10.0304 9.51502C10.0304 9.44802 10.0438 9.38171 10.07 9.32002C10.0971 9.25526 10.138 9.19723 10.19 9.15002L10.32 9.01002L10.48 8.85002L11.55 7.79002L12.1 7.24002C12.1472 7.18807 12.2052 7.14711 12.27 7.12002C12.3317 7.09389 12.398 7.08042 12.465 7.08042C12.532 7.08042 12.5983 7.09389 12.66 7.12002C12.7247 7.14711 12.7828 7.18807 12.83 7.24002L17.22 11.63C17.2719 11.6772 17.3129 11.7353 17.34 11.8C17.3661 11.8617 17.3796 11.928 17.3796 11.995C17.3796 12.062 17.3661 12.1283 17.34 12.19C17.3188 12.253 17.2847 12.3109 17.24 12.36Z"
                fill={color}
              />
              <path
                id="Vector_3"
                d="M17.01 0.930023H4.91C2.57936 0.930023 0.690002 2.81938 0.690002 5.15002V15.65C0.690002 17.9807 2.57936 19.87 4.91 19.87H17.01C19.3406 19.87 21.23 17.9807 21.23 15.65V5.15002C21.23 2.81938 19.3406 0.930023 17.01 0.930023Z"
                stroke={color}
                strokeMiterlimit="10"
              />
            </g>
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_77_10169">
          <rect width="21.54" height="19.94" fill="white" transform="translate(0.190002 0.430023)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const PhotoOutlineIcon: React.FC<{
  size?: number;
  color?: string;
}> = ({ size = 21, color = '#17C467' }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Asset 7" clipPath="url(#clip0_77_10179)">
        <g id="Layer 2">
          <g id="Layer 1">
            <g id="Group" opacity="0.5">
              <path
                id="Vector"
                d="M17.46 0.930023H5.53998C3.15963 0.930023 1.22998 2.85968 1.22998 5.24002V15.56C1.22998 17.9404 3.15963 19.87 5.53998 19.87H17.46C19.8403 19.87 21.77 17.9404 21.77 15.56V5.24002C21.77 2.85968 19.8403 0.930023 17.46 0.930023Z"
                stroke={color}
                strokeMiterlimit="10"
              />
              <path
                id="Vector_2"
                d="M6.67999 8.51001C7.92263 8.51001 8.92999 7.50265 8.92999 6.26001C8.92999 5.01737 7.92263 4.01001 6.67999 4.01001C5.43735 4.01001 4.42999 5.01737 4.42999 6.26001C4.42999 7.50265 5.43735 8.51001 6.67999 8.51001Z"
                fill={color}
              />
              <path
                id="Vector_3"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.07 8.96001L9.20997 12.87L7.87997 11.8C7.47902 11.5036 6.99357 11.3437 6.49497 11.3437C5.99637 11.3437 5.51092 11.5036 5.10997 11.8L1.46997 14.73V15.94C1.47553 16.7179 1.71242 17.4766 2.15049 18.1194C2.58856 18.7623 3.208 19.2603 3.92997 19.55H18.93C19.6358 19.2625 20.2425 18.7755 20.6759 18.1485C21.1093 17.5216 21.3505 16.7819 21.37 16.02V12.56L16.88 8.96001C16.4754 8.6542 15.9821 8.48874 15.475 8.48874C14.9678 8.48874 14.4745 8.6542 14.07 8.96001Z"
                fill={color}
              />
            </g>
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_77_10179">
          <rect width="21.54" height="19.94" fill="white" transform="translate(0.72998 0.430023)" />
        </clipPath>
      </defs>
    </svg>
  );
};
