import { ChainFullNames, ChainIds, ChainNames } from 'configs/chain';
import IcEthereum from 'assets/icons/chain/ethereum.png';
import IcEthereumScan from 'assets/icons/etherscan.svg';
import IcBscScan from 'assets/icons/bscscan.svg';
import IcCybriaScan from 'assets/icons/cybriascan.svg';
import IcSolscan from 'assets/icons/solscan.png';
import IcAvalance from 'assets/icons/chain/avalanche.png';
import IcBsc from 'assets/icons/chain/bsc.png';
import IcFantom from 'assets/icons/chain/fantom.png';
import IcArbitrum from 'assets/icons/chain/arbitrum.svg';
import IcPolygon from 'assets/icons/chain/polygon.svg';
import IcPulse from 'assets/icons/chain/pulse.png';
import IcBitrock from 'assets/icons/chain/brock.png';
import IcShibarium from 'assets/icons/chain/shibarium.png';
import IcCybria from 'assets/icons/chain/cybria.png';
import IcSolana from 'assets/icons/chain/solana.svg';
import IcBase from 'assets/icons/chain/base.svg';
import TrustedToken from 'types/TrustedToken';
import amm from 'configs/amm';
import {
  arbitrum,
  avalandche,
  bsc,
  bscTest,
  ethereum,
  fantomOpera,
  polygon,
  pulse,
  ethereumFlashbot,
  supportedChains,
  bscFlashbot,
  polygonFlashbot,
  bitrock,
  shibarium,
  cybria,
  base,
} from 'configs/networks';
import { ethers, providers } from 'ethers';
import memoize from 'lodash/memoize';

// ADD_NEW_CHAIN
export function getChainIdFromChainName(chain: string): ChainIds | undefined {
  return {
    [ChainNames.Ethereum]: ChainIds.Ethereum,
    [ChainNames.Bsc]: ChainIds.Bsc,
    [ChainNames.BscTest]: ChainIds.BscTest,
    [ChainNames.Avalanche]: ChainIds.Avalanche,
    [ChainNames.FantomOpera]: ChainIds.FantomOpera,
    [ChainNames.Arbitrum]: ChainIds.Arbitrum,
    [ChainNames.Polygon]: ChainIds.Polygon,
    [ChainNames.Pulse]: ChainIds.Pulse,
    [ChainNames.Bitrock]: ChainIds.Bitrock,
    [ChainNames.Shibarium]: ChainIds.Shibarium,
    [ChainNames.Cybria]: ChainIds.Cybria,
    // [ChainNames.Solana]: ChainIds.Solana,
    [ChainNames.Base]: ChainIds.Base,
  }[chain];
}

export function getChainNameFromChainId(chainId: number): ChainNames | undefined {
  return {
    [ChainIds.Ethereum]: ChainNames.Ethereum,
    [ChainIds.Bsc]: ChainNames.Bsc,
    [ChainIds.BscTest]: ChainNames.BscTest,
    [ChainIds.Avalanche]: ChainNames.Avalanche,
    [ChainIds.FantomOpera]: ChainNames.FantomOpera,
    [ChainIds.Arbitrum]: ChainNames.Arbitrum,
    [ChainIds.Polygon]: ChainNames.Polygon,
    [ChainIds.Pulse]: ChainNames.Pulse,
    [ChainIds.Bitrock]: ChainNames.Bitrock,
    [ChainIds.Shibarium]: ChainNames.Shibarium,
    [ChainIds.Cybria]: ChainNames.Cybria,
    // [ChainIds.Solana]: ChainNames.Solana,
    [ChainIds.Base]: ChainNames.Base,
  }[chainId];
}

export function getFullChainNameFromChainName(chainName: string): ChainFullNames | undefined {
  return {
    [ChainNames.Ethereum]: ChainFullNames.Ethereum,
    [ChainNames.Bsc]: ChainFullNames.Bsc,
    [ChainNames.BscTest]: ChainFullNames.BscTest,
    [ChainNames.Avalanche]: ChainFullNames.Avalanche,
    [ChainNames.FantomOpera]: ChainFullNames.FantomOpera,
    [ChainNames.Arbitrum]: ChainFullNames.Arbitrum,
    [ChainNames.Polygon]: ChainFullNames.Polygon,
    [ChainNames.Pulse]: ChainFullNames.Pulse,
    [ChainNames.Bitrock]: ChainFullNames.Bitrock,
    [ChainNames.Shibarium]: ChainFullNames.Shibarium,
    [ChainNames.Cybria]: ChainFullNames.Cybria,
    // [ChainNames.Solana]: ChainFullNames.Solana,
    [ChainNames.Base]: ChainFullNames.Base,
  }[chainName];
}

export function getChainIcon(chainId: number): string | undefined {
  return {
    [ChainIds.Ethereum]: IcEthereum,
    [ChainIds.Bsc]: IcBsc,
    [ChainIds.BscTest]: IcBsc,
    [ChainIds.Avalanche]: IcAvalance,
    [ChainIds.FantomOpera]: IcFantom,
    [ChainIds.Arbitrum]: IcArbitrum,
    [ChainIds.Polygon]: IcPolygon,
    [ChainIds.Pulse]: IcPulse,
    [ChainIds.Bitrock]: IcBitrock,
    [ChainIds.Shibarium]: IcShibarium,
    [ChainIds.Cybria]: IcCybria,
    // [ChainIds.Solana]: IcSolana,
    [ChainIds.Base]: IcBase,
  }[chainId];
}

export function getScanIcon(chainId: number): string | undefined {
  return {
    [ChainIds.Ethereum]: IcEthereumScan,
    [ChainIds.Bsc]: IcBscScan,
    [ChainIds.BscTest]: IcBscScan,
    [ChainIds.Arbitrum]: IcArbitrum,
    [ChainIds.Polygon]: IcPolygon,
    [ChainIds.Pulse]: IcPulse,
    [ChainIds.Bitrock]: IcBitrock,
    [ChainIds.Shibarium]: IcShibarium,
    [ChainIds.Cybria]: IcCybriaScan,
    // // [ChainIds.Solana]: IcSolscan,
    [ChainIds.Base]: IcEthereumScan,
  }[chainId];
}

export function getChain(chainId: number) {
  return {
    [ChainIds.Avalanche]: avalandche,
    [ChainIds.Bsc]: bsc,
    [ChainIds.BscTest]: bscTest,
    [ChainIds.Ethereum]: ethereum,
    [ChainIds.FantomOpera]: fantomOpera,
    [ChainIds.Arbitrum]: arbitrum,
    [ChainIds.Polygon]: polygon,
    [ChainIds.Pulse]: pulse,
    [ChainIds.Bitrock]: bitrock,
    [ChainIds.Shibarium]: shibarium,
    [ChainIds.Cybria]: cybria,
    // [ChainIds.Solana]: solana,
    [ChainIds.Base]: base,
  }[chainId];
}

export function getChainFromChainName(chainName?: string) {
  return {
    [ChainNames.Avalanche]: avalandche,
    [ChainNames.Bsc]: bsc,
    [ChainNames.BscTest]: bscTest,
    [ChainNames.Ethereum]: ethereum,
    [ChainNames.FantomOpera]: fantomOpera,
    [ChainNames.Arbitrum]: arbitrum,
    [ChainNames.Polygon]: polygon,
    [ChainNames.Pulse]: pulse,
    [ChainNames.Bitrock]: bitrock,
    [ChainNames.Shibarium]: shibarium,
    [ChainNames.Cybria]: cybria,
    // [ChainNames.Solana]: solana,
    [ChainNames.Base]: base,
  }[chainName || ''];
}

export function getTrustedTokenFromChainId(chainId?: number): TrustedToken[] | undefined {
  return amm.trustedTokens[chainId as ChainIds];
}

export function getWrappedTokenAddress(chainId?: number): string | undefined {
  return amm.wrappedTokens[chainId as ChainIds].address;
}

export function isAddressEqual(a: string, b?: string) {
  try {
    if (!b) return false;
    return a.toLowerCase() === b.toLowerCase();
  } catch (error) {
    return false;
  }
}

export function isWrappedToken(address: string, chainId: number) {
  return (amm.trustedTokens[chainId as ChainIds] || [])
    .filter((item) => !!item.isWrappedToken && !!item.address)
    .some((item) => isAddressEqual(item.address as string, address));
}

export function isNativeToken(symbol: string, chainId: number) {
  return (amm.trustedTokens[chainId as ChainIds] || [])
    .filter((item) => !!item.isNativeToken)
    .some((item) => item.symbol === symbol);
}

export function getAdapter(adapterName: string) {
  return amm.adapters[adapterName];
}

export function getConfiguredTokenIcon(address?: string, chainId?: number) {
  if (!address || address === ethers.constants.AddressZero) {
    return (amm.trustedTokens[chainId as ChainIds] || []).find((item) => item.isNativeToken)?.icon;
  }
  return (amm.trustedTokens[chainId as ChainIds] || []).find((item) => isAddressEqual(item.address || '', address))
    ?.icon;
}

export const getNetworkRpc = (chainId?: number, isPriority?: boolean) => {
  const chain = supportedChains.find((item) => String(item.id) === String(chainId)) || bsc;

  const priority = isPriority ? chain?.rpcUrls?.priority || '' : '';

  const rpcUrl = priority || chain?.rpcUrls?.infura || chain?.rpcUrls?.default || chain?.rpcUrls?.public || {};
  return rpcUrl?.http?.[0] || '';
};
export const getPublicNetwork = (chainId: ChainIds) => {
  const chain = supportedChains.find((item) => String(item.id) === String(chainId)) || bsc;
  const rpc = chain?.rpcUrls?.public || chain?.rpcUrls?.default || '';
  return rpc.http?.[0] || '';
};

interface RequestNetworkParams {
  currency: {
    name: string;
    symbol: string;
    decimals: number;
  };
  name: string;
  chainId: number;
  rpcUrls: string[];
  blockExplorerUrls: string[];
}

export async function addNetwork(options: RequestNetworkParams, externalProvider?: any) {
  const provider = externalProvider || window.ethereum;
  // null if success, otherwise error
  await provider.request({
    method: 'wallet_addEthereumChain',
    params: [
      {
        chainId: `0x${options.chainId.toString(16)}`,
        chainName: options.name,
        nativeCurrency: {
          name: options.currency.name,
          symbol: options.currency.symbol,
          decimals: options.currency.decimals,
        },
        rpcUrls: options.rpcUrls,
        blockExplorerUrls: options.blockExplorerUrls,
      },
    ],
  });
}

export async function requestNetwork(options: RequestNetworkParams, externalProvider?: any) {
  const provider = externalProvider || window.ethereum;
  try {
    await provider.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: `0x${options.chainId.toString(16)}` }],
    });
  } catch (switchError: any) {
    if (switchError?.code === 4902) {
      try {
        await addNetwork(options, provider);
        return true;
      } catch (e) {
        return false;
      }
    } else {
      return false;
    }
  }
}

export function getFlashbotConfig(chainId: ChainIds) {
  return {
    [ChainIds.Ethereum]: ethereumFlashbot,
    [ChainIds.Bsc]: bscFlashbot,
    [ChainIds.Polygon]: polygonFlashbot,
    [ChainIds.Arbitrum]: null,
    [ChainIds.Avalanche]: null,
    [ChainIds.BscTest]: null,
    [ChainIds.FantomOpera]: null,
    [ChainIds.Pulse]: null,
    [ChainIds.Bitrock]: null,
    [ChainIds.Shibarium]: null,
    [ChainIds.Cybria]: null,
    // [ChainIds.Solana]: null,
    [ChainIds.Base]: null,
  }[chainId];
}

export async function requestFlashbotsRpc(chainId: ChainIds) {
  const flashbotConfig = getFlashbotConfig(chainId);
  if (!flashbotConfig) throw new Error('RPC unavailable');
  return await addNetwork({
    chainId: flashbotConfig.id,
    name: flashbotConfig.name,
    currency: flashbotConfig.nativeCurrency!,
    rpcUrls: [flashbotConfig.rpcUrls.default.http[0]],
    blockExplorerUrls: [flashbotConfig.blockExplorers?.default.url!],
  });
}

export const HTTP_PROVIDERS: Record<ChainIds, providers.JsonRpcProvider> = {
  [ChainIds.Arbitrum]: new providers.JsonRpcProvider(arbitrum.rpcUrls.default.http[0]),
  [ChainIds.Avalanche]: new providers.JsonRpcProvider(avalandche.rpcUrls.default.http[0]),
  [ChainIds.Bitrock]: new providers.JsonRpcProvider(bitrock.rpcUrls.default.http[0]),
  [ChainIds.Bsc]: new providers.JsonRpcProvider(bsc.rpcUrls.default.http[0]),
  [ChainIds.BscTest]: new providers.JsonRpcProvider(bscTest.rpcUrls.default.http[0]),
  [ChainIds.Cybria]: new providers.JsonRpcProvider(cybria.rpcUrls.default.http[0]),
  [ChainIds.Ethereum]: new providers.JsonRpcProvider(ethereum.rpcUrls.default.http[0]),
  [ChainIds.FantomOpera]: new providers.JsonRpcProvider(fantomOpera.rpcUrls.default.http[0]),
  [ChainIds.Polygon]: new providers.JsonRpcProvider(polygon.rpcUrls.default.http[0]),
  [ChainIds.Pulse]: new providers.JsonRpcProvider(pulse.rpcUrls.default.http[0]),
  [ChainIds.Shibarium]: new providers.JsonRpcProvider(shibarium.rpcUrls.default.http[0]),
  // [ChainIds.Solana]: new providers.JsonRpcProvider(solana.rpcUrls.default.http[0]),
  [ChainIds.Base]: new providers.JsonRpcProvider(base.rpcUrls.default.http[0]),
};

export function getProvider(chainId: ChainIds) {
  return HTTP_PROVIDERS[chainId];
}

export function getWeb3Provider(walletProvider?: ethers.providers.ExternalProvider) {
  if (!walletProvider) return null;
  return new ethers.providers.Web3Provider(walletProvider);
}

export const CHAIN_IDS = supportedChains.map((c) => c.id);
export const isChainSupported = memoize((chainId: number) => CHAIN_IDS.includes(chainId));
