import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { generatePersistConfig } from 'utils/misc';
import { persistReducer } from 'redux-persist';

interface State {
  address: string;
  baseToken: string;
}

const addressSelected = createSlice({
  name: 'addressSelected',
  initialState: {
    address: '',
    baseToken: '',
  },
  reducers: {
    addAddress: (state: State, action: PayloadAction<State>) => {
      return action.payload;
    },
    clearAddress: () => {
      return { address: '', baseToken: '' };
    },
  },
});

export const { addAddress, clearAddress } = addressSelected.actions;
const persistConfig = generatePersistConfig('addressSelected', []);
export default persistReducer<State>(persistConfig, addressSelected.reducer);
