import { Box, Divider, Flex, Icon, Image, Link, Spacer, Text, useColorModeValue } from '@chakra-ui/react';
import { sidebarMenu } from 'configs/menus';
import { themeConstants } from 'configs/theme';
import { NavLink } from 'react-router-dom';
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from 'react-icons/ai';
import useSidebar from 'shared/hooks/useSidebar';
import Card from './Card';
import LanguageSwitcher from './LanguageSwitcher';
import ThemeSwitcher from './ThemeSwitcher';
import useTranslation from 'shared/hooks/useTranslation';
import socialLinks from 'configs/social-links';
import ExternalLink from './ExternalLink';
import { isImagePath } from 'utils/misc';
import { memo, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import alphaDexLogo from 'assets/images/alpha-dex-logo.png';
import alphaDexTextLogo from 'assets/images/alpha-dex-text-logo.png';
import {
  AdvertiseIcon,
  ChartColumnIcon,
  ChartMultiIcon,
  ChartPieIcon,
  DiamondIcon,
  DocsIcon,
  GuideIcon,
  LightningCircleIcon,
  StarIcon,
  ToolsIcon,
} from './Icons/Menu';
import chevronRightIcon from 'assets/icons/chevron-right.svg';
import { ContactIcon } from './Icons/Contact';
import { EarthIcon, TelegramIcon, XIcon } from './Icons/Socials';

const SIDEBAR = [
  {
    name: 'Alpha Board',
    icon: (color: string) => <DiamondIcon color={color} />,
    key: 'alpha-board',
  },
  {
    name: 'Alpha Swap',
    icon: (color: string) => <LightningCircleIcon color={color} />,
    key: 'alpha-swap',
  },
  {
    name: 'Portfolio',
    icon: (color: string) => <ChartPieIcon color={color} />,
    key: 'portfolio',
  },
  {
    name: 'Watchlist',
    icon: (color: string) => <StarIcon color={color} />,
    key: 'watchlist',
  },
  {
    name: 'Chart',
    icon: (color: string) => <ChartColumnIcon color={color} />,
    key: 'chart',
  },
  {
    name: 'Multi Chart',
    icon: (color: string) => <ChartMultiIcon color={color} />,
    key: 'multi-chart',
  },
  {
    name: 'Advertise',
    icon: (color: string) => <AdvertiseIcon color={color} />,
    key: 'advertise',
  },
  {
    name: 'Tools',
    icon: (color: string) => <ToolsIcon color={color} />,
    key: 'tools',
  },
  {
    name: 'Guid To Use',
    icon: (color: string) => <GuideIcon color={color} />,
    key: 'guide-to-use',
  },
  {
    name: 'Docs',
    icon: (color: string) => <DocsIcon color={color} />,
    key: 'docs',
  },
];

export const SOCIALS = [
  {
    key: 'website',
    icon: (color?: string) => <EarthIcon color={color} />,
    link: '',
  },
  {
    key: 'telegram',
    icon: (color?: string) => <TelegramIcon color={color} />,
    link: '',
  },
  {
    key: 'telegram',
    icon: (color?: string) => <TelegramIcon color={color} />,
    link: '',
  },
  {
    key: 'x',
    icon: (color?: string) => <XIcon color={color} />,
    link: '',
  },
];

function SidebarMenu() {
  const { collapsed, toggle } = useSidebar();
  const { t } = useTranslation('header');
  const bgActive = useColorModeValue('blackAlpha.200', 'blackAlpha.700');
  const bgHover = useColorModeValue('blackAlpha.50', 'blackAlpha.500');
  const [hoverItemKey, setHoverItemKey] = useState<string>('');

  const renderHeaderMobile = () => {
    return (
      <div className="sticky top-0 z-30 flex items-center justify-between border-b border-b-[#333] bg-[#131722] p-3 md:hidden">
        <img src={alphaDexTextLogo} alt="" className="h-[34px] w-[182px] object-contain" />

        <div onClick={toggle}>
          <div className="p-[6px] flex items-center gap-[6px] flex-shrink-0 w-9 h-[33px] justify-center">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="16" height="2" rx="1" fill="white" />
              <rect y="5" width="16" height="2" rx="1" fill="white" />
              <rect y="10" width="16" height="2" rx="1" fill="white" />
            </svg>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {renderHeaderMobile()}
      <div
        className={twMerge(
          'fixed md:static -left-[60px] top-0 z-30 flex min-h-dvh w-[60px] flex-col justify-between bg-[#0D0D14] pb-6 pt-1 transition-all duration-200 ease-in-out md:left-0 md:max-w-[188px] max-h-full',
          !collapsed && 'w-full max-md:left-0 max-md:translate-x-0 md:w-[188px]',
        )}
      >
        <div className="space-y-3">
          <div className="max-md:mb-6 max-md:flex max-md:items-center max-md:justify-between">
            <div className="mr-1 flex cursor-pointer justify-end p-1 max-md:hidden" onClick={toggle}>
              <img
                src={chevronRightIcon}
                alt=""
                className={twMerge('transition-all duration-300 ease-linear', !collapsed && 'rotate-180')}
              />
            </div>
            {!collapsed ? (
              <img src={alphaDexTextLogo} alt="" className="h-[34px] w-[182px] object-contain" />
            ) : (
              <img src={alphaDexLogo} alt="" className="mx-auto h-[34px] w-[34px]" />
            )}
            <button
              className="mr-3 flex h-6 w-6 items-center justify-center rounded-full bg-gray-1000 text-14 text-white md:hidden"
              onClick={toggle}
            >
              X
            </button>
          </div>
          {SIDEBAR.map((item) => {
            const isActive = item.key === 'alpha-board';
            let color = isActive ? '#FF8A02' : '#808080';
            color = hoverItemKey === item.key ? '#FFFFFF' : color;
            return (
              <div
                key={item.key}
                className={twMerge(
                  'flex cursor-pointer items-center overflow-hidden px-3 transition-all duration-500 ease-linear hover:bg-g-30',
                  isActive && 'bg-g-25',
                )}
                onMouseEnter={() => setHoverItemKey(item.key)}
                onMouseLeave={() => setHoverItemKey('')}
              >
                <div className="flex-shrink-0">{item.icon(color)}</div>
                <span
                  className={twMerge(
                    'hidden text-[16px] font-semibold text-[#808080]',
                    hoverItemKey === item.key && 'text-white',
                    isActive && 'text-[#FF8A02]',
                    !collapsed && 'block whitespace-nowrap',
                  )}
                >
                  {item.name}
                </span>
              </div>
            );
          })}
        </div>
        <div
          className={twMerge(
            'items-center gap-3 px-3 max-md:hidden md:flex md:flex-col',
            collapsed && 'max-md:flex max-md:justify-center md:items-start',
          )}
        >
          {[
            ...SOCIALS,
            {
              key: 'contact',
              icon: (color?: string) => <ContactIcon color={color} />,
              link: '',
            },
          ].map((item) => (
            <Link key={item.key} href={item.link}>
              {item.icon()}
            </Link>
          ))}
        </div>
      </div>
    </>
  );
}

export default memo(SidebarMenu);
