import IcCn from 'assets/icons/flags/cn.png';
import IcUs from 'assets/icons/flags/us.png';

export const supportedLocale = {
  cn: {
    name: 'Chinese',
    lng: 'zh',
    flag: IcCn,
  },
  en: {
    name: 'English',
    lng: 'en',
    flag: IcUs,
  },
};

export const supportedLanguage = [supportedLocale.en, supportedLocale.cn];
