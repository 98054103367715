import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import constants from './constants';

const client = Axios.create({
  timeout: 3 * 60 * 1000,
  baseURL: constants.API_BASE_URL,
});

client.interceptors.request.use(
  (config) => {
    config.headers!.secret = constants.API_SECRET;
    return config;
  },
  (error: AxiosError) => Promise.reject(error),
);

client.interceptors.response.use(
  (response: AxiosResponse<any, any>) => response,
  (error: any) => Promise.reject(error),
);

export default client;
