import { shieldErrorIcon, shieldSuccessIcon, shieldWarningIcon } from 'assets/icons';
import { Tooltip } from '@nextui-org/react';
import React from 'react';
import { twMerge } from 'tailwind-merge';

const SCAN_STATUS: any = {
  success: {
    name: 'success',
    icon: shieldSuccessIcon,
    color: 'text-[#17C467]',
    colorBg: 'bg-[#17C46780]',
  },
  warning: {
    name: 'warning',
    icon: shieldWarningIcon,
    color: 'text-[#EDA803]',
    colorBg: 'bg-[#EDA80380]',
  },
  error: {
    name: 'error',
    icon: shieldErrorIcon,
    color: 'text-[#FF7E8D]',
    colorBg: 'bg-[#FF7E8D80]',
  },
};

interface Props {
  scanKey: string;
  amount: number;
}

const ScanStatus: React.FC<Props> = ({ scanKey = 'success', amount = 0 }) => {
  const scanStatus = SCAN_STATUS[scanKey];

  return (
    <>
      <Tooltip
        classNames={{
          content: 'bg-black p-[6px] rounded-lg border border-[#D7D6DF]',
        }}
        content={
          <div>
            <div className="flex items-center justify-between gap-[6px]">
              <span className="text-13 font-medium text-[#D7D6DF]">Renounce Ownership</span>
              <div className="flex items-center gap-1">
                <span className="text-13 font-medium text-[#D7D6DF]">No</span>
                <div className="h-[10px] w-[10px] rounded-full bg-noti-pink" />
              </div>
            </div>
            <div className="flex items-center justify-between gap-[6px]">
              <span className="text-13 font-medium text-[#D7D6DF]">Antiwhale Modifiable</span>
              <div className="flex items-center gap-1">
                <span className="text-13 font-medium text-[#D7D6DF]">Yes</span>
                <div className="h-[10px] w-[10px] rounded-full bg-noti-yellow" />
              </div>
            </div>
            <div className="flex items-center justify-between gap-[6px]">
              <span className="text-13 font-medium text-[#D7D6DF]">Other Risk</span>
              <div className="flex items-center gap-1">
                <span className="text-13 font-medium text-[#D7D6DF]">No</span>
                <div className="h-[10px] w-[10px] rounded-full bg-gray-100" />
              </div>
            </div>
          </div>
        }
      >
        <div
          className={twMerge(
            'flex h-5 w-fit min-w-[50px] items-center justify-center gap-[6px] rounded-[4px] p-1',
            scanStatus.colorBg,
          )}
        >
          <img src={scanStatus.icon} />
          <span className={twMerge('text-[13px] font-medium', scanStatus.color)}>{amount}</span>
        </div>
      </Tooltip>
    </>
  );
};

export default ScanStatus;
