import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { generatePersistConfig } from 'utils/misc';
import { persistReducer } from 'redux-persist';
import Trending from 'types/Trending';
import { getTrendings } from 'apis/pair';

interface State {
  trending: Trending[];
  isLoading: boolean;
  error?: any;
}

const initialState: State = {
  trending: [],
  isLoading: false,
};

export const fetchTrending = createAsyncThunk('trending/fetchTrending', async (chainId: string) => {
  const trending = await getTrendings({ chainId: chainId ? chainId : undefined });
  return trending.data.data;
});

const trending = createSlice({
  name: 'trending',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchTrending.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchTrending.fulfilled, (state, action) => {
        state.trending = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchTrending.rejected, (state, action) => {
        state.error = action.error;
      });
  },
});

const persistConfig = generatePersistConfig('trending', ['trending']);
export default persistReducer<State>(persistConfig, trending.reducer);
