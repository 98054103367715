import { createWeb3Modal, defaultConfig } from '@web3modal/ethers5/react';

import constants from 'configs/constants';
import {
  arbitrum,
  avalandche,
  base,
  bitrock,
  bsc,
  bscTest,
  ethereum,
  fantomOpera,
  polygon,
  pulse,
} from 'configs/networks';

// Set chains

const arbitrumChain = {
  chainId: arbitrum.id,
  name: arbitrum.name,
  currency: arbitrum.nativeCurrency.symbol,
  explorerUrl: arbitrum.rpcUrls.default.http[0],
  rpcUrl: arbitrum.rpcUrls.default.http[0],
};

const avalancheChain = {
  chainId: avalandche.id,
  name: avalandche.name,
  currency: avalandche.nativeCurrency.symbol,
  explorerUrl: avalandche.rpcUrls.default.http[0],
  rpcUrl: avalandche.rpcUrls.default.http[0],
};

const bitrockChain = {
  chainId: bitrock.id,
  name: bitrock.name,
  currency: bitrock.nativeCurrency.symbol,
  explorerUrl: bitrock.rpcUrls.default.http[0],
  rpcUrl: bitrock.rpcUrls.default.http[0],
};

const bscChain = {
  chainId: bsc.id,
  name: bsc.name,
  currency: bsc.nativeCurrency.symbol,
  explorerUrl: bsc.rpcUrls.default.http[0],
  rpcUrl: bsc.rpcUrls.default.http[0],
};
const bscChainTest = {
  chainId: bscTest.id,
  name: bscTest.name,
  currency: bscTest.nativeCurrency.symbol,
  explorerUrl: bscTest.rpcUrls.default.http[0],
  rpcUrl: bscTest.rpcUrls.default.http[0],
};
const fantomChain = {
  chainId: fantomOpera.id,
  name: fantomOpera.name,
  currency: fantomOpera.nativeCurrency.symbol,
  explorerUrl: fantomOpera.rpcUrls.default.http[0],
  rpcUrl: fantomOpera.rpcUrls.default.http[0],
};

const polygonChain = {
  chainId: polygon.id,
  name: polygon.name,
  currency: polygon.nativeCurrency.symbol,
  explorerUrl: polygon.rpcUrls.default.http[0],
  rpcUrl: polygon.rpcUrls.default.http[0],
};

const pulseChain = {
  chainId: pulse.id,
  name: pulse.name,
  currency: pulse.nativeCurrency.symbol,
  explorerUrl: pulse.rpcUrls.default.http[0],
  rpcUrl: pulse.rpcUrls.default.http[0],
};

const baseChain = {
  chainId: base.id,
  name: base.name,
  currency: base.nativeCurrency.symbol,
  explorerUrl: base.rpcUrls.default.http[0],
  rpcUrl: base.rpcUrls.default.http[0],
};

const mainnet = {
  chainId: ethereum.id,
  name: ethereum.name,
  currency: ethereum.nativeCurrency.symbol,
  explorerUrl: ethereum.rpcUrls.default.http[0],
  rpcUrl: ethereum.rpcUrls.default.http[0],
};

// Create modal
const metadata = {
  name: 'Alpha',
  description: 'Alpha Realtime Price Charts',
  url: 'https://www.alpha.com',
  icons: ['https://alphadex.site/logo.png'],
};

createWeb3Modal({
  ethersConfig: defaultConfig({
    metadata,
  }),
  chains: [
    bscChain,
    bscChainTest,
    mainnet,
    baseChain,
    arbitrumChain,
    avalancheChain,
    bitrockChain,
    fantomChain,
    polygonChain,
    pulseChain,
  ],
  projectId: constants.WALLET_CONNECT_PROJECT_ID,
});

export function Web3ModalProvider({ children }: { children: JSX.Element }) {
  return children;
}
