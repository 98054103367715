import paths from 'configs/path';
import TokenSocialNetworkUpdates from 'modules/home/pages/TokenSocialNetworkUpdates';

import { lazy, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import RouteSuspense from 'shared/components/RouteSuspense';

const IndexPage = lazy(() => import('modules/home/pages/HomePage'));
const TradePage = lazy(() => import(/* webpackChunkName: "trade-page" */ 'modules/trade/pages/TradePage'));
const MultiChartPage = lazy(() => import('modules/multi-charts/pages/MultiChartPage'));
const NewPairsPage = lazy(() => import('modules/home/pages/NewPairsPage'));
const VerifyTokenOwnership = lazy(() => import('modules/token/pages/VerifyTokenOwnership'));
const NotFoundPage = lazy(() => import('modules/error/pages/NotFoundPage'));
const WalletPage = lazy(() => import('modules/wallet/pages/WalletPage'));
const AdsPage = lazy(() => import('modules/ads/pages/Advertise'));
const OrdersPage = lazy(() => import('modules/orders/pages/OrdersPage'));
const BannerAdsPage = lazy(() => import('modules/banner-ads/pages/BannerAdsPage'));
const BoostAdsPage = lazy(() => import('modules/boost-ads/pages/BoostAdsPage'));
const SocialInfoPage = lazy(() => import('modules/social-info/pages/SocialInfoPage'));
const TopPresalePage = lazy(() => import('modules/top-presale/pages/TopPresalePage'));
const TopResearchPage = lazy(() => import('modules/top-research/page/TopResearchPage'));
const TopTrendingPage = lazy(() => import('modules/top-trending/pages/TopTrendingPage'));
const DevAreaPage = lazy(() => import('modules/dev-area/pages/DevAreaPage'));

function Router() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Routes>
      <Route
        path={paths.HOME}
        element={
          <RouteSuspense>
            <IndexPage />
          </RouteSuspense>
        }
      />
      <Route
        path={paths.ADS}
        element={
          <RouteSuspense>
            <AdsPage />
          </RouteSuspense>
        }
      />
      <Route
        path={paths.NEW_PAIR}
        element={
          <RouteSuspense>
            <NewPairsPage />
          </RouteSuspense>
        }
      />
      <Route
        path={paths.NEW_PAIR_WITH_CHAIN}
        element={
          <RouteSuspense>
            <NewPairsPage />
          </RouteSuspense>
        }
      />
      <Route
        path={paths.SOCIAL_NETWORK_UPDATES}
        element={
          <RouteSuspense>
            <TokenSocialNetworkUpdates />
          </RouteSuspense>
        }
      />
      <Route
        path={paths.SOCIAL_NETWORK_UPDATES_WITH_CHAIN}
        element={
          <RouteSuspense>
            <TokenSocialNetworkUpdates />
          </RouteSuspense>
        }
      />
      <Route
        path={paths.NEW_PAIR_WITH_DEX}
        element={
          <RouteSuspense>
            <NewPairsPage />
          </RouteSuspense>
        }
      />
      <Route
        path={paths.TRADE}
        element={
          <RouteSuspense>
            <TradePage />
          </RouteSuspense>
        }
      />
      {/* For dynamic paths, just place it after all static paths above */}
      <Route path={paths.CHAIN}>
        <Route
          index
          element={
            <RouteSuspense>
              <IndexPage />
            </RouteSuspense>
          }
        />
        <Route
          path={paths.CHAIN_WITH_DEX}
          element={
            <RouteSuspense>
              <IndexPage />
            </RouteSuspense>
          }
        />
      </Route>
      <Route path={paths.MULTIPLE_CHARTS}>
        <Route
          index
          element={
            <RouteSuspense>
              <MultiChartPage />
            </RouteSuspense>
          }
        />
      </Route>
      <Route path={paths.VERIFY_OWNER}>
        <Route
          index
          element={
            <RouteSuspense>
              <VerifyTokenOwnership />
            </RouteSuspense>
          }
        />
      </Route>
      <Route
        path={paths.WALLET}
        element={
          <RouteSuspense>
            <WalletPage />
          </RouteSuspense>
        }
      />
      <Route
        path={paths.WALLET_WITH_CHAIN}
        element={
          <RouteSuspense>
            <WalletPage />
          </RouteSuspense>
        }
      />
      <Route
        path={paths.ORDERS}
        element={
          <RouteSuspense>
            <OrdersPage />
          </RouteSuspense>
        }
      />
      <Route
        path={paths.TOP_RESEARCH}
        element={
          <RouteSuspense>
            <TopResearchPage />
          </RouteSuspense>
        }
      />
      <Route
        path={paths.TOP_PRESALE}
        element={
          <RouteSuspense>
            <TopPresalePage />
          </RouteSuspense>
        }
      />
      <Route
        path={paths.TOP_TRENDING}
        element={
          <RouteSuspense>
            <TopTrendingPage />
          </RouteSuspense>
        }
      />
      <Route
        path={paths.BOOST_ADS}
        element={
          <RouteSuspense>
            <BoostAdsPage />
          </RouteSuspense>
        }
      />
      <Route
        path={paths.BANNER_ADS}
        element={
          <RouteSuspense>
            <BannerAdsPage />
          </RouteSuspense>
        }
      />
      <Route
        path={paths.SOCIAL_INFO}
        element={
          <RouteSuspense>
            <SocialInfoPage />
          </RouteSuspense>
        }
      />
      <Route
        path={paths.DEV_AREA}
        element={
          <RouteSuspense>
            <DevAreaPage />
          </RouteSuspense>
        }
      />
      <Route
        path="*"
        element={
          <RouteSuspense>
            <NotFoundPage />
          </RouteSuspense>
        }
      />
    </Routes>
  );
}

export default Router;
