import { Interface } from '@ethersproject/abi';
import { PublicKey } from '@solana/web3.js';
import { getSolanaPriceUsdFromCoinGeeko } from 'apis/price';
import abis from 'configs/abis';
import { ChainIds, ChainSolanaIds } from 'configs/chain';
import constants from 'configs/constants';
import { PairLabel } from 'configs/enums';
import { bsc, supportedChains } from 'configs/networks';
import Decimal from 'decimal.js';
import { BigNumber } from 'ethers';
import chunk from 'lodash/chunk';
import compact from 'lodash/compact';
import first from 'lodash/first';
import uniq from 'lodash/uniq';
import { Resolution } from 'types';
import { IMakerTradeInfo, IPairDetail, IPairSearch } from 'types/Pair';
import TokenInfoWithBurnBalance from 'types/TokenInfoWithBurnBalance';
import Web3 from 'web3';
import { Log } from 'web3-core';
import { BlockTransactionString, TransactionReceipt } from 'web3-eth';
import { getNetworkRpc, isAddressEqual } from './chain';
import { calcTokenPriceFromSqrtPriceX96 } from './misc';
// import { PublicKey } from '@solana/web3.js';

export function getNextBarTime(timestamp: number, resolution: string) {
  if (resolution === Resolution.ONE_SECOND) return timestamp + 1;

  const seconds = getSecondFromResolution(resolution) || 60;

  return timestamp - (timestamp % seconds) + seconds;
}

export function getCurrentBarTime(timestamp: number, resolution: string) {
  if (resolution === Resolution.ONE_SECOND) return timestamp;

  const seconds = getSecondFromResolution(resolution) || 60;

  return timestamp - (timestamp % seconds);
}

export enum TIME_RESOLUTION {
  MINUTE = 'm',
  HOURS = 'h',
  DAY = 'd',
  WEEK = 'w',
  MONTH = 'mth',
}

export const getSecondFromResolution = (resolution: string) => {
  resolution = String(resolution).toLowerCase();

  const ONE_MINUTE_SECONDS = 60;
  if (Number(resolution)) {
    return Number(resolution) * ONE_MINUTE_SECONDS;
  }

  if (resolution.includes(TIME_RESOLUTION.MINUTE)) {
    const resolutionNumber = Number(resolution.replace(TIME_RESOLUTION.MINUTE, '')) || 0;
    return ONE_MINUTE_SECONDS * resolutionNumber;
  }

  if (resolution.includes(TIME_RESOLUTION.HOURS)) {
    const ONE_HOURS_SECONDS = 60 * 60;
    const resolutionNumber = Number(resolution.replace(TIME_RESOLUTION.HOURS, '')) || 0;
    return ONE_HOURS_SECONDS * resolutionNumber;
  }

  if (resolution.includes(TIME_RESOLUTION.DAY)) {
    const ONE_DAY_SECONDS = 60 * 60 * 24;
    const resolutionNumber = Number(resolution.replace(TIME_RESOLUTION.DAY, '')) || 0;
    return ONE_DAY_SECONDS * resolutionNumber;
  }

  if (resolution.includes(TIME_RESOLUTION.WEEK)) {
    const ONE_WEEK_SECONDS = 60 * 60 * 24 * 7;
    const resolutionNumber = Number(resolution.replace(TIME_RESOLUTION.WEEK, '')) || 0;
    return ONE_WEEK_SECONDS * resolutionNumber;
  }
  if (resolution.includes(TIME_RESOLUTION.MONTH)) {
    const ONE_MONTH_SECONDS = 60 * 60 * 24 * 30;
    const resolutionNumber = Number(resolution.replace(TIME_RESOLUTION.MONTH, '')) || 0;
    return ONE_MONTH_SECONDS * resolutionNumber;
  }

  return 0;
};

/* -------------------------------------------------------------------------- */
/*                     Listen realtime data from full node                    */
/* -------------------------------------------------------------------------- */

// Cached token price USD.

type TCachedTokenPrice = {
  [tokenAddress: string]: Decimal;
};

type TCachedWeb3 = {
  [chainId: string]: Web3;
};

interface IGetBatchTransaction {
  transactions: Array<TransactionReceipt | null>;
  errors: string[];
}

export interface IPairHistoryTransaction {
  _id: string;
  time: number;
  hash: string;
  pair: string;
  // amountIn: string;
  // amountOut: string;
  // tokenIn: string;
  // router: string;
  // tokenOut: string;
  token0: string;
  reserve0: string;
  reserve1: string;
  token1: string;
  logIndex: number;
  isBuy: boolean;
  basePrice: string;
  priceUsd: string;
  amountUsd: string;
  txFrom: string;
  txTo?: string;
  isContract?: boolean;
  isHidden?: boolean;
  baseAmount?: string;
  quoteAmount?: string;

  /**Calculate and assign into item */
  makerTradeInfo?: IMakerTradeInfo;
}

export interface IHistoryTransaction {
  _id: string;
  time: any;
  chainId: string;
  hash: string;
  from: string;
  // router: string;
  // amountIn: string;
  // amountOut: string;
  // tokenIn: string;
  // tokenOut: string;
  token0: string;
  token1: string;
  reserve0: string;
  reserve1: string;
  liquidity: string | null;
  pair: string;
  logIndex: number;
  isBuy: boolean;
  priceUsd: string | null;
  amountUsd: string;
  isHidden?: boolean;
  basePrice: string;
  baseAmount: string;
  quoteAmount: string;
  txFrom: string;
}

export interface IMainTokenPrice {
  time: any;
  hash: string;
  pair: string;
  baseToken: string;
  quoteToken: string;
  basePrice: string;
  quotePrice: string;
  priceUsd: string;
  amountUsd: string;
  baseAmount: string;
  quoteAmount: string;
  logIndex: number;
  liquidity: number;
  router: string;
  chainId: string;
}

export interface IBlockDataObj {
  [key: string]: BlockTransactionString;
}

export interface ILogData {
  [pair: string]: ILogDataFormat;
}

export type TPriceHardToHandle = Record<string, { liquidity: number; priceUsd: string }>;

export interface ILogDataFormat {
  hash: string;
  blockNumber: number;
  pair: string;
  from: string;
  to: string;
  logIndex: number;
  sync: {
    pair: string;
    params: [string, string];
  };
  swap: {
    router: string;
    sender: string;
    pair: string;
    // params: [amount0In, amount1In, amount0Out, amount1Out];
    params: [string, string, string, string];
    label?: PairLabel;
  };
  mint?: {
    pair: string;
    params: [string, string];
  };
  burn?: {
    pair: string;
    params: [string, string];
  };
}

const mainTokenPrice = {} as TCachedTokenPrice;
const tokenPriceUsd = {} as TCachedTokenPrice;
const HttpWeb3s = {} as TCachedWeb3;

export const CHAIN_ID = {
  ETH_MAINNET: '1',
  BSC_MAINNET: '56',
  ARB_MAINNET: '42161',
  POL_MAINNET: '137',
  PUL_MAINNET: '369',
  BIT_MAINNET: '7171',
  SHI_MAINNET: '109',
  CYB_MAINNET: '6661',
  SOL_MAINNET: '501424',
  BASE_MAINNET: '8453',
};

export const TOPICS = {
  TRANSFER: '0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef',
  SWAP: '0xd78ad95fa46c994b6551d0da85fc275fe613ce37657fb8d5e3d130840159d822',
  SYNC: '0x1c411e9a96e071241c2f21f7726b17ae89e3cab4c78be50e062b03a9fffbbad1',
  // MINT: HttpsWeb3.utils.sha3('Mint(address,uint256,uint256)'),
  MINT: '0x4c209b5fc8ad50758f13e2e1088ba56a560dff690a1c6fef26394f4c03821c4f',
  // BURN: HttpsWeb3.utils.sha3('Burn(address,uint256,uint256,address)'),
  BURN: '0xdccd412f0b1252819cb1fd330b93224ca42612892bb3f4f789976e6d81936496',
  PAIR_CREATED: '0x0d3648bd0f6ba80134a33ba9275ac585d9d315f0ad8355cddefde31afa28d0e9',
  V3_SWAP: '0xc42079f94a6350d7e6235f29174924f928cc2ac818eb64fed8004e115fbcca67',
  V3_PANCAKE_SWAP: '0x19b47279256b2a23a1665c810c8d55a1758940ee09377d4f8d26497a3577dc83',
};

export const MAIN_TOKEN = {
  [CHAIN_ID.BSC_MAINNET]: {
    MAIN: {
      // WBNB
      address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      decimals: 18,
    },
    BUSD: {
      address: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      decimals: 18,
    }, //
    USDC: {
      address: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
      decimals: 18,
    }, //
    USDT: {
      address: '0x55d398326f99059fF775485246999027B3197955',
      decimals: 18,
    }, //
    STABLE_COINS: [
      '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56', // BUSD
      '0x55d398326f99059fF775485246999027B3197955', // USDT
    ],
    MAIN_PAIRS: [
      /**WBNB/BUSD */
      '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
    ],
    TOKEN_DECIMAL: {
      // WBNB
      '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c': 18,
      // BUSD
      '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56': 18,
      // USDT
      '0x55d398326f99059fF775485246999027B3197955': 18,
    },
    COMMON_QUOTE_TOKENS: [] as string[],
  },
  [CHAIN_ID.ETH_MAINNET]: {
    MAIN: {
      address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
      decimals: 18,
    },
    BUSD: {
      address: '0x4Fabb145d64652a948d72533023f6E7A623C7C53',
      decimals: 18,
    }, //
    USDC: {
      address: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
      decimals: 6,
    }, //
    USDT: {
      address: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
      decimals: 6,
    },
    STABLE_COINS: [
      '0xdAC17F958D2ee523a2206206994597C13D831ec7', // USDT
      '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48', // USDC
      '0x6B175474E89094C44Da98b954EedeAC495271d0F', // DAI
    ],
    MAIN_PAIRS: [
      // WETH/USDT
      '0x0d4a11d5EEaaC28EC3F61d100daF4d40471f1852',
    ],
    TOKEN_DECIMAL: {
      // WETH
      '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2': 18,
      // USDT
      '0xdAC17F958D2ee523a2206206994597C13D831ec7': 6,
    },
    COMMON_QUOTE_TOKENS: [] as string[],
  },
  [CHAIN_ID.ARB_MAINNET]: {
    MAIN: {
      address: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
      decimals: 18,
    },
    BUSD: {
      address: '',
      decimals: 18,
    },
    USDC: {
      address: '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8',
      decimals: 6,
    },
    USDT: {
      address: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
      decimals: 6,
    },
    STABLE_COINS: [
      '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9', // USDT
      '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8', // USDC
      '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1', // DAI
    ],
    MAIN_PAIRS: [
      // WETH/USDT v2 SushiSwap
      '0xCB0E5bFa72bBb4d16AB5aA0c60601c438F04b4ad',
      // // WETH/USDC v2 SushiSwap
      // '0x905dfCD5649217c42684f23958568e533C711Aa3',
      // // WETH/USDC v3
      // '0xC31E54c7a869B9FcBEcc14363CF510d1c41fa443',
      // WETH/USDT v3
      '0x641C00A822e8b671738d32a431a4Fb6074E5c79d',
      // WETH/DAI v3
      '0x31Fa55e03bAD93C7f8AFfdd2eC616EbFde246001',
    ],
    TOKEN_DECIMAL: {
      // WETH
      '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1': 18,
      // USDC
      '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8': 6,
      // USDT
      '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9': 6,
    },
    COMMON_QUOTE_TOKENS: [
      // WBTC
      '0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f',
      // USDs
      '0xD74f5255D557944cf7Dd0E45FF521520002D5748',
    ],
  },
  [CHAIN_ID.POL_MAINNET]: {
    MAIN: {
      address: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
      decimals: 18,
    },
    BUSD: {
      address: '',
      decimals: 18,
    },
    USDC: {
      address: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
      decimals: 6,
    },
    USDT: {
      address: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
      decimals: 6,
    },
    STABLE_COINS: [
      '0xc2132D05D31c914a87C6611C10748AEb04B58e8F', // USDT
      '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174', // USDC
      '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063', // DAI
    ],
    MAIN_PAIRS: [
      // WMATIC/USDT QuickSwap
      '0x604229c960e5CACF2aaEAc8Be68Ac07BA9dF81c3',
      // WMATIC/USDT V3
      '0x9B08288C3Be4F62bbf8d1C20Ac9C5e6f9467d8B7',
      // WMATIC/USDC V3
      '0xA374094527e1673A86dE625aa59517c5dE346d32',
    ],
    TOKEN_DECIMAL: {
      // WETH
      '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270': 18,
      // USDC
      '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174': 6,
      // USDT
      '0xc2132D05D31c914a87C6611C10748AEb04B58e8F': 6,
    },
    COMMON_QUOTE_TOKENS: [
      // WBTC
      '0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6',
      // WETH
      '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
      // MMF
      '0x22a31bD4cB694433B6de19e0aCC2899E553e9481',
      // stMatic
      '0x3A58a54C066FdC0f2D55FC9C89F0415C92eBf3C4',
    ],
  },
  [CHAIN_ID.PUL_MAINNET]: {
    MAIN: {
      address: '0xA1077a294dDE1B09bB078844df40758a5D0f9a27',
      decimals: 18,
    },
    BUSD: {
      address: '',
      decimals: 18,
    },
    USDC: {
      address: '0x15D38573d2feeb82e7ad5187aB8c1D52810B1f07',
      decimals: 6,
    },
    USDT: {
      address: '0x0Cb6F5a34ad42ec934882A05265A7d5F59b51A2f',
      decimals: 6,
    },
    STABLE_COINS: [
      '0x0Cb6F5a34ad42ec934882A05265A7d5F59b51A2f', // USDT
      '0x15D38573d2feeb82e7ad5187aB8c1D52810B1f07', // USDC
      '0xefD766cCb38EaF1dfd701853BFCe31359239F305', // DAI
    ],
    MAIN_PAIRS: [
      // WPLS/USDC
      '0x6753560538ECa67617A9Ce605178F788bE7E524E',
      // WPLS/USDT
      '0x322Df7921F28F1146Cdf62aFdaC0D6bC0Ab80711',
      // WPLS/DAI
      '0xE56043671df55dE5CDf8459710433C10324DE0aE',
    ],
    TOKEN_DECIMAL: {
      // WPLS
      '0xA1077a294dDE1B09bB078844df40758a5D0f9a27': 18,
      // USDC
      '0x15D38573d2feeb82e7ad5187aB8c1D52810B1f07': 6,
      // USDT
      '0xdAC17F958D2ee523a2206206994597C13D831ec7': 6,
    },
    COMMON_QUOTE_TOKENS: [
      // PLSX
      '0x95B303987A60C71504D99Aa1b13B4DA07b0790ab',
      // WBTC
      '0xb17D901469B9208B17d916112988A3FeD19b5cA1',
      // WETH
      '0x02DcdD04e3F455D838cd1249292C58f3B79e3C3C',
      // HEX
      '0x2b591e99afE9f32eAA6214f7B7629768c40Eeb39',
      // PHIAT
      '0x96E035ae0905EFaC8F733f133462f971Cfa45dB1',
      // INC
      '0xf808bb6265e9ca27002c0a04562bf50d4fe37eaa',
    ],
  },
  [CHAIN_ID.BIT_MAINNET]: {
    MAIN: {
      address: '0x413f0E3A440abA7A15137F4278121450416882d5',
      decimals: 18,
    },
    BUSD: {
      address: '',
      decimals: 18,
    },
    USDC: {
      address: '',
      decimals: 6,
    },
    USDT: {
      address: '',
      decimals: 6,
    },
    STABLE_COINS: [] as string[],
    MAIN_PAIRS: [] as string[],
    TOKEN_DECIMAL: {},
    COMMON_QUOTE_TOKENS: [] as string[],
  },
  [CHAIN_ID.SHI_MAINNET]: {
    MAIN: {
      address: '0x213c25900f365F1BE338Df478CD82beF7Fd43F85',
      decimals: 18,
    },
    BUSD: {
      address: '',
      decimals: 18,
    },
    USDC: {
      address: '',
      decimals: 6,
    },
    USDT: {
      address: '',
      decimals: 6,
    },
    STABLE_COINS: [] as string[],
    MAIN_PAIRS: [] as string[],
    TOKEN_DECIMAL: {},
    COMMON_QUOTE_TOKENS: [
      '0x8ed7d143ef452316ab1123d28ab302dc3b80d3ce', // WETH
    ] as string[],
  },
  [CHAIN_ID.CYB_MAINNET]: {
    MAIN: {
      address: '0x8e3607E6BF321a9D87273CA9021ec51cf1C55fFE', // WCYBA
      decimals: 18,
      symbol: 'WCYBA',
    },
    BUSD: {
      symbol: 'BUSD',
      address: '',
      decimals: 18,
    },
    USDC: {
      symbol: 'USDC',
      address: '',
      decimals: 18,
    },
    USDT: {
      symbol: 'USDT',
      address: '0x84c7f870137b48c00F601FD13667651338007599',
      decimals: 18,
    },
    STABLE_COINS: [
      '0x84c7f870137b48c00F601FD13667651338007599', // USDT
    ],
    MAIN_PAIRS: [
      // WCYBA/USDT
      '0xCb3eDA96719265FccCDd8daF6E584B5C3dE49bCe',
    ],
    TOKEN_DECIMAL: {
      // WPLS
      '0x8e3607E6BF321a9D87273CA9021ec51cf1C55fFE': 18,
      // USDT
      '0x84c7f870137b48c00F601FD13667651338007599': 18,
    },
    COMMON_QUOTE_TOKENS: [],
  },
  [CHAIN_ID.SOL_MAINNET]: {
    MAIN: {
      address: 'So11111111111111111111111111111111111111112', // WCYBA
      decimals: 9,
      symbol: 'SOL',
    },
    BUSD: {
      symbol: 'BUSD',
      address: '',
      decimals: 6,
    },
    USDC: {
      symbol: 'USDC',
      address: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
      decimals: 6,
    },
    USDT: {
      symbol: 'USDT',
      address: 'Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB',
      decimals: 6,
    },
    STABLE_COINS: [
      'Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB', // USDT
      'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v', // USDC
    ],
    MAIN_PAIRS: [
      // RAYDIUM SOL/USDT
      '7XawhbbxtsRcQA8KTkHT9f9nc6d69UwqCDh6U5EEbEmX',
    ],
    MAIN_PAIRS_BACKUP: [
      // Baby swap SOL/USDT https://www.alpha.com/bsc/0x570A5D26f7765Ecb712C0924E4De545B89fD43dF
      { address: '0xAe08C9D357731FD8d25681dE753551BE14C00405', chainId: CHAIN_ID.BSC_MAINNET },
    ],
    TOKEN_DECIMAL: {
      // SOL
      So11111111111111111111111111111111111111112: 9,
      // USDT
      Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB: 6,
      // USDC
      EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v: 6,
    },
    COMMON_QUOTE_TOKENS: [],
  },
  [CHAIN_ID.BASE_MAINNET]: {
    MAIN: {
      address: '0x4200000000000000000000000000000000000006', // WETH
      decimals: 18,
      symbol: 'WETH',
    },
    BUSD: {
      symbol: 'BUSD',
      address: '',
      decimals: 18,
    },
    USDC: {
      symbol: 'USDC',
      address: '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913',
      decimals: 6,
    },
    USDT: {
      symbol: 'USDT',
      address: '',
      decimals: 18,
    },
    STABLE_COINS: [
      '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913', // USDC
    ],
    MAIN_PAIRS: [
      // WETH/USDT
      '0xaEeB835f3Aa21d19ea5E33772DaA9E64f1b6982F',
      // WETH/USDC v3
      '0xd0b53D9277642d899DF5C87A3966A349A798F224',
      // WETH/USDbC
      '0x4C36388bE6F416A29C8d8Eee81C771cE6bE14B18',
    ],
    TOKEN_DECIMAL: {
      // WETH
      '0x4200000000000000000000000000000000000006': 18,
      // USDC
      '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913': 6,
    },
    COMMON_QUOTE_TOKENS: [],
  },
};

export const setMainTokenPrice = (tokenAddress: string, price: Decimal) => {
  mainTokenPrice[tokenAddress] = price;
};

/**
 * Don't care about whether or not this address is on curve
 */
export function isSolanaAddress(address: string) {
  try {
    new PublicKey(address);
    return true;
  } catch (error) {
    return false;
  }
}

export function isSupportedAddress(address: string) {
  return Web3.utils.isAddress(address) || isSolanaAddress(address);
}

export const toCheckSum = (address: string) => {
  const isEtherAddress = Web3.utils.isAddress(address);
  if (isEtherAddress) return Web3.utils.toChecksumAddress(address);

  const isSol = isSolanaAddress(address);
  // If is not ether address & is solana address. Then do nothing & return address
  if (isSol) return address;

  return Web3.utils.toChecksumAddress(address);
};

export const getChain = (chainId?: string | number) => {
  const chain = supportedChains.find((item) => String(item.id) === String(chainId));
  return chain || bsc;
};

export const getWeb3 = (chainId?: string | number) => {
  const chain = getChain(chainId);

  if (!HttpWeb3s[chain.id]) {
    const rpcUrl = getNetworkRpc(chain.id);
    const HttpProvider = new Web3.providers.HttpProvider(rpcUrl);

    HttpWeb3s[chain.id] = new Web3(HttpProvider);
  }

  return HttpWeb3s[chain.id];
};

export const getPairV2Contract = (address: string, web3: Web3) => {
  return new web3.eth.Contract(abis.uniswapV2PairABI as any, address);
};

export function getMultiCallContract(chainId: string | number) {
  const chain = getChain(chainId);
  const HttpWeb3 = getWeb3(chainId);
  if (!HttpWeb3) return;

  return new HttpWeb3.eth.Contract(abis.multicallABI as any, chain?.contracts?.multicall3?.address);
}

export const getRouterV2Contract = (address: string, web3: Web3) => {
  return new web3.eth.Contract(abis.uniswapV2RouterABI as any, address);
};

export const getContract = (address: string, abi: any, web3: Web3) => {
  return new web3.eth.Contract(abi, address);
};

export const getTokenContract = (address: string, web3: Web3) => {
  return new web3.eth.Contract(abis.erc20ABI as any, address);
};

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const getBatchTransaction = (web3: Web3, txtHashes: string[]): Promise<IGetBatchTransaction> => {
  const TIME_OUT = 30000;
  const totalItems = txtHashes.length;

  const batch = new web3.BatchRequest();
  const getTransactionReceipt = web3.eth.getTransactionReceipt as any;

  const transactions = [] as Array<TransactionReceipt | null>;

  return new Promise((resolve, reject) => {
    const timeout = setTimeout(() => {
      reject('timeout');
    }, TIME_OUT);

    txtHashes.forEach((txtHash) => {
      batch.add(
        getTransactionReceipt.request(txtHash, (err: unknown, txtInfo: TransactionReceipt) => {
          if (err) {
            transactions.push(null);
          } else {
            transactions.push(txtInfo);
          }

          if (transactions.length === totalItems) {
            resolve({ transactions, errors: [] });
            clearTimeout(timeout);
          }
        }),
      );
    });

    batch.execute();
  });
};

export const getBatchBlockData = (
  web3: Web3,
  blockIds: number[],
): Promise<{ [key: string]: BlockTransactionString }> => {
  const TIME_OUT = 30000;
  let totalBlocks = blockIds.length;

  const batch = new web3.BatchRequest();
  const getTransactionReceipt = web3.eth.getBlock as any;

  const results = {} as { [blockId: string]: BlockTransactionString };

  return new Promise((rel, rej) => {
    const timeout = setTimeout(() => {
      rej('Timeout');
    }, TIME_OUT);

    blockIds.forEach((blockId) => {
      batch.add(
        getTransactionReceipt.request(blockId, (err: unknown, blockInfo: BlockTransactionString) => {
          if (err) return rej(err);

          if (blockInfo) {
            results[blockId] = blockInfo;
          } else {
            totalBlocks = totalBlocks - 1;
          }

          if (Object.keys(results).length === totalBlocks) {
            rel(results);
            clearTimeout(timeout);
          }
        }),
      );
    });

    batch.execute() as any;
  });
};

export const getTransaction = async (web3: Web3, txtHashes: string[]): Promise<Array<TransactionReceipt>> => {
  try {
    const data = await Promise.all(txtHashes.map((txtHash) => web3.eth.getTransactionReceipt(txtHash)));
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getTransactionByHashes = async (web3: Web3, txtHashes: string[]) => {
  const chunks = chunk(txtHashes, 50);
  const results = [] as TransactionReceipt[];

  for (let chunk of chunks) {
    const { transactions } = await getBatchTransaction(web3, chunk);
    results.push(...compact(transactions));
  }

  return results;
};

export function decodeParameters(web3: Web3, types: any[], hexString: string) {
  // if (hexString === '0x') return ['0'];
  const paramsObj = web3.eth.abi.decodeParameters(types, hexString);

  const params = Object.values(paramsObj);
  params.length = paramsObj.__length__;
  return params;
}

export function decodeParameter(web3: Web3, type: string, hexString: string) {
  // if (hexString === '0x') return '0';
  return web3.eth.abi.decodeParameter(type, hexString);
}

export function getSyncData(web3: Web3, log: Log) {
  const pair = toCheckSum(log.address);
  const decodedParams = decodeParameters(web3, ['uint112', 'uint112'], log.data);
  return { pair: toCheckSum(pair), params: decodedParams };
}

// extract swap informations from the log
export function getSwapData(web3: Web3, log: Log) {
  const router = '0x' + log.topics[1].substring(26);
  const sender = '0x' + log.topics[2].substring(26);
  const decodedParams = decodeParameters(web3, ['uint256', 'uint256', 'uint256', 'uint256'], log.data);
  const pair = toCheckSum(log.address);
  return {
    router: toCheckSum(router),
    sender: toCheckSum(sender),
    pair: toCheckSum(pair),
    params: decodedParams,
    label: PairLabel.V2,
  };
}

export function getV3SwapData(web3: Web3, log: Log) {
  const router = '0x' + log.topics[1].substring(26);
  const sender = '0x' + log.topics[2].substring(26);
  // amount0, amount1, sqrtPriceX96, liquidity, tick
  const decodedParams = decodeParameters(web3, ['int256', 'int256', 'uint160', 'uint128', 'int24'], log.data);

  const pair = log.address;

  return {
    router: router,
    sender: sender,
    pair: pair,
    params: decodedParams,
    label: PairLabel.V3,
  };
}

export function getV3PancakeSwapData(web3: Web3, log: Log) {
  const router = '0x' + log.topics[1].substring(26);
  const sender = '0x' + log.topics[2].substring(26);

  // amount0, amount1, sqrtPriceX96, liquidity, tick, protocolFeesToken0, protocolFeesToken1
  const decodedParams = decodeParameters(
    web3,
    ['int256', 'int256', 'uint160', 'uint128', 'int24', 'uint128', 'uint128'],
    log.data,
  );
  const pair = log.address;

  return {
    router: router,
    sender: sender,
    pair: pair,
    params: decodedParams,
    label: PairLabel.V3,
  };
}

export function getTransferData(web3: Web3, log: Log) {
  const from = '0x' + log.topics[1].substring(26);
  const to = '0x' + log.topics[2].substring(26);
  const decodedParams = decodeParameters(web3, ['uint256'], log.data);
  const token = toCheckSum(log.address);
  return {
    token: toCheckSum(token),
    params: decodedParams,
    from: toCheckSum(from),
    to: toCheckSum(to),
  };
}

export function getMintData(web3: Web3, log: Log) {
  const pair = toCheckSum(log.address);
  try {
    const decodedParams = decodeParameters(web3, ['uint112', 'uint112'], log.data);
    return { pair, params: decodedParams };
  } catch (error) {
    return { pair, params: [] };
  }
}

export function getBurnData(web3: Web3, log: Log) {
  const pair = toCheckSum(log.address);
  try {
    const decodedParams = decodeParameters(web3, ['uint112', 'uint112'], log.data);
    return { pair, params: decodedParams };
  } catch (error) {
    return { pair, params: [] };
  }
}

function handleSyncEvent(web3: Web3, log: Log, relevantEvents: any, receipt: TransactionReceipt) {
  const data = getSyncData(web3, log);

  if (!relevantEvents[data.pair]) {
    relevantEvents[data.pair] = {
      pair: data.pair,
      hash: receipt.transactionHash,
      blockNumber: receipt.blockNumber,
      from: receipt.from,
      to: receipt.to,
    };
  }

  relevantEvents[data.pair].sync = data;
}

function handleV3UniSwapEvent(
  web3: Web3,
  log: Log,
  relevantEvents: any,
  relevantEventsArray: any[],
  receipt: TransactionReceipt,
) {
  try {
    const data = getV3SwapData(web3, log);

    if (!relevantEvents[data.pair]) {
      relevantEvents[data.pair] = {
        pair: data.pair,
        hash: receipt.transactionHash,
        from: receipt.from,
        to: receipt.to,
        blockNumber: receipt.blockNumber,
      };
    }

    relevantEvents[data.pair].logIndex = log.logIndex;
    relevantEvents[data.pair].swap = data;

    relevantEventsArray.push(relevantEvents);
    relevantEvents = {};
  } catch (e) {
    console.log(e);
  }
}

function handleV3PancakeSwapEvent(
  web3: Web3,
  log: Log,
  relevantEvents: any,
  relevantEventsArray: any[],
  receipt: TransactionReceipt,
) {
  try {
    const data = getV3PancakeSwapData(web3, log);

    if (!relevantEvents[data.pair]) {
      relevantEvents[data.pair] = {
        pair: data.pair,
        hash: receipt.transactionHash,
        from: receipt.from,
        to: receipt.to,
        blockNumber: receipt.blockNumber,
      };
    }

    relevantEvents[data.pair].logIndex = log.logIndex;
    relevantEvents[data.pair].swap = data;

    relevantEventsArray.push(relevantEvents);
    relevantEvents = {};
  } catch (e) {
    console.log(e);
  }
}

function handleSwapEvent(
  web3: Web3,
  log: Log,
  relevantEvents: any,
  relevantEventsArray: any[],
  receipt: TransactionReceipt,
) {
  const data = getSwapData(web3, log);

  if (!relevantEvents[data.pair]) {
    relevantEvents[data.pair] = {
      pair: data.pair,
      hash: receipt.transactionHash,
      from: receipt.from,
      to: receipt.to,
      blockNumber: receipt.blockNumber,
    };
  }

  relevantEvents[data.pair].logIndex = log.logIndex;
  relevantEvents[data.pair].swap = data;

  relevantEventsArray.push(relevantEvents);
  relevantEvents = {};
}

function handleMintEvent(web3: Web3, log: Log, relevantEvents: any, relevantEventsArray: any[]) {
  const data = getMintData(web3, log);

  if (relevantEvents[data.pair]?.sync) {
    relevantEventsArray.push({
      [data.pair]: {
        ...relevantEvents[data.pair],
        mint: data,
        logIndex: log.logIndex,
      },
    });
  }
}

function handleBurnEvent(web3: Web3, log: Log, relevantEvents: any, relevantEventsArray: any[]) {
  const data = getBurnData(web3, log);

  if (relevantEvents[data.pair]?.sync) {
    relevantEventsArray.push({
      [data.pair]: {
        ...relevantEvents[data.pair],
        burn: data,
        logIndex: log.logIndex,
      },
    });
  }
}

export function handleLogInfoV2(
  web3: Web3,
  receipt: TransactionReceipt,
  listPairs?: string[],
): {
  [pair: string]: ILogDataFormat;
}[] {
  const relevantEventsArray = [] as any[];
  let relevantEvents = {} as any;

  receipt.logs = receipt.logs
    .sort((a, b) => a.logIndex - b.logIndex)
    .map((log) => {
      const hasSyncEvent = log.topics.includes(TOPICS.SYNC);
      const hasSwapEvent = log.topics.includes(TOPICS.SWAP);
      const hasV3UniSwapEvent = log.topics.includes(TOPICS.V3_SWAP);
      const hasV3PancakeSwapEvent = log.topics.includes(TOPICS.V3_PANCAKE_SWAP);
      const hasMintEvent = log.topics.includes(TOPICS.MINT);
      const hasBurnEvent = log.topics.includes(TOPICS.BURN);
      if (hasSyncEvent) {
        handleSyncEvent(web3, log, relevantEvents, receipt);
      }

      if (hasMintEvent) {
        handleMintEvent(web3, log, relevantEvents, relevantEventsArray);
      }
      if (hasBurnEvent) {
        handleBurnEvent(web3, log, relevantEvents, relevantEventsArray);
      }

      if (hasV3UniSwapEvent) {
        handleV3UniSwapEvent(web3, log, relevantEvents, relevantEventsArray, receipt);
      }

      if (hasV3PancakeSwapEvent) {
        handleV3PancakeSwapEvent(web3, log, relevantEvents, relevantEventsArray, receipt);
      }

      if (hasSwapEvent) {
        handleSwapEvent(web3, log, relevantEvents, relevantEventsArray, receipt);
      }

      return log;
    });

  return relevantEventsArray;
}

export function formatLogData(web3: Web3, transactions: TransactionReceipt[], listPairs?: string[]) {
  // Format data
  const formated = transactions.reduce(
    (acc, tx) => {
      // const relevantEvents = this.handleLogInfo(tx);
      // acc.push(...relevantEvents);
      const relevantEvents = handleLogInfoV2(web3, tx, listPairs);
      acc.push(...relevantEvents);
      // const relevantEvents = this.handleLogInfoOld(tx);
      // acc.push(relevantEvents);
      return acc;
    },
    [] as {
      [pair: string]: ILogDataFormat;
    }[],
  );

  // filter null
  const logData = formated.filter((item) => Object.keys(item)?.length);

  // const { pairs, liquidity } = logData.reduce(
  //   (acc, cur) => {
  //     const pairs = Object.keys(cur);
  //     acc.pairs.push(...pairs);

  //     pairs.forEach((pair) => {
  //       if (cur[pair].sync) {
  //         acc.liquidity[pair] = cur[pair].sync.params;
  //       }
  //     });

  //     return acc;
  //   },
  //   { pairs: [], liquidity: {} } as { pairs: string[]; liquidity: { [pair: string]: [string, string] } },
  // );

  return {
    // pairs: uniq(pairs),
    pairs: [],
    // tokens: uniq(tokens),
    logData,
    // liquidity,
    liquidity: {},
  };
}

interface IPairInfo {
  [pair: string]: { token0?: string; token1?: string; factory?: string };
}
export function getBatchPairInfo(web3: Web3, pairAddress: string[]): Promise<IPairInfo> {
  pairAddress = uniq(pairAddress);
  const TIME_OUT = 20000;
  const totalPair = pairAddress.length;

  const batch = new web3.BatchRequest();
  const data = {} as IPairInfo;
  const results = {} as IPairInfo;

  return new Promise((resolve, reject) => {
    const timeout = setTimeout(() => reject('Timeout. Check your RPC URL'), TIME_OUT);
    const fields = ['token0', 'token1', 'factory'];
    const totalField = fields.length;

    pairAddress.forEach((pair) => {
      const pairContract = getPairV2Contract(pair, web3);

      fields.forEach((method) => {
        batch.add(
          pairContract.methods[method]().call.request((err: unknown, result: string) => {
            if (!data[pair]) data[pair] = {};

            if (err || !result) {
              (data as any)[pair][method] = '';
            } else {
              (data as any)[pair][method] = result;
            }

            // Calc total supply
            if (Object.keys(data[pair]).length === totalField) {
              results[pair] = { ...data[pair] };
            }

            // All done.
            if (Object.keys(results).length === totalPair) {
              resolve(results);
              clearTimeout(timeout);
            }
          }),
        );
      });
    });

    batch.execute();
  });
}

interface ITokenInfo {
  [token: string]: { name?: string; symbol?: string; decimals?: string; totalSupply?: string };
}
export function getBatchTokenInfo(web3: Web3, tokenAddress: string[]): Promise<ITokenInfo> {
  tokenAddress = uniq(tokenAddress);
  const TIME_OUT = 20000;
  const totalToken = tokenAddress.length;

  const batch = new web3.BatchRequest();
  const data = {} as ITokenInfo;
  const results = {} as ITokenInfo;

  return new Promise((resolve, reject) => {
    const timeout = setTimeout(() => reject('Timeout. Check your RPC URL'), TIME_OUT);
    const fields = ['name', 'symbol', 'decimals', 'totalSupply'];
    const totalField = fields.length;

    tokenAddress.forEach((token) => {
      const tokenContract = getTokenContract(token, web3);

      fields.forEach((method) => {
        batch.add(
          tokenContract.methods[method]().call.request(async (err: unknown, result: string) => {
            if (!data[token]) data[token] = {};

            if (err || !result) {
              (data as any)[token][method] = '';
            } else {
              (data as any)[token][method] = result;
            }

            // Calc total supply
            if (Object.keys(data[token]).length === totalField) {
              results[token] = { ...data[token] };
            }

            // All done.
            if (Object.keys(results).length === totalToken) {
              resolve(results);
              clearTimeout(timeout);
            }
          }),
        );
      });
    });

    batch.execute();
  });
}

type Call = {
  address: string; // Address of the contract
  name: string; // Function name on the contract (example: balanceOf)
  params?: any[]; // Function params
};

export async function multicall(contract: any, abi: any, calls: Call[]): Promise<any> {
  try {
    const itf = new Interface(abi);
    const calldata = calls.map((call) => [toCheckSum(call.address), itf.encodeFunctionData(call.name, call.params)]);
    const { returnData } = await contract.methods.aggregate(calldata).call();
    const res = returnData.map((call: any, i: number) => itf.decodeFunctionResult(calls[i].name, call));

    return res;
  } catch (error: any) {
    console.log(error);
    throw new Error(error);
  }
}

export async function getPairReserve(chainId: number, address: string) {
  const multiCallContract = getMultiCallContract(String(chainId));
  if (!multiCallContract) return;

  try {
    const [[reserve0, reserve1], [token0], [token1]] = await multicall(multiCallContract, abis.uniswapV2PairABI, [
      {
        address: address,
        name: 'getReserves',
      },
      {
        address: address,
        name: 'token0',
      },
      {
        address: address,
        name: 'token1',
      },
    ]);

    return [reserve0, reserve1, token0, token1];
  } catch (error) {
    console.log('Pair info error', address);
    return;
  }
}

export async function getPairTokens(chainId: number, address: string) {
  const multiCallContract = getMultiCallContract(String(chainId));
  if (!multiCallContract) return;

  try {
    const [[token0], [token1]] = await multicall(multiCallContract, abis.uniswapV2PairABI, [
      {
        address: address,
        name: 'token0',
      },
      {
        address: address,
        name: 'token1',
      },
    ]);

    return [token0, token1];
  } catch (error) {
    console.log('Pair info error', address);
    return;
  }
}

export function areEqualAddress(firstAddress: string, secondAddress: string) {
  try {
    return toCheckSum(firstAddress) === toCheckSum(secondAddress);
  } catch (error) {
    return false;
  }
}

export function fromRawBalance(input: number | string, decimals = 18) {
  // input * 10 ** decimals
  const _decimals = new Decimal(10).pow(new Decimal(decimals));

  return new Decimal(String(input)).dividedBy(_decimals);
}

export function toRawBalance(input: number | string, decimals = 18) {
  // input * 10 ** decimals
  const _decimals = new Decimal(10).pow(new Decimal(decimals));

  return new Decimal(String(input)).mul(_decimals);
}

/**
 * pairs: IPairTokenHierarchy
 * @returns [baseToken, quoteToken]
 */
// export function getTokenHierarchy(chainId: number, token0: string, token1: string) {
//   const { USDT, BUSD, USDC, MAIN, STABLE_COINS, COMMON_QUOTE_TOKENS } = MAIN_TOKEN[chainId];

//   // Has stable coin
//   const isToken0AStableCoin = STABLE_COINS.includes(token0);
//   const isToken1AStableCoin = STABLE_COINS.includes(token1);

//   // If both are stable coin. USDT, BUSD, USDC, DAI alway is quote token
//   if (isToken0AStableCoin && isToken1AStableCoin) {
//     // Token0 is USDT
//     const isToken0USDT = areEqualAddress(token0, USDT.address);
//     if (isToken0USDT) return [token1, token0];

//     // Token0 is BUSD
//     const isToken0BUSD = areEqualAddress(token0, BUSD.address);
//     if (isToken0BUSD) return [token1, token0];

//     // Token0 is USDC
//     const isToken0USDC = areEqualAddress(token0, USDC.address);
//     if (isToken0USDC) return [token1, token0];

//     // If don't, preserve current order
//     return [token0, token1];
//   }

//   // Has one stable coin. Stable coin alway is quote token
//   if (isToken0AStableCoin || isToken1AStableCoin) {
//     if (isToken0AStableCoin) return [token1, token0];
//     if (isToken1AStableCoin) return [token0, token1];
//   }

//   // Don't have stable coin. Check common quote token
//   // WBNB
//   const isToken0WBNB = areEqualAddress(token0, MAIN.address);
//   const isToken1WBNB = areEqualAddress(token1, MAIN.address);

//   if (isToken0WBNB) return [token1, token0];
//   if (isToken1WBNB) return [token0, token1];

//   // Just for ARB1, POL, PUL
//   const isToken0CommonQuote = COMMON_QUOTE_TOKENS.includes(toCheckSum(token0));
//   const isToken1CommonQuote = COMMON_QUOTE_TOKENS.includes(toCheckSum(token1));
//   if (isToken0CommonQuote) return [token1, token0];
//   if (isToken1CommonQuote) return [token0, token1];

//   return [token0, token1];
// }

export function getBaseQuoteToken(pairInfo: IPairDetail) {
  return [pairInfo.baseToken, pairInfo.quoteToken];
}

function handlePairMintOrBurn(
  chainId: string,
  blockData: IBlockDataObj,
  _pairData: ILogDataFormat,
  pairData: IPairDetail,
  priceUsdOfPairHardToHandle?: TPriceHardToHandle,
) {
  const { sync, hash, pair, blockNumber, from, to } = _pairData;

  const timestamp = blockData[blockNumber].timestamp;

  if (!sync) return; // if there is no a sync event, skip this;

  // Total token in pair
  const [reserve0, reserve1] = sync.params;

  if (!pairData) return;

  const { token0, token1, decimal0, decimal1 } = pairData;
  if (!token0 || !token1) return;

  const humanReserve0 = fromRawBalance(reserve0, decimal0);
  const humanReserve1 = fromRawBalance(reserve1, decimal1);

  const reserveObj = {
    [token0]: humanReserve0,
    [token1]: humanReserve1,
  };
  const [baseToken, quoteToken] = getBaseQuoteToken(pairData);

  const basePrice = reserveObj[quoteToken].div(reserveObj[baseToken]).toFixed();
  const quotePrice = reserveObj[baseToken].div(reserveObj[quoteToken]).toFixed();
  changeUsdPrice(+chainId, pair, basePrice);

  const price = calcUsdPrice(+chainId, basePrice, baseToken, quoteToken, priceUsdOfPairHardToHandle);
  let priceUsd = price.priceUsd;

  const [tokenIn, tokenOut] = [quoteToken, baseToken];
  const liquidity = priceUsd
    ? new Decimal(reserveObj[baseToken]).mul(new Decimal(priceUsd)).mul(new Decimal('2')).toFixed()
    : null;

  const isRug = checkPairIsRug('250', liquidity);

  // Overwrite priceUsd
  priceUsd = getRugPrice(isRug, priceUsd);

  const historyTransaction = {
    // Timestamp
    time: timestamp,
    chainId: chainId,
    blockNumber,
    // txn hash
    hash: hash,
    // Sender
    from: from,
    txFrom: from,
    basePrice,
    txTo: to,
    // Router
    router: null,
    // Amount In
    amountIn: '0',
    // Amount Out
    amountOut: '0',
    // Token In. Token sell
    tokenIn,
    // Token Out. Token buy
    tokenOut,
    // Token 0
    token0: token0,
    // Token 1
    token1: token1,
    reserve0: reserve0,
    // Token 1
    reserve1: reserve1,
    priceUsd,
    amountUsd: '0',
    //liquidity
    liquidity,
    // pair
    pair: toCheckSum(pairData.address),
    // Log index
    logIndex: _pairData.logIndex,
    // is Buy or else
    isBuy: false,
    quotePrice,
    baseAmount: '0',
    quoteAmount: '0',
    isHidden: true,
    isRug,
  };

  return { historyTransaction };
}

export async function handlePairTransactionUniswapV3(
  chainId: string,
  blockData: IBlockDataObj,
  logDecoded: ILogDataFormat,
  pairData: IPairDetail,
  priceUsdOfPairHardToHandle?: TPriceHardToHandle,
) {
  const { swap, hash, pair, blockNumber, from, to } = logDecoded;
  const timestamp = blockData[blockNumber].timestamp;

  // some reason token info mission or not exists
  if (!pairData) return;
  const { token0, token1, decimal0, decimal1 } = pairData;
  if (!token0 || !token1) return;
  const sender = toCheckSum(swap.sender);
  const router = toCheckSum(swap.router);

  const [baseToken, quoteToken] = getBaseQuoteToken(pairData);

  const [amount0, amount1, sqrtPriceX96, liquidityV3] = swap.params;
  const amount0NoDecimals = fromRawBalance(new Decimal(amount0).abs().toFixed(), decimal0).toFixed();
  const amount1NoDecimals = fromRawBalance(new Decimal(amount1).abs().toFixed(), decimal1).toFixed();

  const isBaseTokenEqualToken0 = areEqualAddress(baseToken, token0);
  const baseAmount = isBaseTokenEqualToken0 ? amount0NoDecimals : amount1NoDecimals;
  const quoteAmount = !isBaseTokenEqualToken0 ? amount0NoDecimals : amount1NoDecimals;
  const isBuy = isBaseTokenEqualToken0 ? amount0.includes('-') : amount1.includes('-');

  const price1 = calcTokenPriceFromSqrtPriceX96(sqrtPriceX96, decimal0, decimal1);
  const basePrice = isBaseTokenEqualToken0 ? new Decimal('1').div(new Decimal(price1)) : price1;
  const quotePrice = isBaseTokenEqualToken0 ? price1 : new Decimal('1').div(new Decimal(price1));

  const [tokenIn, tokenOut] = isBuy ? [quoteToken, baseToken] : [baseToken, quoteToken];
  const [amountIn, amountOut] = isBuy ? [quoteAmount, baseAmount] : [baseAmount, quoteAmount];
  changeUsdPrice(+chainId, pair, basePrice.toFixed());

  const price = calcUsdPrice(+chainId, basePrice.toFixed(), baseToken, quoteToken, priceUsdOfPairHardToHandle);
  let priceUsd = price.priceUsd;
  // FIXME: check liq too small not use usdPrice
  const liquidity = null;
  let isRug = false;
  if (!Number(liquidityV3)) {
    priceUsd = '0.000000000000000000000000000001';
    isRug = true;
  }
  // Calc amount usd by quote price only
  const amountUsd = priceUsd ? new Decimal(quoteAmount).mul(new Decimal(priceUsd).div(basePrice)).toFixed() : null;

  const historyTransaction = {
    // Timestamp
    time: timestamp,
    blockNumber,
    chainId: chainId,
    // txn hash
    hash: hash,
    // Sender
    from: sender,
    txFrom: from,
    basePrice: basePrice.toFixed(),
    txTo: to,
    // Router
    router: router,
    // Amount In
    amountIn: amountIn,
    // Amount Out
    amountOut: amountOut,
    // Token In. Token sell
    tokenIn,
    // Token Out. Token buy
    tokenOut,
    // Token 0
    token0: token0,
    // Token 1
    token1: token1,
    reserve0: null,
    // Token 1
    reserve1: null,
    priceUsd,
    amountUsd,
    //liquidity
    liquidity,
    // pair
    pair: toCheckSum(pairData.address),
    // Log index
    logIndex: logDecoded.logIndex,
    // is Buy or else
    isBuy,
    quotePrice: quotePrice.toFixed(),
    baseAmount: baseAmount,
    quoteAmount: quoteAmount,
    isHidden: false,
    isRug,
  };

  return { historyTransaction };
}

export async function handlePairTransaction(
  chainId: string,
  blockData: IBlockDataObj,
  logDecoded: ILogDataFormat,
  pairData: IPairDetail,
  priceUsdOfPairHardToHandle?: TPriceHardToHandle,
) {
  const { sync, swap, hash, pair, blockNumber, from, to, mint, burn } = logDecoded;
  if (!isAddressEqual(pairData?.address, pair)) return;

  if (swap?.label === PairLabel.V3) {
    return handlePairTransactionUniswapV3(chainId, blockData, logDecoded, pairData, priceUsdOfPairHardToHandle);
  }
  const timestamp = blockData[blockNumber].timestamp;

  if (!sync) return; // if there is no a sync event, skip this;

  // Totle token in pair
  const [reserve0, reserve1] = sync.params;

  // some reason token info mission or not exists
  if (!pairData) return;

  if (mint || burn) {
    return handlePairMintOrBurn(chainId, blockData, logDecoded, pairData, priceUsdOfPairHardToHandle);
  }

  if (!swap) return;

  // if the pair do not exists on the db, and this is not a swap then skip. ( to avoid making any web3 call to populate the unexistant pair )
  // Maybe is deposit
  if (!swap && !pairData) return;

  const { token0, token1, decimal0, decimal1 } = pairData;
  if (!token0 || !token1) return;

  const sender = toCheckSum(swap.sender);
  const router = toCheckSum(swap.router);

  const humanReserve0 = fromRawBalance(reserve0, decimal0);
  const humanReserve1 = fromRawBalance(reserve1, decimal1);

  const reserveObj = {
    [token0]: humanReserve0,
    [token1]: humanReserve1,
  };

  const [baseToken, quoteToken] = getBaseQuoteToken(pairData);

  // base price
  const basePrice = reserveObj[quoteToken].div(reserveObj[baseToken]).toFixed();
  // const quotePrice = reserveObj[baseToken].div(reserveObj[quoteToken]).toFixed();

  // this.changeUsdPrice(pair, basePrice);

  changeUsdPrice(+chainId, pair, basePrice);

  const price = calcUsdPrice(+chainId, basePrice, baseToken, quoteToken, priceUsdOfPairHardToHandle);
  let priceUsd = price?.priceUsd || null;

  // const isWrongLiquidity = price.liquidity !== undefined && price.liquidity > 1000;

  /* -------------------------------------------------------------------------- */
  /*                         Old get amount by transfer                         */
  /* -------------------------------------------------------------------------- */
  // const sold0 = fromRawBalance(swap.transfer.sold.amount, decimal0).toFixed();
  // const sold1 = fromRawBalance(swap.transfer.sold.amount, decimal1).toFixed();
  // const bought0 = fromRawBalance(swap.transfer.bought.amount, decimal0).toFixed();
  // const bought1 = fromRawBalance(swap.transfer.bought.amount, decimal1).toFixed();
  // const amountInNoDecimals = areEqualAddress(swap.transfer.sold.token, token0) ? sold0 : sold1;
  // const amountOutNoDecimals = areEqualAddress(swap.transfer.bought.token, token0) ? bought0 : bought1;

  /* -------------------------------------------------------------------------- */
  /*                         Get amount from swap event                         */
  /* -------------------------------------------------------------------------- */
  const [amount0In, amount1In, amount0Out, amount1Out] = swap.params;

  const amountInOutObj = {
    [token0]: {
      in: amount0In,
      out: amount0Out,
    },
    [token1]: {
      in: amount1In,
      out: amount1Out,
    },
  };

  const isAmountBaseInGreaterThan0 = new Decimal(amountInOutObj[baseToken].in).greaterThan(new Decimal('0'));
  const isAmountQuoteInGreaterThan0 = new Decimal(amountInOutObj[quoteToken].in).greaterThan(new Decimal('0'));

  let isBuy = true;
  if (isAmountBaseInGreaterThan0) {
    const isSell = new Decimal(amountInOutObj[quoteToken].out).greaterThan(new Decimal(amountInOutObj[quoteToken].in));
    isBuy = !isSell;
  }

  if (isAmountQuoteInGreaterThan0) {
    isBuy = new Decimal(amountInOutObj[baseToken].out).greaterThan(new Decimal(amountInOutObj[baseToken].in));
  }

  const [tokenIn, tokenOut] = isBuy ? [quoteToken, baseToken] : [baseToken, quoteToken];

  const sold0 = fromRawBalance(amount0In, decimal0).toFixed();
  const sold1 = fromRawBalance(amount1In, decimal1).toFixed();
  const bought0 = fromRawBalance(amount0Out, decimal0).toFixed();
  const bought1 = fromRawBalance(amount1Out, decimal1).toFixed();
  const amountInNoDecimals = areEqualAddress(tokenIn, token0) ? sold0 : sold1;
  const amountOutNoDecimals = areEqualAddress(tokenOut, token0) ? bought0 : bought1;

  // FIXME: check liq too small not use usdPrice
  // USD
  const liquidity = priceUsd
    ? new Decimal(reserveObj[baseToken]).mul(new Decimal(priceUsd)).mul(new Decimal('2')).toFixed()
    : null;

  if (liquidity && priceUsd) {
    const dLiquidity = new Decimal(liquidity);
    if (dLiquidity.lessThan(new Decimal('500'))) {
      priceUsd = new Decimal('0.000000000000000001').toFixed();
    }
  }

  // Formatted by decimals
  const amount0 = areEqualAddress(tokenIn, token0) ? sold0 : bought0;
  const amount1 = areEqualAddress(tokenOut, token0) ? sold1 : bought1;

  const amountObj = {
    [token0]: amount0,
    [token1]: amount1,
  };

  // Calc amount usd by quote price only
  const amountUsd = priceUsd
    ? new Decimal(amountObj[quoteToken]).mul(new Decimal(priceUsd).div(new Decimal(basePrice))).toFixed()
    : null;

  const historyTransaction = {
    // Timestamp
    time: timestamp,
    chainId,
    // txn hash
    hash: hash,
    // Sender
    from: sender,
    basePrice,
    // Router
    router: router,
    // Amount In
    amountIn: amountInNoDecimals,
    // Amount Out
    amountOut: amountOutNoDecimals,
    // Token In. Token sell
    tokenIn,
    // Token Out. Token buy
    tokenOut,
    // Token 0
    token0: token0,
    // Token 1
    token1: token1,
    reserve0: reserve0,
    // Token 1
    reserve1: reserve1,
    priceUsd,
    amountUsd,
    //liquidity
    liquidity,
    // pair
    pair: toCheckSum(pairData.address),
    // Log index
    logIndex: logDecoded.logIndex,
    // is Buy or else
    isBuy,
    txFrom: from,
    txTo: to,
    baseAmount: amountObj[baseToken],
    quoteAmount: amountObj[quoteToken],
  };

  return { historyTransaction };
}

async function changeUsdPrice(chainId: number, pair: string, basePrice: string) {
  const { MAIN_PAIRS, MAIN } = MAIN_TOKEN[chainId];

  const isMainPair = MAIN_PAIRS.includes(pair);
  if (isMainPair) {
    mainTokenPrice[MAIN.address] = new Decimal(basePrice);
  }
}

export function randomString(length = 8) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function calcUsdPrice(
  chainId: number,
  basePrice: string,
  baseToken: string,
  quoteToken: string,
  priceUsdOfPairHardToHandle?: TPriceHardToHandle,
) {
  const { MAIN, STABLE_COINS } = MAIN_TOKEN[chainId];

  /* -------------------------------------------------------------------------- */
  /*                               Has stable coin                              */
  /* -------------------------------------------------------------------------- */
  const isQuoteTokenStableCoin = STABLE_COINS.includes(quoteToken);
  if (isQuoteTokenStableCoin) {
    return { priceUsd: basePrice };
  }

  const isBaseTokenStableCoin = STABLE_COINS.includes(baseToken);
  if (isBaseTokenStableCoin) {
    const priceUsd = new Decimal('1').div(new Decimal(basePrice)).toFixed();
    return { priceUsd };
  }

  /* -------------------------------------------------------------------------- */
  /*                               Has main token                               */
  /* -------------------------------------------------------------------------- */
  const isQuoteTokenMain = areEqualAddress(MAIN.address, quoteToken);
  const isBaseTokenMain = areEqualAddress(MAIN.address, baseToken);

  if (isQuoteTokenMain || isBaseTokenMain) {
    const mainTokenPriceUsd = mainTokenPrice[MAIN.address] || null;
    if (mainTokenPriceUsd === null) return { priceUsd: null };

    if (isQuoteTokenMain) {
      const priceUsd = new Decimal(basePrice).mul(mainTokenPriceUsd).toFixed();
      return { priceUsd };
    }

    if (isBaseTokenMain) {
      const quotePrice = new Decimal('1').div(new Decimal(basePrice));
      const priceUsd = quotePrice.mul(mainTokenPriceUsd).toFixed();
      return { priceUsd };
    }
  }

  if (tokenPriceUsd[quoteToken]) {
    const priceUsd = new Decimal(basePrice).mul(tokenPriceUsd[quoteToken]).toFixed();
    return { priceUsd };
  }

  if (tokenPriceUsd[baseToken]) {
    const quotePriceUsd = new Decimal(tokenPriceUsd[baseToken]).div(new Decimal(basePrice));
    const priceUsd = new Decimal(basePrice).mul(quotePriceUsd);
    tokenPriceUsd[quoteToken] = new Decimal(quotePriceUsd);
    return { priceUsd: priceUsd.toFixed() };
  }

  // => 3% pair in this case. ignore that.
  // /* -------------------------------------------------------------------------- */
  // /*                           Both token is un expect                          */
  // /* -------------------------------------------------------------------------- */

  // If have transaction of token with mainpair before.
  if (priceUsdOfPairHardToHandle?.[quoteToken]?.priceUsd) {
    const priceUsd = new Decimal(basePrice).mul(new Decimal(priceUsdOfPairHardToHandle[quoteToken].priceUsd)).toFixed();
    return { priceUsd, liquidity: Number(priceUsdOfPairHardToHandle?.[quoteToken]?.liquidity) || 0 };
  }

  if (priceUsdOfPairHardToHandle?.[baseToken]?.priceUsd) {
    const priceUsd = priceUsdOfPairHardToHandle?.[baseToken]?.priceUsd;
    return { priceUsd, liquidity: Number(priceUsdOfPairHardToHandle?.[baseToken]?.liquidity) || 0 };
  }

  return { priceUsd: null };
  // // ETH/CAKE
  // // basePrice 0.003;
  // // quotePriceMain = 0.5
  // // mainPrice 300$ = 150$
  // const quotePriceMain = this.tokenPriceBNB[quoteToken];
  // if (quotePriceMain && mainTokenPriceUsd) {
  //   const quotePriceUsd = quotePriceMain.mul(mainTokenPriceUsd);
  //   return new Decimal(basePrice).mul(quotePriceUsd).toFixed();
  // }

  // const basePriceMain = this.tokenPriceBNB[baseToken];
  // if (basePriceMain && mainTokenPriceUsd) {
  //   const basePriceUsd = basePriceMain.mul(mainTokenPriceUsd);
  //   return new Decimal(basePrice).mul(new Decimal('1').div(basePriceUsd)).toFixed();
  // }

  /**
   * I think it can cover 99% pair.
   * Need more testing
   * Ignore other case.
   * Leave price usd null.
   */

  // Pair with stable coin, WBNB take 97%: pair in BSC.
}

export async function handleLogData(
  chainId: string,
  blockData: IBlockDataObj,
  logData: ILogData[],
  pairData: IPairDetail,
  tempMainPriceUsd?: Decimal | null,
  priceUsdOfPairHardToHandle?: TPriceHardToHandle,
) {
  const { MAIN } = MAIN_TOKEN[+chainId];
  // const results = [];
  // const pairs = {};
  // const tokens = {};
  const historyTransactions = [] as IHistoryTransaction[];
  const historyPrices = [] as any[];
  const mainTokenPrices = [] as IMainTokenPrice[];
  // const historyPriceObj = {};

  /**
   * Get prev price first.
   */
  if (!mainTokenPrice[MAIN.address]) {
    if (tempMainPriceUsd) mainTokenPrice[MAIN.address] = tempMainPriceUsd;
  }

  for (const transaction of logData) {
    for (const pair in transaction) {
      const logTx = await handlePairTransaction(
        String(chainId),
        blockData,
        transaction[pair],
        pairData,
        priceUsdOfPairHardToHandle,
      );

      if (!logTx) continue;

      const { historyTransaction } = logTx;

      // results.push(transaction);
      // pairs[pairData.address] = pairData;
      // tokens[result.token0.address] = result.token0;
      // tokens[result.token1.address] = result.token1;

      const _id = randomString(24);

      historyTransactions.push({ _id, ...historyTransaction } as any);

      // if (!historyPriceObj[pair]) {
      //   historyPriceObj[pair] = [];
      // }

      // historyPriceObj[pair].push(historyPrice);

      // historyPrices.push({ hash: historyTransaction.hash, ...historyPrice });

      // const hasStableCoin =
      //   STABLE_COINS.includes(mainTokenPrice.quoteToken) || STABLE_COINS.includes(mainTokenPrice.baseToken);
      // const hasMainToken =
      //   areEqualAddress(MAIN.address, mainTokenPrice.baseToken) ||
      //   areEqualAddress(MAIN.address, mainTokenPrice.quoteToken);

      // if (hasStableCoin || hasMainToken) {
      //   mainTokenPrices.push(mainTokenPrice as any);
      // }
    }
  }

  return {
    // pairs,
    // tokens,
    historyTransactions,
    historyPrices,
    mainTokenPrices,
    // historyPriceObj,
    // debug: results,
  };
}

export function createArrayBlockIds(from: number, to: number): number[] {
  if (to < from) return [];

  const blockIds = [];
  for (let i = from; i <= to; i++) {
    blockIds.push(i);
  }
  return blockIds;
}

export async function getBlockDataByIds(web3: Web3, blockIds: number[]): Promise<IBlockDataObj> {
  try {
    return await getBatchBlockData(web3, blockIds);
  } catch (error) {
    throw error;
    // const data = await Promise.all(blockIds.map((item) => web3.eth.getBlock(item)));

    // return data.reduce((acc, cur) => {
    //   acc[cur.number] = cur;
    //   return acc;
    // }, {} as IBlockDataObj);
  }
}

export async function getTransactionHashesFromBlockData(blockData: BlockTransactionString[]) {
  const txtHashes = blockData.reduce((transactions, block) => {
    transactions.push(...block.transactions);
    return transactions;
  }, [] as string[]);

  return txtHashes;
}

async function getTxnHashes(web3: Web3, fromBlock: number, toBlock: number, params: IGetTransactionInfo) {
  if (!params.blockData) {
    const blockIds = createArrayBlockIds(fromBlock, toBlock);

    /* -------------------------------------------------------------------------- */
    /*                               Get block data                               */
    /* -------------------------------------------------------------------------- */
    params.blockData = await getBlockDataByIds(web3, blockIds);
  }

  /* -------------------------------------------------------------------------- */
  /*                       Get transaction data from block                      */
  /* -------------------------------------------------------------------------- */
  // Get data from getPastLog.
  return getPastLogsDemo(web3, { fromBlock, toBlock });

  // const transactionHashes = await getTransactionHashesFromBlockData(Object.values(params.blockData));

  // return transactionHashes;
}

export async function getPastLogsDemo(web3: Web3, params: { fromBlock: number; toBlock: number }) {
  const blockIds = createArrayBlockIds(params.fromBlock, params.toBlock);

  const results = (await getBatchPastLog(web3, blockIds)) as any;

  const receipts = results.results.reduce(
    (acc: TransactionReceipt[], cur: TransactionReceipt[]) => [...acc, ...cur],
    [] as TransactionReceipt[],
  );

  return uniq(receipts.map((item: TransactionReceipt) => item.transactionHash)) as any;
}

export function getBatchPastLog(web3: Web3, blockNumbers: number[]) {
  const TIME_OUT = 30000;
  const totalBlocks = blockNumbers.length;

  const batch = new web3.BatchRequest();
  const getTransactionReceipt = web3.eth.getPastLogs as any;

  const results = [] as any[];

  return new Promise((rel, rej) => {
    const timeout = setTimeout(() => {
      rel({ results });
    }, TIME_OUT);

    blockNumbers.forEach((blockNumber) => {
      const params = {
        topics: [[TOPICS.MINT, TOPICS.BURN, TOPICS.SWAP, TOPICS.V3_SWAP, TOPICS.V3_PANCAKE_SWAP]],
        fromBlock: +blockNumber,
        toBlock: +blockNumber,
      };

      batch.add(
        getTransactionReceipt.request(params, (err: unknown, txtInfo: Log) => {
          if (err) {
            console.log('err', err);

            results.push(null);
          } else {
            results.push(txtInfo);
          }

          if (Object.keys(results).length === totalBlocks) {
            rel({ results });
            clearTimeout(timeout);
          }
        }),
      );
    });

    batch.execute();

    // Stop after 10 seconds
    setTimeout(() => rej('Timeout. Check your RPC URL'), TIME_OUT);
  });
}

interface IGetTransactionInfo {
  fromBlock: number;
  toBlock: number;
  listPairs: string[];
  pairData: IPairDetail;
  [key: string]: any;
}

export async function getTransactionInformation(
  web3: Web3,
  chainId: string,
  params: IGetTransactionInfo,
  tempMainPriceUsd?: Decimal | null,
) {
  const { fromBlock, toBlock, listPairs } = params;

  const txnHashes = await getTxnHashes(web3, fromBlock, toBlock, params);

  // const txnHashes = ['0x90fc064e77b7fca9b097d9f34893374dbc0ea33c70305174e2930a21b1975a00'];
  if (!txnHashes?.length) {
    return {
      historyTransactions: [] as IHistoryTransaction[],
      historyPrices: [],
      mainTokenPrices: [],
      totalTransactions: 0,
      routers: [],
      liquidity: {},
      pairs: [],
    };
  }
  /* -------------------------------------------------------------------------- */
  /*                                    Test                                    */
  /* -------------------------------------------------------------------------- */

  const transactions = await getTransactionByHashes(web3, txnHashes);

  if (!transactions?.length) {
    return {
      historyTransactions: [] as IHistoryTransaction[],
      historyPrices: [],
      mainTokenPrices: [],
      totalTransactions: 0,
      routers: [],
      liquidity: {},
      pairs: [],
    };
  }

  // console.log('transactions time', Date.now() - startTime);
  /* -------------------------------------------------------------------------- */
  /*                               format log data                              */
  /* -------------------------------------------------------------------------- */

  const { logData, pairs, liquidity } = formatLogData(web3, transactions, listPairs);

  const priceUsdOfPairHardToHandle = {};

  // if (params.getMainTokenPrices) {
  //   const _priceUsdOfPairHardToHandle = await params.getMainTokenPrices.getMainTokenPrices(tokenHardToHandlePriceUsd);
  //   Object.assign(priceUsdOfPairHardToHandle, _priceUsdOfPairHardToHandle);
  // }

  /* -------------------------------------------------------------------------- */
  /*                              Data with candle                              */
  /* -------------------------------------------------------------------------- */
  const handledTransactions = await handleLogData(
    chainId,
    params.blockData,
    logData,
    params.pairData,
    tempMainPriceUsd,
    priceUsdOfPairHardToHandle,
  );

  // console.log('handleLogData', Date.now() - startTime);

  /* -------------------------------------------------------------------------- */
  /*                             Handle candlestick                             */
  /* -------------------------------------------------------------------------- */
  return {
    ...handledTransactions,
    totalTransactions: txnHashes.length,
    liquidity,
    pairs: uniq(pairs),
  };
}

export const setMainTokenPriceByPairInfo = async (pairInfo: IPairDetail, priceUsd?: string) => {
  const mainToken = MAIN_TOKEN[pairInfo.chainId as any];
  if (!mainToken) return;

  if (mainToken.MAIN_PAIRS.includes(pairInfo.address)) {
    if (priceUsd) {
      return setMainTokenPrice(pairInfo.quoteToken, new Decimal(priceUsd));
    }

    if (+pairInfo.priceUsd) {
      return setMainTokenPrice(pairInfo.quoteToken, new Decimal(Number(pairInfo.priceUsd).toString()));
    }
  }

  if (pairInfo.quoteToken === mainToken.MAIN.address && pairInfo.priceUsd && pairInfo.basePrice) {
    const quotePrice = new Decimal('1').div(new Decimal(pairInfo.basePrice));
    const mainPriceUsd = quotePrice.mul(new Decimal(pairInfo.priceUsd));
    return setMainTokenPrice(pairInfo.quoteToken, mainPriceUsd);
  }
};

// Get data from other chain
// const getDataMainTokenPriceFromRpc = (chainId: number) => {
//   if (ChainSolanaIds.includes(chainId)) {
//     const mainPairAddress = MAIN_TOKEN?.[chainId]?.MAIN_PAIRS_BACKUP;
//     if (!mainPairAddress?.length) return {};

//     const firstItem = first(mainPairAddress);
//     return { chainId, mainPairAddress: [firstItem?.address] };
//   }

//   const mainPairAddress = MAIN_TOKEN?.[chainId]?.MAIN_PAIRS;
//   return { chainId, mainPairAddress };
// };
/**
 * Get main token price from RPC
 * FIXME: Temporary get solana price from API
 */
export const fetchMainTokenPriceFromRPC = async (chainId: number) => {
  try {
    if (ChainSolanaIds.includes(Number(chainId))) {
      const data = await getSolanaPriceUsdFromCoinGeeko();
      return Number(data) || 0;
    }

    const mainPairAddress = MAIN_TOKEN?.[chainId]?.MAIN_PAIRS;
    const { MAIN, TOKEN_DECIMAL } = MAIN_TOKEN[chainId] as any;
    if (!mainPairAddress?.length) return;

    const mainPair = first(mainPairAddress) as string;

    const reserve = await getPairReserve(chainId, mainPair);

    if (!reserve) return;
    const [reserve0, reserve1, token0, token1] = reserve;

    if (!TOKEN_DECIMAL[token0] || !TOKEN_DECIMAL[token1]) return;

    if (areEqualAddress(token0, MAIN.address)) {
      mainTokenPrice[MAIN.address] = new Decimal(fromRawBalance(reserve1.toString(), TOKEN_DECIMAL[token1])).div(
        new Decimal(fromRawBalance(reserve0.toString(), TOKEN_DECIMAL[token0])),
      );

      return +mainTokenPrice[MAIN.address].toFixed();
    } else {
      mainTokenPrice[MAIN.address] = new Decimal(fromRawBalance(reserve0.toString(), TOKEN_DECIMAL[token0])).div(
        new Decimal(fromRawBalance(reserve1.toString(), TOKEN_DECIMAL[token1])),
      );
      return +mainTokenPrice[MAIN.address].toFixed();
    }
  } catch (error) {
    console.log('Get main price error', error);
  }
};

interface ICalcQuiteTokenPriceUsd {
  priceUsd: number | string;
  basePrice: number | string;
  baseToken: string;
  quoteToken: string;
  [key: string]: any;
}
export const calcAndAssignQuotePriceUsd = (pairInfo: ICalcQuiteTokenPriceUsd) => {
  const priceUsd = pairInfo.priceUsd;
  const basePrice = pairInfo.basePrice;
  if (!priceUsd || String(priceUsd) === '0') return new Decimal('0');
  if (!basePrice || String(basePrice) === '0') return new Decimal('0');

  const quotePriceUsd = new Decimal(priceUsd).div(new Decimal(basePrice));

  tokenPriceUsd[pairInfo.baseToken] = new Decimal(priceUsd);
  tokenPriceUsd[pairInfo.quoteToken] = quotePriceUsd;

  return quotePriceUsd;
};

export function getBarCandleUnixSecond(timestamp: number, resolution: string) {
  const seconds = getSecondFromResolution(resolution) || 60;

  return timestamp - (timestamp % seconds);
}

export async function getTokenInfoAndBurnBalance(
  chainId: ChainIds,
  address: string,
): Promise<TokenInfoWithBurnBalance> {
  const multiCallContract = getMultiCallContract(chainId || constants.DEFAULT_CHAIN_ID);
  const paramsBurnAddress = constants.BURN_ADDRESSES.map((item) => {
    return {
      address: address,
      name: 'balanceOf',
      params: [item],
    };
  });

  const [[decimals], [totalSupply], [name], [symbol], ...burnBalanceResults] = await multicall(
    multiCallContract,
    abis.erc20ABI,
    [
      {
        address: address,
        name: 'decimals',
      },
      {
        address: address,
        name: 'totalSupply',
      },
      {
        address: address,
        name: 'name',
      },
      {
        address: address,
        name: 'symbol',
      },
      ...paramsBurnAddress,
    ],
  );
  const totalBurned = (burnBalanceResults as BigNumber[][]).reduce((acc, [current]) => {
    acc = acc.add(current || 0);
    return acc;
  }, BigNumber.from('0'));
  return {
    address,
    name,
    symbol,
    decimals,
    totalSupply,
    totalBurned,
  };
}

export async function getERC20TokenInfo(chainId?: string, address?: string) {
  if (!chainId || !address || !isSupportedAddress(address)) return false;

  try {
    // if (ChainSolanaIds.includes(Number(chainId))) {
    //   const { SolClient } = await import('utils/solana-client');
    //   return await SolClient.getSolanaTokenInfo(Number(chainId), address);
    // }

    const multiCallContract = getMultiCallContract(String(chainId || constants.DEFAULT_CHAIN_ID));

    const [[decimals], [totalSupply], [name], [symbol]] = await multicall(multiCallContract, abis.erc20ABI, [
      {
        address: address,
        name: 'decimals',
      },
      {
        address: address,
        name: 'totalSupply',
      },
      {
        address: address,
        name: 'name',
      },
      {
        address: address,
        name: 'symbol',
      },
    ]);

    return {
      address,
      name,
      symbol,
      decimals,
      totalSupply,
    };
  } catch (error) {
    console.error(error);
    return false;
  }
}

export async function getOwnerAddress(chainId: ChainIds, tokenAddress: string) {
  // if (ChainSolanaIds.includes(chainId)) {
  //   try {
  //     const { SolClient } = await import('utils/solana-client');
  //     const owner = await SolClient.getSolanaTokenOwner(chainId, tokenAddress);
  //     return owner;
  //   } catch (error) {}
  // }

  const abi = [
    {
      constant: true,
      inputs: [],
      name: 'owner',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'getOwner',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
  ];

  const HttpsWeb3 = getWeb3(chainId);
  const contract = getContract(tokenAddress, abi, HttpsWeb3);

  try {
    const owner = await contract.methods.owner().call();
    if (owner) return toCheckSum(owner);
  } catch (error) {}

  try {
    const owner = await contract.methods.getOwner().call();
    if (owner) return toCheckSum(owner);
  } catch (error) {}

  return false;
}

export async function getCreatorFromTxnHash(chainId: ChainIds, txnHash: string, tokenAddress: string) {
  try {
    const HttpsWeb3 = getWeb3(chainId);

    const receipt = await HttpsWeb3.eth.getTransactionReceipt(txnHash);
    if (receipt.to || !receipt.contractAddress || !receipt.from) return false;

    if (areEqualAddress(tokenAddress, receipt.contractAddress)) {
      // Deployer
      return toCheckSum(receipt.from);
    }
  } catch (error) {}
}

export const assignIsContract = (transactions: IPairHistoryTransaction[], whiteListAddresses: string[]) => {
  whiteListAddresses = whiteListAddresses || [];

  return transactions.map((item) => {
    item.isContract = false;

    try {
      item.isContract = checkTxToIsBotOrSmartContract(whiteListAddresses, item.txTo);
    } catch (error) {}
    return item;
  });
};

export const checkTxToIsBotOrSmartContract = (whitelist: string[], txTo?: string | null) => {
  if (!txTo) return false;
  return !whitelist.some((item) => isAddressEqual(item, txTo));
};

export async function getPoolBalance(chainId: number, address: string, baseToken: string, quoteToken: string) {
  const multiCallContract = getMultiCallContract(String(chainId));
  if (!multiCallContract) return ['0', '0'];

  try {
    const [[rawBaseBalance], [rawQuoteBalance]] = await multicall(multiCallContract, abis.erc20ABI, [
      {
        address: baseToken,
        name: 'balanceOf',
        params: [address],
      },
      {
        address: quoteToken,
        name: 'balanceOf',
        params: [address],
      },
    ]);

    return [rawBaseBalance, rawQuoteBalance];
  } catch (error) {
    return ['0', '0'];
  }
}
interface ICalcPoolTVL {
  address: string;
  chainId: string;
  basePrice: number;
  priceUsd: number;
  baseTokenData: {
    address: string;
    decimal: number;
  };
  quoteTokenData: {
    address: string;
    decimal: number;
  };
}
export async function calcLiquidityByTokenBalance(params: ICalcPoolTVL) {
  const { MAIN, STABLE_COINS } = MAIN_TOKEN[params.chainId];

  const [, rawQuoteBalance] = await getPoolBalance(
    +params.chainId,
    params.address,
    params.baseTokenData.address,
    params.quoteTokenData.address,
  );
  const quoteReserve = fromRawBalance(rawQuoteBalance, params.quoteTokenData.decimal);

  const isQuoteTokenStableCoin = STABLE_COINS.includes(params.quoteTokenData.address);
  if (isQuoteTokenStableCoin) {
    return quoteReserve.toFixed();
  }

  const isQuoteTokenMain = areEqualAddress(MAIN.address, params.quoteTokenData.address);
  if (isQuoteTokenMain) {
    const mainTokenPriceUsd = mainTokenPrice[MAIN.address] || null;
    if (mainTokenPriceUsd === null) return '0';

    if (isQuoteTokenMain) {
      const liquidity = new Decimal(quoteReserve).mul(mainTokenPriceUsd).toFixed();
      return liquidity;
    }
  }

  const quotePrice = new Decimal('1').div(new Decimal(params.basePrice)).toFixed();

  const quotePriceUsd = new Decimal(quotePrice).mul(new Decimal(params.priceUsd)).toFixed();
  const quoteLiquidity = new Decimal(quoteReserve).mul(new Decimal(quotePriceUsd));

  return quoteLiquidity.toFixed();
}

// FIXME: Support solana
export async function getTokenInfoFromBlockChain(tokenAddress?: string) {
  if (!tokenAddress || !isSupportedAddress(tokenAddress)) return;

  const tasks = await Promise.allSettled(
    supportedChains.map(async (item) => {
      const multiCallContract = getMultiCallContract(String(item.id));
      const [[name], [symbol]] = await multicall(multiCallContract, abis.erc20ABI, [
        {
          address: tokenAddress,
          name: 'name',
          params: [],
        },
        {
          address: tokenAddress,
          name: 'symbol',
          params: [],
        },
      ]);
      return { name, symbol, address: tokenAddress, chainId: item.id };
    }),
  );

  const data = compact(tasks).filter((item) => item.status === 'fulfilled') as PromiseFulfilledResult<{
    name: any;
    symbol: any;
    address: `0x${string}`;
    chainId: number;
  }>[];

  return data.map((item) => ({
    _id: String(Date.now()),
    address: '',
    chainId: String(item?.value?.chainId),
    basePrice: 0,
    baseToken: item?.value?.address,
    baseTokenName: item?.value?.name,
    baseTokenSymbol: item?.value?.symbol,
    factory: '',
    liquidity: 0,
    priceChange24h: 0,
    priceUsd: 0,
    quoteToken: '',
    quoteTokenName: '',
    quoteTokenSymbol: '',
    totalTransaction24h: 0,
    volume24h: 0,
    baseTokenLogo: '',
    quoteTokenLogo: '',
  })) as IPairSearch[];
}

export function checkPairIsRug(minLiquidity: string, liquidity?: string | null) {
  try {
    if (!Number(liquidity)) return true;

    const dLiquidity = new Decimal(Number(liquidity) || '0');
    if (dLiquidity.lessThan(new Decimal(minLiquidity))) {
      return true;
    }
  } catch (error) {}

  return false;
}

export function getRugPrice(isRug: boolean, priceUsd: string | null) {
  try {
    if (!isRug) return priceUsd;

    const RUG_PRICE = new Decimal('0.000000000000000000000000000001');
    const dPriceUsd = new Decimal(priceUsd || '0');

    if (dPriceUsd.eq(new Decimal('0'))) return '0';

    if (dPriceUsd.lessThan(RUG_PRICE)) {
      const priceUsd = dPriceUsd.mul('0.0000001');
      return priceUsd.toFixed();
    }

    return RUG_PRICE.toFixed();
  } catch (error) {}

  return '0';
}
