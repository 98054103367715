import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { generatePersistConfig } from 'utils/misc';
import { persistReducer } from 'redux-persist';

interface State {
  height: number;
  mobileHeight: number;
}

const heightChart = createSlice({
  name: 'heightChart',
  initialState: { height: 500, mobileHeight: window.innerWidth },
  reducers: {
    updateHeightChart: (state: State, action: PayloadAction<number>) => {
      state.height = action.payload;
      state.mobileHeight = action.payload;
    },
  },
});

export const { updateHeightChart } = heightChart.actions;
const persistConfig = generatePersistConfig('heightChart', ['height', 'mobileHeight']);
export default persistReducer<State>(persistConfig, heightChart.reducer);
