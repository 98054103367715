import { format, intervalToDuration, formatDistance } from 'date-fns';
import i18next from 'i18next';
export function formatTimeUnix(time: number) {
  return format(new Date(time * 1000), 'dd/mm/yyyy hh:mm:ss');
}

export function getCurrentDate() {
  try {
    return new Date(format(new Date(), 'yyyy-MM-dd'));
  } catch (error) {
    return null;
  }
}

export function formatTime(time?: number | Date, formatType?: string) {
  if (!time) return '-';
  return format(time, formatType || 'yyyy-MM-dd');
}

export function getUnixStartOfDayUTC(date: Date) {
  const formatedDate = format(date, 'yyyy-MM-dd');
  return Math.floor(new Date(formatedDate + 'T00:00:00.000Z').getTime() / 1000);
}

export function formatTimePair(time: number) {
  try {
    const durations = intervalToDuration({
      start: new Date(time * 1000),
      end: new Date(),
    });
    return i18next.t(durations.days ? 'home:ageDay' : 'home:age', durations);
  } catch {
    return '';
  }
}

function getFormat(durations: Duration) {
  const keys = Object.keys(durations).filter((item: any) => (durations as any)[item]);
  const results = [];
  if (keys.includes('days')) {
    results.push('{{days}}d');
  }

  if (keys.includes('hours')) {
    results.push('{{hours}}h');
  }

  if (keys.includes('minutes')) {
    results.push('{{minutes}}m');
  }

  return results.join(' ');
}

function timeToDuration(start: number) {
  const end = Date.now();

  const ONE_MINUTE_MS = 1000 * 60;
  const ONE_HOUR_MS = ONE_MINUTE_MS * 60;
  const ONE_DAY_MS = ONE_HOUR_MS * 24;

  const minutes = Math.floor(Math.round((end - start) % ONE_HOUR_MS) / ONE_MINUTE_MS);
  const hours = Math.floor(Math.round((end - start) % ONE_DAY_MS) / ONE_HOUR_MS);
  const days = Math.floor(Math.round(end - start) / ONE_DAY_MS);
  return { minutes, hours, days };
}

export function formatTimeTokenSocialNetwork(time: number) {
  try {
    const durations = timeToDuration(time * 1000);
    return i18next.t(getFormat(durations), durations);
  } catch {
    return '';
  }
}

export function formatDistanceTime(start?: number, end?: number) {
  if (!start || !end) return '';
  return formatDistance(start, end, { addSuffix: true });
}

export function getDateInfo(start?: number, end?: number) {
  const startAt = new Date(start!);
  const endAt = new Date(end!);
  const hoursStart = startAt.getHours();
  const minutesStart = startAt.getMinutes();
  const hoursEnd = endAt.getHours();
  const minutesEnd = endAt.getMinutes();
  const startTime = hoursStart * 60 * 60 * 1000 + minutesStart * 60 * 1000;
  const endTime = hoursEnd * 60 * 60 * 1000 + minutesEnd * 60 * 1000;

  startAt.setHours(0, 0, 0, 0);
  endAt.setHours(0, 0, 0, 0);
  return {
    startAt,
    endAt,
    startTime,
    endTime,
  };
}

export function compareTime(time1: number, time2: number, reverse?: boolean) {
  let disabled = false;
  let startAt = new Date(time1)
  if (reverse) {
    startAt = new Date(time2);
  }
  startAt = new Date(startAt);
  const hourStart = startAt?.getHours();
  const minuteStart = startAt?.getMinutes();
  const valueStartAt = hourStart * 60 * 60 * 1000 + minuteStart * 60 * 1000;
  disabled = reverse ? time1 >= valueStartAt : time2 < valueStartAt;
  debugger
  return disabled;
}