import { Link, useColorModeValue } from '@chakra-ui/react';
import path from 'configs/path';
import { Link as ReactLink } from 'react-router-dom';
import { buildHref } from 'utils/path';
import LogoFull from 'assets/logo/LogoFull';
import LogoCat from 'assets/logo/LogoCat';

function Logo() {
  const color = useColorModeValue('black', 'white');
  return (
    <Link
      as={ReactLink}
      to={buildHref(path.HOME)}
      textDecoration="none"
      _hover={{ textDecoration: 'none' }}
      _focus={{ boxShadow: 'none' }}
    >
      {/* <LogoFull aria-label="Alpha" h={8} w={32} fill={color} display={['none', 'none', 'block']} /> */}
      <LogoCat aria-label="Alpha" h={8} w={8} fill={color} display={['block', 'block', 'none']} />
    </Link>
  );
}

export default Logo;
